import * as _ from 'lodash';
import { FETCH_PRODUCT_PRICE_LIST, FETCH_PRODUCT_PRICE_LIST_FULFILLED, FETCH_PRODUCT_PRICE_LIST_REJECTED } from '../actions';

export default (state = {
	data: null,
	fetching: false,
	fetched: false,
	error: null
}, action) => {
	switch (action.type) {
		case FETCH_PRODUCT_PRICE_LIST:
			return { ...state, fetching: true, fetched: false, error: null }
		case FETCH_PRODUCT_PRICE_LIST_FULFILLED:
			return {
				...state, fetching: false, fetched: true, error: null,
				pricelistData: action.payload,
				data: Object.assign(state.data || {}, _.mapKeys(action.payload, 'id'))

			};
		case FETCH_PRODUCT_PRICE_LIST_REJECTED:
			return { ...state, fetching: false, fetched: false, error: action.payload, data: null };

		default:
			return state;
	}
}
