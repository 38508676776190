import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  reset
} from "redux-form";
import * as _ from "lodash";
import {
  Layout,
  Row,
  Col,
  Form,
  Input,
  Select,
  List,
  Card,
  Button,
  Modal,
  Table,
  Spin, Icon, Skeleton, Result
} from "antd";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { numberFormat } from "../../utilities";
import DocumentMeta from "react-document-meta";

import config from "../../config";
import {
  fetchPermissions,
  fetchRecentOrders,
  cancelRecentOrders
} from "../../actions";
import circle from "../../assets/images/home-banners/circle-pink.png";
import expandIcon from "../../assets/icons/expand.svg";

import "./Order.scss";
import { numFormat } from "../../utilities/format";

class RecentOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      expand: [],
      searchValue: "",
      ordersSearched: []
    };
    this.imageUploader = React.createRef();
  }

  handleToggleDetails = (id, toggle) => {
    let expand = this.state.expand;

    if (toggle) {
      expand.push(id);
    } else {
      const index = expand.indexOf(id);
      expand.splice(index, 1);
    }

    this.setState({ expand: expand });
  };
  handleSearch = e => {
    const result = Object.values(this.props.recentOrders.data).filter(row => {
      const search = arg => {
        if (arg) {
          return arg
            .toString()
            .toLowerCase()
            .includes(this.state.searchValue.toString().toLowerCase());
        }
      };
      return (
        search(row.port_destination) ||
        search(row.transaction_number) ||
        search(row.po_reference) ||
        search(row.special_instructions) ||
        [row.product].some(
          row =>
            row
              .map(row => row)
              .map(row => row.product)
              .some(
                row =>
                  search(row.name) ||
                  search(row.description) ||
                  search(row.category) ||
                  search(row.country_made)
              ) ||
            row
              .map(row => row)
              .map(row => row.variant)
              .some(
                row =>
                  search(row.name) ||
                  search(row.material_code) ||
                  search(row.packing_scheme) ||
                  search(row.variants.size) ||
                  search(row.variants.flavor) ||
                  search(row.gross_weight) ||
                  search(row.net_weight) ||
                  search(row.description)
              )
        )
      );
    });

    this.setState({
      searchValue: e.target.value.substr(0, 20),
      ordersSearched: result
    });
  };

  handleCancelOrder = (e) => {
    Modal.confirm({
      title: "Cancel Order",
      content: `Are you sure, you want to cancel order ${e.transaction_number}.`,
      okText: ' Okay',
      onOk: () => this.props.cancelRecentOrders(e.id).then((res) => {
        if (!res.error) {
          Modal.success({
            title: "Success",
            content: `You have cancelled your order.`,
            okText: ' Okay',
          })
        }
      })
    })
  }
  searchResultIndicator = () => {
    const { searchValue, ordersSearched } = this.state;
    if (searchValue) {
      if (ordersSearched.length == 1) {
        return (
          <div className="search-result-indicator">{`${ordersSearched.length}  Product Found`}</div>
        );
      } else if (ordersSearched.length >= 1) {
        return (
          <div className="search-result-indicator">{`${ordersSearched.length}  Products Found`}</div>
        );
      } else {
        return (
          <div className="search-result-indicator">{`${ordersSearched.length}  Product Found`}</div>
        );
      }
    }
  };

  componentDidMount() {
    this.props.fetchPermissions();
    this.props.fetchRecentOrders();
  }

  componentDidUpdate(prevProps, prevState) {
    const { recentOrders } = this.props;
    const { ordersSearched, searchValue } = this.state;
    if (this.state.orders !== recentOrders.data) {
      const expand =
        recentOrders.data && recentOrders.data.length > 0
          ? [recentOrders.data[0].id]
          : [];

      this.setState({
        orders: recentOrders.data,
        expand: expand
      });
    }

    if (prevState.searchValue !== this.state.searchValue) {
      if (this.state.searchValue.length >= 0) {
        this.setState({
          searchValue,
          ordersSearched
        });
      }
    }
  }

  render() {
    const { orders, expand, searchValue, ordersSearched } = this.state;
    const {
      myPermissions, myPermissionsIsFetching,
      recentOrders,
      fetching
    } = this.props;
    const columns = [
      {
        title: "LINE ITEM",
        dataIndex: "lineItem",
        key: "lineItem",
        render: ({ }, { }, i) => <span>{i}</span>
      },
      {
        title: "PRODUCTS",
        dataIndex: "variant.image",
        key: "variant.image",
        render: (image, row) => {
          // if (!image) {
          //   return "";
          // }
          return (
            <div className="product-information">
              <span
                className="product-image"
                style={{
                  backgroundImage: `url(${
                    image
                      ? `${config.REACT_APP_FILES_URL}${image}`
                      : "https://via.placeholder.com/100x100?text=No+Image"
                    })`
                }}
              />
              <span className="product-id">
                <h5>{row.product.brand.name}</h5>
                <h3>
                  {`${row.product.name} `}
                  {/* {`${row.product.name} 
                ${row.variant && row.variant.variants && row.variant.variants.flavor ? row.variant.variants.flavor.toLowerCase() : null} `} */}
                </h3>
              </span>
            </div>
          );
        }
      },
      {
        title: "VARIANTS",
        dataIndex: "variant",
        key: "variant",
        render: variant => variant.name
      },
      {
        title: "COUNTRY",
        dataIndex: "variant.country",
        key: "variant.country",
        align: "center"
      },
      {
        title: "TOTAL QTY.",
        dataIndex: "quantity",
        key: "quantity",
        align: "center",
        width: 260,
        render: quantity => quantity && quantity.toLocaleString()
      },
      {
        title: "TOTAL CBM",
        dataIndex: "variant.cbm",
        key: "variant.cbm",
        align: "center",
        width: 260,
        render: (cbm, row) => {
          return numberFormat(cbm * row.quantity, 2);
        }
      },
      {
        title: "TOTAL AMOUNT",
        dataIndex: "selling_price",
        key: "selling_price",
        align: "center",
        width: 260,
        render: (selling_price, row) => {
          return `$ ${numberFormat(selling_price * row.quantity)}`;
        }
      }
    ];

    const meta = {
      title: "Order History - URC Global Exports",
      description:
        "URC Global Exports seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: {
          keywords: "Order History"
        }
      }
    };

    const country = arg => {
      switch (arg) {
        case "philippines":
          return "PH";
        case "thailand":
          return "TH";
        case "vietnam":
          return "VT";
        case "malaysia":
          return "MY";
        case "indonesia":
          return "ID";
        default:
          throw Error("country name must be defined");
      }
    };

    if (myPermissionsIsFetching) {
      return (
        <DocumentMeta {...meta}>
          <Layout>
            <Spin spinning={myPermissionsIsFetching}>
              <Skeleton active />
            </Spin>
          </Layout>
        </DocumentMeta>
      )
    }

    return (
      <DocumentMeta {...meta}>
        <Layout>
          {myPermissions.some(r => r.name === 'distributor.orders.view') ? <Layout.Content className="product-order-content">
            <Row gutter={48}>
              <Col className="product-order-form" span={24}>
                <div className="po-form-header ">
                  <h2>Order History</h2>
                </div>
                <div className="recent-search-holder search-container">
                  <Input.Search
                    prefix={<Icon type="search" />}
                    placeholder="Search"
                    value={this.state.searchValue}
                    onChange={this.handleSearch}
                    size="large"
                  />
                </div>
                {this.searchResultIndicator()}
                <div className="product-order-result">
                  {orders && orders.length > 0 ? (
                    (searchValue.length >= 1 ? ordersSearched : orders)
                      .sort(
                        (a, b) => moment(b.created_at) - moment(a.created_at)
                      )
                      .map((order, index) => {
                        return (
                          <Row
                            type="flex"
                            gutter={30}
                            key={index}
                            className="product-table"
                          >
                            <Col
                              xs={24}
                              lg={24}
                              md={24}
                              className="table-header"
                            >
                              <MediaQuery minWidth={1224}>
                                <Row type="flex" justify="start" align="middle">
                                  <Col xs={24} md={4} lg={3}>
                                    <span className="order-date">
                                      {moment(order.created_at).format(
                                        "MMMM DD, YYYY"
                                      )}
                                    </span>
                                  </Col>
                                  <span className="separator">&#9679;</span>
                                  <Col xs={24} md={4} lg={3}>
                                    <span className="order-number">
                                      {order.transaction_number}
                                    </span>
                                  </Col>
                                  <span className="separator">&#9679;</span>
                                  <Col xs={24} md={4} lg={3}>
                                    <span className="product-order-length">
                                      {order.product.length} Product/s ordered
                                    </span>
                                  </Col>

                                </Row>
                              </MediaQuery>
                              <MediaQuery maxWidth={1224}>
                                <Row
                                  type="flex"
                                  justify="start"
                                  align="middle"
                                  className="po-history-sub-header"
                                >
                                  <Col span={24}>
                                    {moment(order.created_at).format(
                                      "MMMM DD, YYYY"
                                    )}
                                  </Col>
                                  <Col span={24}>
                                    <Row
                                      type="flex"
                                      justify="space-between"
                                      align="middle"
                                    >
                                      <Col>
                                        <label>PO NUMBER:</label>
                                        <span> {order.po_reference}</span>
                                      </Col>
                                      <Col>
                                        <label>ORDER NUMBER:</label>
                                        <span> {order.transaction_number}</span>
                                      </Col>
                                      <Col>
                                        <label>CUSTOMER PO NUMBER:</label>
                                        <span>
                                          {order.sales_ref_number || "N/A"}
                                        </span>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </MediaQuery>
                            </Col>

                            <Col span={24} className="table-holder">
                              {/* <Table dataSource={order.product} columns={columns}
                          loading={recentOrders.fetching} pagination={false}
                          scroll={{ y: "300px" }} className="product-table" style={{ display: expand.indexOf(order.id) > -1 ? "block" : "none" }} /> */}
                              <List
                                bordered
                                itemLayout="horizontal"
                                dataSource={order.product || []}
                                // this.state.searchValue
                                // ? this.state.newData
                                // : this.state.items
                                renderItem={(row, i) => {
                                  return (
                                    <List.Item
                                      className="product"
                                      style={{
                                        display:
                                          expand.indexOf(order.id) > -1
                                            ? "block"
                                            : "none"
                                      }}
                                    >
                                      <MediaQuery minWidth={1224}>
                                        <Row
                                          gutter={24}
                                          type="flex"
                                          align="middle"
                                          justify="center"
                                        >
                                          <Col span={2}>{i + 1}</Col>
                                          <Col span={3}>
                                            <div className="product-information">
                                              <span className="product-id">
                                                <h5>
                                                  {row.product.brand.name}
                                                </h5>
                                                <h3>{`${row.product.name} `}</h3>
                                              </span>
                                            </div>
                                          </Col>
                                          <Col span={4}>{row.variant.name}</Col>
                                          <Col span={4}>
                                            {row.variant.packing_scheme}
                                          </Col>
                                          <Col span={2}>
                                            {country(
                                              row.product.country_made.toLowerCase()
                                            )}
                                          </Col>
                                          <Col span={3} align="right">
                                            {row.quantity.toLocaleString()}
                                          </Col>
                                          <Col span={3} align="right">
                                            {(
                                              row.variant.cbm * row.quantity
                                            ).toFixed(2)}
                                          </Col>
                                          <Col span={3} align="right">
                                            ${" "}
                                            {numberFormat(
                                              row.selling_price * row.quantity
                                            )}
                                          </Col>
                                        </Row>
                                      </MediaQuery>
                                      <MediaQuery maxWidth={1224}>
                                        <Row
                                          type="flex"
                                          justify="start"
                                          align="start"
                                          className="xs-order-history-content"
                                        >
                                          <Col span={24}>
                                            <Row
                                              type="flex"
                                              justify="start"
                                              align="start"
                                            >
                                              <Col span={12}>
                                                <label>line number</label>
                                                <span>{i + 1}</span>
                                              </Col>
                                              <Col span={11} offset={1}>
                                                <label>sku code</label>
                                                <span>
                                                  {row.variant.material_code}
                                                </span>
                                              </Col>
                                              {/* <Col span={12}>
                                                <label>country made</label>
                                                <span>
                                                  {country(
                                                    row.product.country_made.toLowerCase()
                                                  )}
                                                </span>
                                              </Col> */}
                                              <Col span={24}>
                                                <label>sku description</label>
                                                <span>
                                                  {`${row.product.brand.name}   ${row.variant.name}`}
                                                </span>
                                              </Col>
                                              <Col span={12}>
                                                <label>packing scheme</label>
                                                <span>
                                                  {row.variant.packing_scheme}
                                                </span>
                                              </Col>

                                              <Col span={11} offset={1}>
                                                <label>amount (in usd)</label>
                                                <span>
                                                  $&nbsp;
                                                  {numberFormat(
                                                  row.selling_price *
                                                  row.quantity
                                                )}
                                                </span>
                                              </Col>

                                              <Col span={12}>
                                                <label>
                                                  ordered qty. (IN CS)
                                                </label>
                                                <span>
                                                  {numFormat(
                                                    "comma",
                                                    row.quantity
                                                  )}
                                                </span>
                                              </Col>

                                              <Col span={11} offset={1}>
                                                <label>cbm</label>
                                                <span>
                                                  {(
                                                    row.variant.cbm *
                                                    row.quantity
                                                  ).toFixed(2)}
                                                </span>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </MediaQuery>
                                    </List.Item>
                                  );
                                }}
                                header={
                                  <MediaQuery minWidth={1224}>
                                    <Row gutter={24}>
                                      <Col span={2}>
                                        <h3>Line Item</h3>
                                      </Col>
                                      <Col span={3}>
                                        <h3>Products</h3>
                                      </Col>
                                      <Col span={4}>
                                        <h3>Product Description</h3>
                                      </Col>
                                      <Col span={4}>
                                        <h3>Packing Scheme</h3>
                                      </Col>
                                      <Col span={2}>
                                        <h3>Country</h3>
                                      </Col>
                                      <Col span={3} align="right">
                                        <h3>Total QTY.</h3>
                                      </Col>
                                      <Col span={3} align="right">
                                        <h3>Total CBM</h3>
                                      </Col>
                                      <Col span={3} align="right">
                                        <h3>Total Amount</h3>
                                      </Col>
                                    </Row>
                                  </MediaQuery>
                                }
                                footer={
                                  <>
                                    <div className="footer">
                                      <MediaQuery maxWidth={1224}>
                                        <Row
                                          type="flex"
                                          justify="start"
                                          align="start"
                                          className="xs-order-history-footer"
                                        >
                                          <Col span={24}>
                                            <Row
                                              type="flex"
                                              justify="start"
                                              align="start"
                                            >
                                              {/* <Col span={12}>
                                                <label>po number</label>
                                                <span>
                                                  {order.po_reference}
                                                </span>
                                              </Col> */}
                                              <Col span={12}>
                                                <label>
                                                  port of destination
                                                </label>
                                                <span>
                                                  {order.port_destination}
                                                </span>
                                              </Col>
                                              {/* <Col span={12}>
                                                <label>order number</label>
                                                <span>
                                                  {order.transaction_number}
                                                </span>
                                              </Col> */}
                                              <Col span={10} offset={2}>
                                                <label>target etd</label>
                                                <span>
                                                  {moment(
                                                    order.estimated_delivery
                                                  ).format("MMMM YYYY")}
                                                </span>
                                              </Col>
                                              <Col span={12}>
                                                <label>
                                                  special instructions
                                                </label>
                                                <span>
                                                  {order.special_instructions ||
                                                    "N/A"}
                                                </span>
                                              </Col>
                                              <Col span={10} offset={2}>
                                                <label>
                                                  order status
                                                </label>
                                                <div className='recent-order-status' style={{ marginTop: 12 }}>
                                                  {order.status === 0 ? <span className='cancelled'>CANCELLED</span> : <span className='inprocess'>IN PROCESS</span>}
                                                </div>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </MediaQuery>
                                      <MediaQuery minWidth={1224}>
                                        <Row type="flex" gutter={30}>
                                          <Col md={12}>
                                            <Row className="footer-details">
                                              <Col span={12}>
                                                <h3>
                                                  PO REF NUMBER:
                                                </h3>
                                                <p>
                                                  {order.po_reference}
                                                </p>
                                              </Col>
                                              <Col span={12}>
                                                <h3>
                                                  PORT OF DESTINATION:
                                                </h3>
                                                <p>
                                                  {order.port_destination}
                                                </p>
                                              </Col>

                                              <Col span={12} style={{ margin: "20px 0px" }}>
                                                <h3>
                                                  ORDER #:
                                                </h3>
                                                <p>
                                                  {order.transaction_number}
                                                </p>
                                              </Col>

                                              <Col span={12} style={{ margin: "20px 0px" }}>
                                                <h3>
                                                  TARGET ETD:
                                                </h3>
                                                <p>
                                                  {moment(
                                                    order.estimated_delivery
                                                  ).format("MMMM YYYY")}
                                                </p>
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col md={12}>
                                            <Row className="footer-details">
                                              <Col span='12'>
                                                <h3>
                                                  SPECIAL INSTRUCTIONS:
                                                </h3>
                                                <p>
                                                  {order.special_instructions}
                                                </p>
                                              </Col>
                                              <Col md={12}>
                                                <h3>
                                                  ORDER STATUS:
                                                </h3>
                                                <div className='recent-order-status'>
                                                  {order.status === 0 ? <span className='cancelled'>CANCELLED</span> : <span className='inprocess'>IN PROCESS</span>}
                                                </div>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </MediaQuery>

                                      <MediaQuery minWidth={1224}>
                                        <Row type="flex" gutter={30}>
                                          <Layout.Content
                                            style={{ margin: "0 -6px -30px" }}
                                          >
                                            <Card
                                              style={{
                                                paddingTop: 30,
                                                paddingBottom: 30
                                              }}
                                            >
                                              <Row
                                                type="flex"
                                                justify="space-between"
                                                align="middle"
                                                className="order-history-footer"
                                              >
                                                <Col span={12}>
                                                  {expand.indexOf(order.id) >
                                                    -1 ? (
                                                      <span
                                                        className="toggle-button"
                                                        onClick={() =>
                                                          this.handleToggleDetails(
                                                            order.id,
                                                            false
                                                          )
                                                        }
                                                      >
                                                        Shorten Order Details >
                                                      </span>
                                                    ) : (
                                                      <span
                                                        className="toggle-button"
                                                        onClick={() =>
                                                          this.handleToggleDetails(
                                                            order.id,
                                                            true
                                                          )
                                                        }
                                                      >
                                                        Expand Order Details >
                                                      </span>
                                                    )}
                                                </Col>
                                                <Col span={12}>
                                                  <Row
                                                    type="flex"
                                                    justify="space-between"
                                                    align="middle"
                                                  >
                                                    <Col span={8}>
                                                      <Row
                                                        type="flex"
                                                        justify="center"
                                                        align="middle"
                                                      >
                                                        <Col>
                                                          <label>
                                                            TOTAL QUANTITY:
                                                          </label>
                                                          &nbsp;
                                                          <span>
                                                            {order &&
                                                              order.product &&
                                                              order.product
                                                                .reduce(
                                                                  (a, c) => {
                                                                    return (
                                                                      a +
                                                                      parseInt(
                                                                        c.quantity
                                                                      )
                                                                    );
                                                                  },
                                                                  0
                                                                )
                                                                .toLocaleString()}
                                                          </span>
                                                        </Col>
                                                      </Row>
                                                    </Col>
                                                    <Col span={8}>
                                                      <Row
                                                        type="flex"
                                                        justify="center"
                                                        align="middle"
                                                      >
                                                        <Col>
                                                          <label>
                                                            TOTAL CBM:
                                                          </label>
                                                          &nbsp;
                                                          <span>
                                                            {numberFormat(
                                                              order.product.reduce(
                                                                (a, c) => {
                                                                  return (
                                                                    a +
                                                                    parseFloat(
                                                                      c.quantity
                                                                    ) *
                                                                    parseFloat(
                                                                      c
                                                                        .variant
                                                                        .cbm
                                                                    )
                                                                  );
                                                                },
                                                                0
                                                              ),
                                                              2
                                                            )}
                                                          </span>
                                                        </Col>
                                                      </Row>
                                                    </Col>
                                                    <Col span={8}>
                                                      <Row
                                                        type="flex"
                                                        justify="center"
                                                        align="middle"
                                                      >
                                                        <Col>
                                                          <label>
                                                            TOTAL AMOUNT:
                                                          </label>
                                                          &nbsp; ${" "}
                                                          <span>
                                                            {numberFormat(
                                                              order.product.reduce(
                                                                (a, c) => {
                                                                  return (
                                                                    a +
                                                                    parseFloat(
                                                                      c.quantity
                                                                    ) *
                                                                    parseFloat(
                                                                      c.selling_price
                                                                    )
                                                                  );
                                                                },
                                                                0.0
                                                              ),
                                                              2
                                                            )}
                                                          </span>
                                                        </Col>
                                                      </Row>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Card>
                                          </Layout.Content>
                                        </Row>
                                      </MediaQuery>
                                    </div>
                                    <MediaQuery maxWidth={1224}>
                                      <Row
                                        type="flex"
                                        justify="center"
                                        align="middle"
                                        className="xs-expand"
                                      >
                                        <Col span={2}>
                                          {expand.indexOf(order.id) > -1 ? (
                                            <span
                                              onClick={() =>
                                                this.handleToggleDetails(
                                                  order.id,
                                                  false
                                                )
                                              }
                                            >
                                              <img
                                                src={expandIcon}
                                                alt="expand"
                                              />
                                            </span>
                                          ) : (
                                              <span
                                                onClick={() =>
                                                  this.handleToggleDetails(
                                                    order.id,
                                                    true
                                                  )
                                                }
                                              >
                                                <img
                                                  src={expandIcon}
                                                  alt="expand"
                                                />
                                              </span>
                                            )}
                                        </Col>
                                        <Col span={22}>
                                          <Row
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                          >
                                            <Col span={10}>
                                              <label>total amount</label>
                                              <span>
                                                $&nbsp;
                                                {numberFormat(
                                                order.product.reduce(
                                                  (a, c) =>
                                                    a +
                                                    parseFloat(c.quantity) *
                                                    parseFloat(
                                                      c.selling_price
                                                    ),
                                                  0.0
                                                ),
                                                2
                                              )}
                                              </span>
                                            </Col>
                                            <Col span={7}>
                                              <label>total qty.</label>
                                              <span>
                                                {order &&
                                                  order.product &&
                                                  order.product
                                                    .reduce((a, c) => {
                                                      return (
                                                        a + parseInt(c.quantity)
                                                      );
                                                    }, 0)
                                                    .toLocaleString()}
                                              </span>
                                            </Col>
                                            <Col span={7}>
                                              <label>total cbm</label>
                                              <span>
                                                {numberFormat(
                                                  order.product.reduce(
                                                    (a, c) => {
                                                      return (
                                                        a +
                                                        parseFloat(c.quantity) *
                                                        parseFloat(
                                                          c.variant.cbm
                                                        )
                                                      );
                                                    },
                                                    0
                                                  ),
                                                  2
                                                )}
                                              </span>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </MediaQuery>
                                  </>
                                }
                              />
                            </Col>
                          </Row>
                        );
                      })
                  ) : (
                      <Row
                        type="flex"
                        align="middle"
                        justify="center"
                        style={{ marginTop: "50px" }}
                      >
                        <Col>
                          {fetching ? <Spin /> : <h1>No Order History</h1>}
                        </Col>
                      </Row>
                    )}
                </div>
              </Col>
            </Row>
          </Layout.Content>
            : <Result
              status="warning"
              title="You have no permission to view this page."
            />
          }
        </Layout>
      </DocumentMeta>
    );
  }
}

const mapStateToProps = state => {
  const {
    permissions,
    recentOrders
  } = state;

  return {
    myPermissions: permissions.data,
    myPermissionsIsFetching: permissions.fetching,
    recentOrders: recentOrders,
    fetching: recentOrders.fetching
  };
};

export default connect(mapStateToProps, {
  fetchPermissions,
  fetchRecentOrders,
  cancelRecentOrders
})(
  reduxForm({ form: "productOrderForm", enableReinitialize: true })(RecentOrder)
);
