import React from 'react'
import { Row, Col, Button } from 'antd'

export default function ContactUs() {
  return (
    <div className='contact-us-container'>
      <Row type='flex'>
        <Col lg={12}>
          <div className='contact-message-container'>
            <div className='contact-message'>
              <h1>How to reach us</h1>
              <p>URC takes great pride in the superior flavor, quality and value of all its products, bringing a taste of the Philippines to the four corners of the globe. To learn more about the products we offer and how to enjoy them in your home and country, please send us a feedback.</p>
              <Button href='/contact-us'>
                Contact Us
              </Button>
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className='contact-us-button-container'>
            {/* // */}
          </div>
        </Col>
      </Row>
    </div>
  )
}
