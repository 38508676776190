import React, { Component } from 'react'
import Slider from 'react-slick'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { redirectFilter } from '../../actions/home'

// // import images
import Piattos from '../../assets/images/Brands/1_Piattos.png'
import Vcut from '../../assets/images/Brands/2_V_cut.png'
import Chippy from '../../assets/images/Brands/3_Chippy.png'
import Nova from '../../assets/images/Brands/4_Nova.png'
import MrChips from '../../assets/images/Brands/5_MR_Chips.png'
import RollerCoaster from '../../assets/images/Brands/6_Roller_Coaster.png'
import MangJuan from '../../assets/images/Brands/7_Mang_juan.png'
import C2 from '../../assets/images/Brands/8_C2.png'
import GreatTaste from '../../assets/images/Brands/9_Great_Taste.png'
import MagicFlakes from '../../assets/images/Brands/10_Magic_Flakes.png'
import Presto from '../../assets/images/Brands/11_Presto.png'
import CreamO from '../../assets/images/Brands/12_Cream_O.png'
import XO from '../../assets/images/Brands/13_XO.png'
import Payless from '../../assets/images/Brands/14_Payless.png'

class ourBrands extends Component {
  render() {
    let dragging = false;
    const settings = {
      beforeChange: () => dragging = true,
      afterChange: () => dragging = false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 2,
      centerMode: true,
      focusOnSelect: true,
      autoplay: true,
      autoplaySpeed: 1000,
      lazyLoad: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

    const brandsImages = [
      {
        id: 1,
        imageLink: Piattos,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=piattos'
      },
      {
        id: 2,
        imageLink: Vcut,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=v-cut'
      },
      {
        id: 3,
        imageLink: Chippy,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=chippy'
      },
      {
        id: 4,
        imageLink: Nova,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=nova'
      },
      {
        id: 5,
        imageLink: MrChips,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=mr. chips'
      },
      {
        id: 6,
        imageLink: RollerCoaster,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=roller coaster'
      },
      {
        id: 7,
        imageLink: MangJuan,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=mang juan'
      },
      {
        id: 8,
        imageLink: C2,
        linkFilter: '?brand=C2&category=all&country=all&activeMenu=brand&product=c2'
      },
      {
        id: 9,
        imageLink: GreatTaste,
        linkFilter: '?brand=GREAT%20TASTE&category=all&country=all&activeMenu=brand&product=all',

      },
      {
        id: 10,
        imageLink: MagicFlakes,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=magic flakes'
      },
      {
        id: 11,
        imageLink: Presto,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=presto'

      },
      {
        id: 12,
        imageLink: CreamO,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=cream-o'
      },
      {
        id: 13,
        imageLink: XO,
        linkFilter: '?brand=JACK%20N%27%20JILL&category=all&country=all&activeMenu=brand&product=x.o.'
      },
      {
        id: 14,
        imageLink: Payless,
        linkFilter: '?brand=PAYLESS&category=all&country=all&activeMenu=brand&product=all'
      }
    ]
    return (
      <div className='our-brands-container'>
        <Row>
          <Col lg={6}>
            <h1>Our Brands</h1>
            <p>Our vast portfolio of brands of exceptional taste, quality, & value.</p>
            <div className='view-brands-link'>
              <span><Link to='/products'>View Our Brands</Link></span>
            </div>
          </Col>
          <Col lg={18}>
            <div className='product-slider'>
              <div className='product-display-overlay' />
              <Slider {...settings} arrows={false}>
                {brandsImages.map((data) => {
                  return (
                    <Link to={`/products${data.linkFilter}`} disabled={!data.linkFilter ? true : false} key={data.id} draggable="false"
                      onClick={(e) => dragging ? e.preventDefault() : this.props.redirectFilter(`${data.linkFilter}`)}
                    >
                      <center>
                        <img src={data.imageLink} alt='Brand Item' />
                      </center>
                    </Link>
                  )
                })}
              </Slider>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps({ home }) {
  return home
}

export default connect(mapStateToProps, { redirectFilter })(ourBrands)
