import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import * as _ from "lodash";
import {
  Layout,
  Row,
  Col,
  Input,
  Select,
  Icon,
  Table,
  Tag,
  List,
  Typography,
  Tabs,
  Popover
} from "antd";
import { numberFormat } from "../../utilities";
import DocumentMeta from "react-document-meta";
import { fetchDistributor } from "../../actions";
import sampleData from "../OrderStatus/data";
import { fetchPoStatus, fetchDataFromSap } from "../../actions";
import MediaQuery from "react-responsive";
import { reduxForm } from "redux-form";
import "./PurchaseOrder.scss";
import NextIcon from "../../assets/icons/next.svg";
import FilterIcon from "../../assets/icons/filter.svg";
import { numFormat } from "../../utilities/format";
import ColumnGroup from "antd/lib/table/ColumnGroup";
const { Option } = Select;

class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      filterBy: "all",
      tableData: [],
      isScrolled: false,
      tab: 1,
    };
  }

  handleFilterBy = (filterBy) => {
    this.setState({ filterBy, keyword: "" });
    const { poStatus } = this.props;
    const newArrayObj = poStatus.data;

    // if data not yet loaded condition to avoid error
    if (!newArrayObj) {
      return;
    }

    // if data not yet loaded condition to avoid error
    if (!newArrayObj) {
      return;
    } else {
      // return original data
      return this.setState({ tableData: newArrayObj });
    }
  };

  componentDidMount() {
    const handleScroll = (e) => {
      var scrollPositionY = window.pageYOffset || document.body.scrollTop;

      this.setState({
        isScrolled: scrollPositionY > 120,
      });
    };
    document.addEventListener("scroll", handleScroll, { passive: true });
    this.props.fetchDataFromSap();
    this.props.fetchDistributor();
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  componentWillReceiveProps(nextProps) {

    const distributorCustomerCode =
      this.props.distributor &&
      this.props.distributor.data &&
      this.props.distributor.data.distributor.customer_code;

    if (nextProps) {
      if (this.props.poStatus.data !== nextProps.poStatus.data) {
        this.setState({ tableData: nextProps.poStatus.data });
      }

      if (this.props.sapData !== nextProps.sapData) {
        let orderStatusArray = [];
        nextProps.sapData &&
          nextProps.sapData.data &&
          nextProps.sapData.data.map((data) => {
            if (
              Number(data.customer_po_code) === Number(distributorCustomerCode)
            ) {
              orderStatusArray.push({ ...data });
            }
          });

        this.props.fetchPoStatus(orderStatusArray);
      }
    }


  }

  getDataBasedOnCustomerCode() {

    const urlParams = new URLSearchParams(window.location.search);
    const po_number = urlParams.get("po_number");

    const distributorCustomerCode =
      this.props.distributor &&
      this.props.distributor.data &&
      this.props.distributor.data.distributor.customer_code;
    let orderStatusArray = [];
    sampleData.map((data) => {
      if (data["Customer PO Code"] === distributorCustomerCode) {
        orderStatusArray.push({ ...data });
      }
    });
    return orderStatusArray;
  }

  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    this.setState({ keyword: searchTerm });

    //function inside function, this will convert the dot string into actual object
    const convertStringDotNotation = (desc, obj) => {
      if(desc==null) return;
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    };
    const { poStatus } = this.props;
    const newArrayObj = poStatus.data;
    
    // if data not yet loaded condition to avoid error
    if (!newArrayObj) {
      return;
    }

    //if SearchTerm is Empty return original data.
    if (!searchTerm) {
      return this.setState({ tableData: newArrayObj });
    }

    // special search for ready to load
    const word = "ready to load";
    if (word.toLowerCase().includes(searchTerm)) {
      searchTerm = "forloading";
    }

    let orderStatusArray = [];
    newArrayObj.forEach((rowItem, i) => {
      if(rowItem.pricelist.special_code != null) searchKeys.push("pricelist.special_code") // if item has special code, add it to the search keys
      else if(searchKeys.includes("pricelist.special_code") && rowItem.pricelist.special_code == null) searchKeys.pop()
      
      searchKeys.forEach((keys) => {
        let rowItemKeys = convertStringDotNotation(keys, {
          ...rowItem,
        }).toString();
        let isFound =
          rowItemKeys &&
          rowItemKeys.toLowerCase().includes(searchTerm.toLowerCase());
        if (isFound) orderStatusArray.push(rowItem);
      });
    });
    //Removed Duplicate Entry in Array.
    const newArrayElement = orderStatusArray.filter(function (
      elem,
      index,
      self
    ) {
      return index === self.indexOf(elem);
    });
    this.setState({ tableData: newArrayElement });
  };

  renderDotLoading(status, center) {
    const dotCount = 4;
    let i;
    let arrayCircle = [];
    let circleDataObject = {
      activeDot: 0,
      label: "",
    };
    if (status === "invoiced") {
      circleDataObject = {
        activeDot: 4,
        label: "INVOICED",
      };
    } else if (status === "forLoading") {
      circleDataObject = {
        activeDot: 3,
        label: "READY TO LOAD",
      };
    } else if (status === "produced") {
      circleDataObject = {
        activeDot: 2,
        label: "PRODUCED",
      };
    } else {
      circleDataObject = {
        activeDot: 1,
        label: "FOR PRODUCTION",
      };
    }
    for (i = 0; i < dotCount; i++) {
      arrayCircle.push(
        <div
          key={i}
          style={{
            backgroundColor:
              circleDataObject.activeDot > i ? "#6BBF59" : "lightgray",
            height: 12,
            width: 12,
            borderRadius: 12,
            margin: 8,
          }}
        />
      );
    }
    return (
      <div className="xs-activity">
        <h4 style={{ color: "gray", whiteSpace: "nowrap" }}>
          {circleDataObject.label}
        </h4>
        <div
          style={{ display: "flex", justifyContent: center }}
          className="dot"
        >
          {arrayCircle}
        </div>
      </div>
    );
  }

  handleTab = (key) => {
    if (key == 1) {
      this.setState({
        tab: 1,
      });
      this.props.getTabStatus(1);
    } else {
      this.setState({
        tab: 2,
      });
      this.props.getTabStatus(2);
    }
  };

  render() {
    const { filterBy, keyword, isScrolled, tab } = this.state;

    const meta = {
      title: "Purchase Order - URC Global Exports",
      description:
        "URC Global Exports seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: {
          keywords: "Purchase Order",
        },
      },
    };

    const { poStatus } = this.props;
    const urlParams = new URLSearchParams(window.location.search);

    const dataSource = this.state.tableData || [];
    const orderStatus = dataSource.map(data => data.statusView)

    const columns = [
      {
        title: "LINE NUMBER",
        dataIndex: "line",
        key: "LINE NUMBER",
        render: (value, e, i) => <span>{i + 1}</span>,
      },
      // {
      //   title: "CUSTOMER CODE",
      //   dataIndex: "customerCode",
      //   key: "customerCode"
      // },
      {
        title: "SKU CODE",
        dataIndex: "materialCode",
        key: "SKU CODE",
        render: (value, i) => `$ ${i.pricelist && i.pricelist.special_code ? i.pricelist.special_code : i.materialCode}`,
      },
      {
        title: "SKU DESCRIPTION",
        dataIndex: "skuDescription",
        key: "SKU DESCRIPTION",
      },
      {
        title: "PACKING SCHEME",
        dataIndex: "packingScheme",
        key: "PACKING SCHEME",
      },
      {
        title: (
          <span>
            ORDERED QTY<p>(IN CS)</p>
          </span>
        ),
        dataIndex: "orderQty",
        key: "ORDERED QTY (IN CS)",
      },
      {
        title: (
          <span>
            ORDERED AMOUNT<p>(IN USD)</p>
          </span>
        ),
        dataIndex: "orderAmount",
        key: "ORDERED AMOUNT (IN USD)",
        render: (value) => `$ ${value}`,
      },
      // {
      //   title: "ORDER STATUS",
      //   dataIndex: "status",
      //   key: "ORDER STATUS",
      //   render: (value) => this.renderDotLoading(value),
      // },
      // {
      //   title: (
      //     <span>
      //       INVOICED QTY<p>(IN CS)</p>
      //     </span>
      //   ),
      //   dataIndex: "actualStocks",
      //   key: "INVOICE QTY",
      // },
      // {
      //   title: (
      //     <span>
      //       INVOICED AMOUNT<p>(IN USD)</p>
      //     </span>
      //   ),
      //   dataIndex: "amount",
      //   key: "INVOICE AMOUNT (IN USD)",
      //   render: (value) => `$ ${value}`,
      // },
      {
        title: "CBM",
        dataIndex: "cbm",
        key: "CBM",
      },
      {
        title: "GROSS WEIGHT",
        dataIndex: "grossWeight",
        key: "GROSS WEIGHT",
        // render: (value, i) => <span>{i + 1}</span>,
      },
    ];

    return (
      <DocumentMeta {...meta}>
        <Layout>
          <MediaQuery maxWidth={1024}>
            <Row
              type="flex"
              justify="center"
              align="middle"
              className="xs-container-breakdown"
            >
              <Col span={24}>
                <Row
                  type="flex"
                  justify={!isScrolled ? "start" : "space-between"}
                  align="middle"
                  className="sub-header"
                >
                  <Col span={isScrolled ? 7 : 4}>
                    <Link to="/order-status">
                      <img src={NextIcon} />
                    </Link>
                  </Col>
                  <Col span={isScrolled ? 15 : 20}>
                    <span>{urlParams.get("po_number")}</span>
                  </Col>
                </Row>

                <Row
                  type="flex"
                  justify="start"
                  align="middle"
                  className="upper-details"
                >
                  <Col span={8}>
                    <label>po date:</label>
                    <span>
                      {moment(urlParams.get("po_date")).format("MMMM YYYY")}
                    </span>
                  </Col>
                  <Col span={8}>
                    <label>po number:</label>
                    <span>{urlParams.get("po_number")}</span>
                  </Col>
                  <Col span={8}>
                    <label>order number:</label>
                    <span>{urlParams.get("order_number")}</span>
                  </Col>
                  <Col span={8}>
                    <label>
                      customer <br />
                      po number:
                    </label>
                    <span>{urlParams.get("sales_ref_no") || "N/A"}</span>
                  </Col>
                  <Col span={8}>
                    <label>
                      target
                      <br />
                      etd:
                    </label>
                    <span>
                      {moment(urlParams.get("target_etd")).format("MMMM YYYY")}
                    </span>
                  </Col>
                </Row>

                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className={`tabs ${isScrolled ? "tabs-scrolled" : ""}`}
                >
                  <Col span={24}>
                    <Tabs defaultActiveKey="1" onChange={this.handleTab}>
                      <Tabs.TabPane tab="SKU Details" key="1" />
                      {/* <Tabs.TabPane tab="Container Breakdown" key="2" /> */}
                    </Tabs>
                  </Col>
                </Row>
                {tab === 1 ? (
                  <Row type="flex" className="search-holder">
                    <Col span={24} className="search-container">
                      <Input
                        type="text"
                        size="large"
                        prefix={<Icon type="search" />}
                        value={keyword}
                        placeholder="Search"
                        onChange={(e) =>
                          this.handleSearch(
                            e.target.value,
                            filterBy === "all"
                              ? columns
                                .filter((value) => value.dataIndex !== "line")
                                .map((value) => value.dataIndex)
                              : [filterBy]
                          )
                        }
                      />
                    </Col>
                  </Row>
                ) : (
                    ""
                  )}

                {tab == 1 ? (
                  <List
                    className="sku-main-list"
                    bordered
                    loading={this.props.poStatus.fetching}
                    dataSource={dataSource}
                    renderItem={(item, i) => {
                      return (
                        <List.Item className="sku-details-list">
                          <Row type="flex" justify="space-between" align="top">
                            <Col span={12}>line number</Col>
                            <Col span={12}>{i + 1}</Col>
                            <Col span={12}>sku code</Col>
                            <Col span={12}>{item.pricelist && item.pricelist.special_code !== null ? item.pricelist.special_code : item.materialCode}</Col>
                            <Col span={12}>sku description</Col>
                            <Col span={12}>{item.skuDescription}</Col>
                            <Col span={12}>packing scheme</Col>
                            <Col span={12}>{item.packingScheme}</Col>
                            {/* <Col span={12}>order status</Col>
                            <Col span={12}>
                              {this.renderDotLoading(item.status)}
                            </Col> */}
                          </Row>
                          <Row type="flex" justify="space-between" align="top">
                            <Col span={14}>ordered qty (in cs)</Col>
                            <Col span={10}>
                              {numFormat("comma", parseInt(item.orderQty))}
                            </Col>
                            <Col span={14}>ordered amount (in usd)</Col>
                            <Col span={10}>
                              $&nbsp;
                              {numFormat(
                              "dot/comma",
                              parseInt(item.orderAmount)
                            )}
                            </Col>
                            {/* <Col span={14}>invoiced qty (in cs)</Col>
                            <Col span={10}>
                              {numFormat("comma", parseInt(item.actualStocks))}
                            </Col> */}
                            {/* <Col span={14}>invoiced amount (in usd)</Col>
                            <Col span={10}>
                              $&nbsp;
                              {numFormat("dot/comma", parseInt(item.amount))}
                            </Col> */}
                            <Col span={10}>cbm</Col>
                            <Col span={10}>
                              {item.cbm ? parseInt(item.cbm).toFixed(2) : 0}
                            </Col>
                            <Col span={10}>gross weight</Col>
                            <Col span={10}>
                              {item.grossWeight ? parseInt(item.grossWeight).toFixed(2) : 0}
                            </Col>
                          </Row>
                        </List.Item>
                      );
                    }}
                    footer={
                      <Row
                        type="flex"
                        justify="space-between"
                        align="top"
                        className="list-footer"
                      >
                        <Col span={24}>total</Col>
                        <Col span={14}>invoiced amount(in usd)</Col>
                        <Col span={10}>
                          $&nbsp;
                          {(poStatus &&
                            poStatus.data &&
                            poStatus.data
                              .reduce((a, c) => {
                                return (
                                  a +
                                  parseFloat(
                                    c.amount.toString().replace(/,/g, "")
                                  )
                                );
                              }, 0)
                              .toLocaleString()) ||
                            0}
                        </Col>
                        <Col span={14}>ordered amount(in usd)</Col>
                        <Col span={10}>
                          $&nbsp;
                          {(poStatus &&
                            poStatus.data &&
                            poStatus.data
                              .reduce((a, c) => {
                                return (
                                  a +
                                  parseFloat(
                                    c.orderAmount.toString().replace(/,/g, "")
                                  )
                                );
                              }, 0)
                              .toLocaleString()) ||
                            0}
                        </Col>
                        <Col span={14}>invoiced qty (in cs)</Col>
                        <Col span={10}>
                          {" "}
                          {(poStatus &&
                            poStatus.data &&
                            poStatus.data
                              .reduce((a, c) => {
                                return (
                                  a +
                                  parseFloat(
                                    c.actualStocks.toString().replace(/,/g, "")
                                  )
                                );
                              }, 0)
                              .toLocaleString()) ||
                            0}
                        </Col>
                        <Col span={14}>ordered qty (in cs)</Col>
                        <Col span={10}>
                          {(poStatus &&
                            poStatus.data &&
                            poStatus.data
                              .reduce((a, c) => {
                                return (
                                  a +
                                  parseFloat(
                                    c.orderQty.toString().replace(/,/g, "")
                                  )
                                );
                              }, 0)
                              .toLocaleString()) ||
                            0}
                        </Col>
                        <Col span={14}>cbm</Col>
                        <Col span={10}>
                          {(poStatus &&
                            poStatus.data &&
                            poStatus.data
                              .reduce((a, c) => {
                                return (
                                  a +
                                  parseFloat(c.cbm.toString().replace(/,/g, ""))
                                );
                              }, 0)
                              .toFixed(2)) ||
                            0}
                        </Col>
                        <Col span={14}>gross weight</Col>
                        <Col span={10}>
                          {(poStatus &&
                            poStatus.data &&
                            poStatus.data
                              .reduce((a, c) => {

                                return (
                                  a +
                                  parseFloat(c.grossWeight.toString().replace(/,/g, ""))
                                );
                              }, 0)
                              .toFixed(2)) ||
                            0}
                        </Col>
                      </Row>
                    }
                  />
                ) : (
                    ""
                  )}
              </Col>
            </Row>
          </MediaQuery>

          <Layout.Content
            className="po-page-content"
            style={{ display: this.state.tab == 2 ? "none" : "" }}
          >
            <Row className="po-row">
              <MediaQuery minWidth={1024}>
                <Col span={24}>
                  <Row type="flex" className="po-page-header">
                    <Col xs={24} sm={12} md={2} lg={1} xl={1} span={1}>
                      <Link to="/order-status">
                        <Icon type="arrow-left" />
                      </Link>
                    </Col>
                    <Col span={23}>
                      <h2>{urlParams.get("po_number")}</h2>
                      <div className="po-page-sub-header">
                        <div>
                          PO DATE:
                          <br />
                          <span>
                            {moment(urlParams.get("po_date")).format(
                              "MMMM YYYY"
                            )}
                          </span>
                        </div>
                        <div>
                          PO NUMBER:
                          <br />
                          <span>{urlParams.get("po_number")}</span>
                        </div>
                        <div>
                          ORDER NUMBER:
                          <br />
                          <span>{urlParams.get("order_number")}</span>
                        </div>
                        <div>
                          CUSTOMER PO NUMBER:
                          <br />
                          <span>{urlParams.get("sales_ref_no") || "N/A"}</span>
                        </div>
                        <div>
                          CONTAINER OPTION:
                          <br />
                          <span>{urlParams.get("container_option") || "N/A"}</span>
                        </div>
                        <div>
                          <span style={{ paddingLeft: 20, color: "#E4B6BA" }}>STATUS:</span>
                          <br />
                          {/* <span>{urlParams.get("status") || "N/A"}</span> */}
                          <div className="order-status-tag" style={{ margin: '5px 0' }}>
                            {

                              urlParams.get("status") === '1'
                                ? (<span className="in-process">IN PROCESS</span>)
                                : (urlParams.get("status") === '0')
                                  ? (
                                    <Popover
                                      content={'Remarks: ' + dataSource.map(data => data.remarks)}
                                      trigger="hover"
                                    >
                                      <span className="cancelled">CANCELLED</span>
                                    </Popover>
                                  )
                                  : (urlParams.get("status") === '2')
                                    ? (<span className="invoiced">INVOICED</span>)
                                    : 'Fetching...'
                            }
                          </div>
                        </div>
                        <div>
                          TARGET ETD:
                          <br />
                          <span>
                            {moment(urlParams.get("target_etd")).format(
                              "MMMM YYYY"
                            )}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row type="flex" className="po-page-filters">
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <Input
                        type="text"
                        size="large"
                        style={{ width: 300 }}
                        prefix={<Icon type="search" />}
                        value={keyword}
                        placeholder="Search Products"
                        onChange={(e) =>
                          this.handleSearch(
                            e.target.value,
                            filterBy === "all"
                              ? columns
                                .filter((value) => value.dataIndex !== "line")
                                .map((value) => value.dataIndex)
                              : [filterBy]
                          )
                        }
                      />

                      <Select
                        size="large"
                        style={{ width: 200 }}
                        placeholder="Filter by"
                        defaultValue={[filterBy]}
                        onChange={(value) => this.handleFilterBy(value)}
                      >
                        <Option value="all">All</Option>
                        {columns
                          .filter((value) => value.dataIndex !== "line")
                          .map((value) => (
                            <Option
                              key={value.dataIndex}
                              value={value.dataIndex}
                            >
                              {value.key}
                            </Option>
                          ))}
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </MediaQuery>

              <MediaQuery minWidth={1024}>
                <Col className="po-page-table" span={24}>
                  <List
                    header={
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "#FAFAFA",
                          color: "#9999999",
                          fontSize: 12,
                          fontWeight: 400,
                        }}
                      >
                        <Row>
                          <Col span={2}>
                            LINE <br /> NUMBER
                          </Col>
                          <Col span={3}>SKU CODE</Col>
                          <Col span={4}>SKU DESCRIPTION</Col>
                          <Col span={3}>PACKING SCHEME</Col>
                          {/* <Col
                            span={3}
                            style={{
                              textAlign: "center",
                            }}
                          >
                            ORDER STATUS
                          </Col> */}
                          <Col span={3} align="right">
                            ORDERED QTY <p>(IN CS)</p>
                          </Col>
                          <Col span={3} align="right">
                            ORDERED AMOUNT <p>(IN USD)</p>
                          </Col>
                          {/* <Col span={2} align="right">
                            INVOICED<br /> QTY <p>(IN CS)</p>
                          </Col> */}
                          {/* <Col span={2} align="right">
                            INVOICED<br /> AMOUNT <p>(IN USD)</p>
                          </Col> */}
                          <Col span={3} align="right">
                            CBM
                          </Col>
                          <Col span={3} align="right">
                            GROSS<br />WEIGHT
                          </Col>
                        </Row>
                      </div>
                    }
                    footer={
                      <div style={{ color: "white" }}>
                        <Row>
                          <Col span={1}>
                            <div className="footer-label">
                              <h1>TOTAL</h1>
                            </div>
                          </Col>
                          <Col span={2}></Col>
                          <Col span={5}></Col>
                          <Col span={2}></Col>
                          <Col span={2}>
                            <div className="footer-label"></div>
                          </Col>

                          <Col span={3} align="right">
                            <div className="footer-label">
                              <h1>
                                {(poStatus &&
                                  poStatus.data &&
                                  poStatus.data
                                    .reduce((a, c) => {
                                      return (
                                        a +
                                        parseFloat(
                                          c.orderQty
                                            .toString()
                                            .replace(/,/g, "")
                                        )
                                      );
                                    }, 0)
                                    .toLocaleString()) ||
                                  0}
                              </h1>
                            </div>
                          </Col>
                          <Col span={3} align="right">
                            <div className="footer-label">
                              <h1>
                                $&nbsp;
                                {(poStatus &&
                                  poStatus.data &&
                                  poStatus.data
                                    .reduce((a, c) => {
                                      return (
                                        a +
                                        parseFloat(
                                          c.orderAmount
                                            .toString()
                                            .replace(/,/g, "")
                                        )
                                      );
                                    }, 0)
                                    .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })) ||
                                  "0.00"}
                              </h1>
                            </div>
                          </Col>

                          {/* <Col span={2} align="right">
                            <div className="footer-label">
                              <h1>
                                {(poStatus &&
                                  poStatus.data &&
                                  poStatus.data
                                    .reduce((a, c) => {
                                      return (
                                        a +
                                        parseFloat(
                                          c.actualStocks
                                            .toString()
                                            .replace(/,/g, "")
                                        )
                                      );
                                    }, 0)
                                    .toLocaleString()) ||
                                  0}
                              </h1>
                            </div>
                          </Col> */}
                          {/* <Col span={2} align="right">
                            <div className="footer-label">
                              <h1>
                                $&nbsp;
                                {(poStatus &&
                                  poStatus.data &&
                                  numberFormat(
                                    poStatus.data.reduce((a, c) => {
                                      return (
                                        a +
                                        parseFloat(
                                          c.amount.toString().replace(/,/g, "")
                                        )
                                      );
                                    }, 0)
                                  )) ||
                                  "0.00"}
                              </h1>
                            </div>
                          </Col> */}
                          <Col span={3} align="right">
                            <div className="footer-label">
                              <h1>
                                {(poStatus &&
                                  poStatus.data &&
                                  numberFormat(
                                    poStatus.data.reduce((a, c) => {
                                      return (
                                        a +
                                        parseFloat(
                                          c.cbm.toString().replace(/,/g, "")
                                        )
                                      );
                                    }, 0)
                                  )) ||
                                  "0.00"}
                              </h1>
                            </div>
                          </Col>
                          <Col span={3} align="right">
                            <div className="footer-label">
                              <h1>
                                {(poStatus &&
                                  poStatus.data &&
                                  parseFloat(
                                    poStatus.data.reduce((a, c) => {
                                      return (
                                        a +
                                        parseFloat(
                                          c.grossWeight.toString().replace(/,/g, "")
                                        )
                                      );
                                    }, 0).toFixed(2)
                                  ).toLocaleString()) ||
                                  "0"}
                              </h1>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    }
                    bordered
                    loading={this.props.poStatus.fetching}
                    dataSource={dataSource}
                    renderItem={(item, i) => (
                      <List.Item>
                        <div style={{ width: "100%" }}>
                          <Row>
                            <Col span={2}>{i + 1}</Col>
                            <Col span={3}>{item.pricelist && item.pricelist.special_code !== null ? item.pricelist.special_code : item.materialCode}</Col>
                            <Col span={4}>{item.skuDescription}</Col>
                            <Col span={3}>{item.packingScheme}</Col>
                            {/* <Col span={3}>
                              {this.renderDotLoading(item.status)}
                            </Col> */}
                            <Col span={3} align="right">
                              {item.orderQty}
                            </Col>
                            <Col span={3} align="right">
                              $&nbsp;{item.orderAmount}
                            </Col>
                            {/* <Col span={2} align="right">
                              {item.actualStocks}
                            </Col> */}
                            {/* <Col span={2} align="right">
                              $&nbsp;{item.amount}
                            </Col> */}
                            <Col span={3} align="right">
                              {item.cbm || "0.00"}
                            </Col>
                            <Col span={3} align="right">
                              {item.grossWeight || "0.00"}
                            </Col>
                          </Row>
                        </div>
                      </List.Item>
                    )}
                  />
                  <Table
                    style={{ display: "none" }}
                    pagination={false}
                    dataSource={dataSource}
                    loading={this.props.poStatus.fetching}
                    rowKey="materialCode"
                    footer={() => (
                      <Row type="flex" gutter={32}>
                        <Col>
                          <small>TOTAL ORDERS SKU'S:</small>
                          <span>
                            {(poStatus &&
                              poStatus.data &&
                              poStatus.data.length) ||
                              0}
                          </span>
                        </Col>
                        <Col>
                          <small>TOTAL FOR PRODUCTION:</small>
                          <span>
                            {(poStatus &&
                              poStatus.data &&
                              poStatus.data.filter(
                                (data) =>
                                  data.status === "forProduction" ||
                                  data.status === ""
                              ).length) ||
                              0}
                          </span>
                        </Col>
                        <Col>
                          <small>TOTAL PRODUCED:</small>
                          <span>
                            {(poStatus &&
                              poStatus.data &&
                              poStatus.data.filter(
                                (data) => data.status === "produced"
                              ).length) ||
                              0}
                          </span>
                        </Col>
                        <Col>
                          <small>TOTAL READY TO LOAD:</small>
                          <span>
                            {(poStatus &&
                              poStatus.data &&
                              poStatus.data.filter(
                                (data) => data.status === "forLoading"
                              ).length) ||
                              0}
                          </span>
                        </Col>
                      </Row>
                    )}
                    columns={columns}
                  />
                </Col>
              </MediaQuery>

              <MediaQuery minWidth={520} maxWidth={1024}>
                <Col span={24} style={{ display: "none" }}>
                  <List
                    size="large"
                    loading={this.props.poStatus.fetching}
                    bordered
                    dataSource={this.state.tableData || []}
                    renderItem={(item) => (
                      <List.Item key={item.materialCode}>
                        <Row style={{ width: "100%" }}>
                          <Col span={12}>
                            <h4>Order Status</h4>
                            <h4>SKU Code</h4>
                            <h4>SKU Description</h4>
                            <h4>Packing Scheme</h4>
                            <h4>Ordered Quantity (IN CS)</h4>
                            <h4>Amount (IN USD)</h4>
                            <h4>Actual Stocks</h4>
                            <h4>Cbm</h4>
                            <h4>Gross Weight</h4>
                          </Col>
                          <Col span={12}>
                            <h4>
                              {item.status === "invoiced"
                                ? "INVOICED"
                                : item.status === "forLoading"
                                  ? "READY TO LOAD"
                                  : item.status === "produced"
                                    ? "PRODUCED"
                                    : "FOR PRODUCTION"}
                            </h4>
                            <h4
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item.pricelist.special_code !== null ? item.pricelist.special_code : item.materialCode}
                            </h4>
                            <h4
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item.skuDescription}
                            </h4>
                            <h4>{item.packingScheme}</h4>
                            <h4>{item.orderQty}</h4>
                            <h4>{item.amount}</h4>
                            <h4>{item.actualStocks}</h4>
                            <h4>{item.cbm}</h4>
                            <h4>{item.grossWeight}</h4>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </Col>
              </MediaQuery>
            </Row>
          </Layout.Content>
        </Layout>
      </DocumentMeta >
    );
  }
}

const mapStateToProps = (state) => {
  const { distributor, poStatus, sapData } = state;

  return {
    distributor: distributor,
    poStatus,
    sapData,
  };
};

export default connect(mapStateToProps, {
  fetchDistributor,
  fetchPoStatus,
  fetchDataFromSap,
})(
  reduxForm({ form: "poOrderStatus", enableReinitialize: true })(PurchaseOrder)
);
