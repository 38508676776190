import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Select,
  Button,
  Layout,
  Card,
  Row,
  Col,
  Typography,
  Progress,
  Result,
  Skeleton,
  Spin,
  Icon,
  Drawer,
  Empty,
  Modal,
  Checkbox,
  DatePicker,
  Divider,
} from "antd";
import moment from "moment";
import { Doughnut, Chart } from "react-chartjs-2";
import MediaQuery from "react-responsive";
import { numberFormat } from "../../utilities";
import DocumentMeta from "react-document-meta";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { PDFDocument, StandardFonts } from "pdf-lib";
import reportIcon from "./../../assets/icons/report-icon.png";
import filterIcon from "./../../assets/icons/filter-icon.png";
import _ from 'lodash'

// Import CSS
import "./OrderSummary.scss";
import { fetchPermissions, fetchOrderSummary } from "../../actions";

const wb = new ExcelJS.Workbook();
const CheckboxGroup = Checkbox.Group;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Title } = Typography;
const plainOptions = ["EXCEL", "CSV", "PDF"];

class OrderSummary extends Component {
  state = {
    visible: false,
    table: [],
    radio: "MTD",
    selectPODate: "all",
    month: "today",
    startDate: moment().format("MM-DD-YYYY"),
    endDate: moment().format("MM-DD-YYYY"),
    rangePicker: [],
    selectCustPO: ["all"],
    selectStatus: "all",
    selectCreatedAt: ["all"],
    showDownloadModal: false,
    showCustomDateModal: false,
    indeterminate: false,
    checkAll: false,
    checkedList: [],
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  filterCustPO = async selectCustPO => {
    await this.setState({ selectCustPO: [] })
    await this.setState({ selectCustPO });
    // this.handleMonth(this.state.month)
}
  getTotal = (data, column) =>
    data.reduce((a, c) => a + parseFloat(c[column]), 0) || 0

  getTotalInProcessQuantity(data) {
    return data.reduce((a, c) => a + parseFloat(this.getQtyBasedOnStatus(c.status, c)), 0)
  }

  getTotalInvoicedQuantity(data) {
    return data.reduce((a, c) => a + parseFloat(c['billed_qty']), 0)
  }

  getTotalInProcessAmount(data) {
    return data.reduce((a, c) => a + (parseFloat(this.getQtyBasedOnStatus(c.status, c)) * c.product_variant && c.product_variant.pricelist[0].selling_price || 0), 0)
  }

  getTotalInvoicedAmount(data) {
    return data.reduce((a, c) => a + (parseFloat(c['billed_qty']) * c.product_variant && c.product_variant.pricelist[0].selling_price || 0), 0)
  }



  getQtyBasedOnStatus(status, row) {
    let returnQty;
    if (status === "produced") {
      returnQty = row["order_qty"]; // sap
    } else if (status === "ready_to_load") {
      returnQty = row["delivered_qty"]; // sap
    } else if (status === "invoiced") {
      returnQty = row["billed_qty"]; // sap
    }
    return returnQty;
  };

  groupBy = (data, key) => {
    return data.reduce(function (storage, item) {
      var group = item[key];
      storage[group] = storage[group] || [];
      storage[group].push(item);
      return storage;
    }, {});
  };

  handleMonth = async (value) => {
    const today = moment().format("MM-DD-YYYY");
    const startOfWeek = moment().startOf("week").format("MM-DD-YYYY");
    const endOfWeek = moment().endOf("week").format("MM-DD-YYYY");
    const startOfMonth = moment().startOf("month").format("MM-DD-YYYY");
    const endOfMonth = moment().endOf("month").format("MM-DD-YYYY");
    const startOfYear = moment().startOf("year").format("MM-DD-YYYY");
    const endOfYear = moment().endOf("year").format("MM-DD-YYYY");

    let startDate, endDate;

    if (value === "today") {
      startDate = today;
      endDate = today;
    } else if (value === "week") {
      startDate = startOfWeek;
      endDate = endOfWeek;
    } else if (value === "month") {
      startDate = startOfMonth;
      endDate = endOfMonth;
    } else if (value === "year") {
      startDate = startOfYear;
      endDate = endOfYear;
    } else {
      startDate = this.state.startDate;
      endDate = this.state.endDate;
    }


    await this.setState({ startDate: null })
    await this.setState({ endDate: null })

    await this.setState({
      month: value,
      showCustomDateModal: value === "custom",
      rangePicker: [],
      startDate,
      endDate,
    });
  };

  removeDuplicates = (myArr, prop) => {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  };

  filterData = (data, predicate) => {
    // if no data is sent in, return null, otherwise transform the data
    return !!!data
      ? null
      : data.reduce((list, entry) => {
        let clone = null;
        if (predicate(entry)) {
          // if the object matches the filter, clone it as it is
          clone = Object.assign({}, entry);
        } else if (entry.order != null && Array.isArray(entry.order)) {
          // if the object has orders, filter the list of order
          let order = this.filterData(entry.order, predicate);
          if (order.length > 0) {
            // if any of the order matches, clone the parent object, overwrite
            // the order list with the filtered list
            clone = Object.assign({}, entry, { order: order });
          }
        }
        // if there's a cloned object, push it to the output list
        clone && list.push(clone);
        return list;
      }, []);
  };

  onChangeCheckbox = (checkedList) => {
    this.setState({
      checkedList,
      indeterminate:
        !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length,
    });
  };

  onCheckAllChange = (e) => {
    this.setState({
      checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  splitArrayIntoChunksOfLen = (arr, len) => {
    let chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  };

  exportExcel = async (data) => {
    const worksheet = wb.addWorksheet("ORDER_SUMMARY_DASHBOARD_REPORT");

    // Width of column
    worksheet.getColumn("A").width = 16;
    worksheet.getColumn("B").width = 16;
    worksheet.getColumn("C").width = 20;
    worksheet.getColumn("D").width = 25;
    worksheet.getColumn("E").width = 20;
    worksheet.getColumn("F").width = 16;
    worksheet.getColumn("G").width = 20;
    worksheet.getColumn("H").width = 16;

    // Header
    worksheet.mergeCells("A1:H1");
    worksheet.getCell("A1").value = "ORDER SUMMARY DASHBOARD REPORT";
    worksheet.getCell("A1").alignment = { horizontal: "center" };
    worksheet.getCell("A1").font = {
      bold: true,
      horizontal: "center",
      color: { argb: "ffffff" },
    };
    worksheet.getCell("A1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "0072d4" },
    };

    worksheet.getCell("A2").value = "CUSTOMER PO NUMBER";
    worksheet.getCell("A2").alignment = { horizontal: "center" };
    worksheet.getCell("A2").font = {
      bold: true,
      horizontal: "center",
      color: { argb: "ffffff" },
    };
    worksheet.getCell("A2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "0072d4" },
    };

    worksheet.getCell("B2").value = "SO NUMBER";
    worksheet.getCell("B2").alignment = { horizontal: "center" };
    worksheet.getCell("B2").font = {
      bold: true,
      horizontal: "center",
      color: { argb: "ffffff" },
    };
    worksheet.getCell("B2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "0072d4" },
    };

    worksheet.getCell("C2").value = "STATUS";
    worksheet.getCell("C2").alignment = { horizontal: "center" };
    worksheet.getCell("C2").font = {
      bold: true,
      horizontal: "center",
      color: { argb: "ffffff" },
    };
    worksheet.getCell("C2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "0072d4" },
    };

    worksheet.getCell("D2").value = "TOTAL AMOUNT(IN USD)";
    worksheet.getCell("D2").alignment = { horizontal: "center" };
    worksheet.getCell("D2").font = {
      bold: true,
      horizontal: "center",
      color: { argb: "ffffff" },
    };
    worksheet.getCell("D2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "0072d4" },
    };

    worksheet.getCell("E2").value = "TOTAL QUANTITY";
    worksheet.getCell("E2").alignment = { horizontal: "center" };
    worksheet.getCell("E2").font = {
      bold: true,
      horizontal: "center",
      color: { argb: "ffffff" },
    };
    worksheet.getCell("E2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "0072d4" },
    };

    worksheet.getCell("F2").value = "TOTAL CBM";
    worksheet.getCell("F2").alignment = { horizontal: "center" };
    worksheet.getCell("F2").font = {
      bold: true,
      horizontal: "center",
      color: { argb: "ffffff" },
    };
    worksheet.getCell("F2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "0072d4" },
    };

    worksheet.getCell("G2").value = "TOTAL GROSS WEIGHT";
    worksheet.getCell("G2").alignment = { horizontal: "center" };
    worksheet.getCell("G2").font = {
      bold: true,
      horizontal: "center",
      color: { argb: "ffffff" },
    };
    worksheet.getCell("G2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "0072d4" },
    };

    worksheet.getCell("H2").value = "INVOICED DATE";
    worksheet.getCell("H2").alignment = { horizontal: "center" };
    worksheet.getCell("H2").font = {
      bold: true,
      horizontal: "center",
      color: { argb: "ffffff" },
    };
    worksheet.getCell("H2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "0072d4" },
    };

    const lastRow = 3;
    data.map((value, i) => {
      worksheet.getCell(`A${lastRow + i}`.toString()).value =
        value.customer_po_number;
      worksheet.getCell(`B${lastRow + i}`.toString()).value = value.so_number;
      worksheet.getCell(`C${lastRow + i}`.toString()).value = value.status;
      worksheet.getCell(`D${lastRow + i}`.toString()).value =
        `$${numberFormat(value.total_amount)}`;
      worksheet.getCell(`E${lastRow + i}`.toString()).value =
        value.total_quantity && value.total_quantity.toLocaleString();
      worksheet.getCell(`F${lastRow + i}`.toString()).value = numberFormat(value.total_cbm);
      worksheet.getCell(`F${lastRow + i}`).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      worksheet.getCell(`G${lastRow + i}`.toString()).value = value.total_grossWeight;
      worksheet.getCell(`G${lastRow + i}`).alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      worksheet.getCell(`H${lastRow + i}`.toString()).value = value.invoiced_date;
    });

    let totalRow = lastRow + data.length;
    worksheet.getCell(`A${totalRow}`).value = "TOTAL";
    worksheet.getCell(`A${totalRow}`).font = { bold: true };

    worksheet.getCell(`D${totalRow}`.toString()).value = `$${numberFormat(this.getTotal(
      data,
      "total_amount"
    ))}`;
    worksheet.getCell(`D${totalRow}`).alignment = {
      vertical: "bottom",
      horizontal: "right",
    };
    worksheet.getCell(`D${totalRow}`).font = { bold: true };

    worksheet.getCell(`E${totalRow}`.toString()).value = this.getTotal(
      data,
      "total_quantity"
    ).toLocaleString();
    worksheet.getCell(`E${totalRow}`).alignment = {
      vertical: "bottom",
      horizontal: "right",
    };
    worksheet.getCell(`E${totalRow}`).font = { bold: true };

    worksheet.getCell(`F${totalRow}`.toString()).value = numberFormat(this.getTotal(
      data,
      "total_cbm"
    ));
    worksheet.getCell(`F${totalRow}`).alignment = {
      vertical: "bottom",
      horizontal: "right",
    };
    worksheet.getCell(`F${totalRow}`).font = { bold: true };

    worksheet.getCell(`G${totalRow}`.toString()).value = `$${numberFormat(this.getTotal(
      data,
      "total_grossWeight"
    ))}`;
    worksheet.getCell(`G${totalRow}`).alignment = {
      vertical: "bottom",
      horizontal: "right",
    };
    worksheet.getCell(`G${totalRow}`).font = { bold: true };

    const excelData = await wb.xlsx.writeBuffer();

    saveAs(new Blob([excelData]), "order-summary-dashboard-report.xlsx");
    wb.removeWorksheet(worksheet.id);
  };

  exportCSV = (data) => {
    // console.log("DATA================>X", data)
    const csv = Papa.unparse({
      fields: [
        "CUSTOMER PO NUMBER",
        "SO NUMBER",
        "STATUS",
        "TOTAL AMOUNT (IN USD)",
        "TOTAL QUANTITY",
        "TOTAL CBM",
        "TOTAL GROSS WEIGHT",
        "INVOICED DATE",
      ],
      data: data.map((value) => {
        return [
          value.customer_po_number,
          value.so_number,
          value.status,
          `$${numberFormat(value.total_amount)}`,
          value.total_quantity && value.total_quantity.toLocaleString(),
          numberFormat(value.total_cbm),
          value.total_grossWeight,
          value.invoiced_date
        ]
      }),
    });

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "order-summary-dashboard-report.csv");
  };

  exportPDF = async (data) => {
    const pdfDoc = await PDFDocument.create();

    // Embed the Times Roman font
    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

    // Split page by 80
    let pageArray = this.splitArrayIntoChunksOfLen(data, 80);

    let pageCount = 0;
    pageArray.map((value, i) => {
      const page = pdfDoc.addPage();
      const { height } = page.getSize();
      page.moveTo(110, 200);

      page.drawText("#", {
        x: 40,
        y: height - 4 * 9,
        size: 9,
        font: timesRomanFont,
      });
      page.drawText("CUST PO #", {
        x: 60,
        y: height - 4 * 9,
        size: 9,
        font: timesRomanFont,
      });
      page.drawText("SO #", {
        x: 120,
        y: height - 4 * 9,
        size: 9,
        font: timesRomanFont,
      });
      page.drawText("STATUS", {
        x: 175,
        y: height - 4 * 9,
        size: 9,
        font: timesRomanFont,
      });
      page.drawText("TOTAL AMOUNT", {
        x: 220,
        y: height - 4 * 9,
        size: 9,
        font: timesRomanFont,
      });
      page.drawText("TOTAL QUANTITY", {
        x: 295,
        y: height - 4 * 9,
        size: 9,
        font: timesRomanFont,
      });
      page.drawText("TOTAL CBM", {
        x: 380,
        y: height - 4 * 9,
        size: 9,
        font: timesRomanFont,
      });
      page.drawText("TOTAL G.WEIGHT", {
        x: 438,
        y: height - 4 * 9,
        size: 9,
        font: timesRomanFont,
      });
      page.drawText("INVOICED DATE", {
        x: 520,
        y: height - 4 * 9,
        size: 9,
        font: timesRomanFont,
      });

      value.map((value, i) => {
        pageCount++;
        page.drawText(pageCount.toString(), {
          x: 40,
          y: height - (6 + i) * 9,
          size: 9,
          font: timesRomanFont,
        });
        page.drawText(`${value.customer_po_number}`, {
          x: 60,
          y: height - (6 + i) * 9,
          size: 9,
          font: timesRomanFont,
        });
        page.drawText(`${value.so_number}`, {
          x: 120,
          y: height - (6 + i) * 9,
          size: 9,
          font: timesRomanFont,
        });
        page.drawText(`${value.status}`, {
          x: 175,
          y: height - (6 + i) * 9,
          size: 9,
          font: timesRomanFont,
        });
        page.drawText(`$${(value.total_amount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`, {
          x: 220,
          y: height - (6 + i) * 9,
          size: 9,
          font: timesRomanFont,
        });
        page.drawText(`${value.total_quantity && value.total_quantity.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`, {
          x: 295,
          y: height - (6 + i) * 9,
          size: 9,
          font: timesRomanFont,
        });
        page.drawText(`${(value.total_cbm.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }))}`, {
          x: 380,
          y: height - (6 + i) * 9,
          size: 9,
          font: timesRomanFont,
        });
        page.drawText(`${value.total_grossWeight.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`, {
          x: 438,
          y: height - (6 + i) * 9,
          size: 9,
          font: timesRomanFont,
        });
        page.drawText(`${value.invoiced_date}`, {
          x: 520,
          y: height - (6 + i) * 9,
          size: 9,
          font: timesRomanFont,
        });

        if (Number(data.length) === Number(pageCount)) {
          page.drawText(`TOTAL`, {
            x: 180,
            y: height - (8 + i) * 9,
            size: 10,
            font: timesRomanFont,
          });
          page.drawText(
            `$${(this.getTotal(data, "total_amount").toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }))}`,
            { x: 220, y: height - (8 + i) * 9, size: 10, font: timesRomanFont }
          );
          page.drawText(
            `${this.getTotal(data, "total_quantity").toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`,
            { x: 295, y: height - (8 + i) * 9, size: 10, font: timesRomanFont }
          );
          page.drawText(
            `${this.getTotal(data, "total_cbm").toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`,
            { x: 380, y: height - (8 + i) * 9, size: 10, font: timesRomanFont }
          );
          page.drawText(
            `${this.getTotal(data, "total_grossWeight").toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`,
            { x: 438, y: height - (8 + i) * 9, size: 10, font: timesRomanFont }
          );
        }
      });
    });

    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], {
      type: "application/pdf;charset=utf-8",
    });
    saveAs(pdfBlob, "order-summary-dashboard-report.pdf");
  };

  handleDownload = (data) => {
    const { checkedList } = this.state;
    checkedList.includes("EXCEL") && this.exportExcel(data);
    checkedList.includes("CSV") && this.exportCSV(data);
    checkedList.includes("PDF") && this.exportPDF(data);
  };

  componentDidMount() {
    this.props.fetchPermissions();
    this.props.fetchOrderSummary();
  }


  render() {
    const { permissions, orderSummary } = this.props;
    const {
      radio,
      selectPODate,
      month,
      startDate,
      endDate,
      rangePicker,
      selectCustPO,
      selectStatus,
      selectCreatedAt,
      showDownloadModal,
      showCustomDateModal,
      checkedList,
    } = this.state;
    const isLoading = orderSummary.fetching || permissions.fetching;
    let orderSummaryData = orderSummary.data;

    //Filters
    const filterSummaryData = orderSummary.data
    // let customer_po_number_filter = [...new Set(filterSummaryData.map((value) => value.customer_po_number))];

    // let status_filter = []
    // filterSummaryData.map((value) => {
    //   if (value.status === 'invoiced') status_filter.push('invoiced')
    //   else status_filter.push('in process')
    // })
    // status_filter = [...new Set(status_filter)];

    // let created_date_filter = [...new Set(filterSummaryData.map((value) => value.so_created_on))];


    orderSummaryData = this.filterData(orderSummaryData, function (item) {
      return moment(moment(item.so_created_on).format("MM-DD-YYYY")).isBetween(
        startDate,
        endDate,
        null,
        "[]"
      );
    });

    let customer_po_number_filter = [...new Set(orderSummaryData.map((value) => value.customer_po_number))];


    let status_filter = []
    orderSummaryData.map((value) => {
      if (value.status === 'invoiced') status_filter.push('invoiced')
      else status_filter.push('in process')
    })
    status_filter = [...new Set(status_filter)];

    let created_date_filter = [...new Set(orderSummaryData.sort((a, b) => moment(b.billing_creation_dat).unix() - moment(a.billing_creation_dat).unix()).map((value) => value.billing_creation_dat))];

    // orderSummaryData = selectPODate === "all" ? orderSummaryData : orderSummaryData.filter(value => value.po_date === selectPODate)

    orderSummaryData =
      // selectCustPO === "all"
      selectCustPO.includes("all") && selectCustPO.length === 1
        ? orderSummaryData
        : orderSummaryData.filter(
          value => selectCustPO.includes(value.customer_po_number)
        );

    if (selectCustPO[0] === 'all' && selectCustPO.length > 1) {
      selectCustPO.splice(selectCustPO.indexOf('all'), 1)
      orderSummaryData = orderSummaryData.filter(value => selectCustPO.includes(value.customer_po_number))

      let newStatus = []
      orderSummaryData.map((value) => {
        if (value.status === 'invoiced') newStatus.push('invoiced')
        else newStatus.push('in process')
      })
      status_filter = [...new Set(newStatus)]

      created_date_filter = [...new Set(orderSummaryData.map((value) => value.billing_creation_dat))]

    } else if (selectCustPO[selectCustPO.length - 1] === 'all' && selectCustPO.length > 1) {
      selectCustPO.splice(0, selectCustPO.length, 'all')

      orderSummaryData = this.filterData(orderSummaryData, function (item) {
        return moment(moment(item.billing_creation_dat).format("MM-DD-YYYY")).isBetween(
          startDate,
          endDate,
          null,
          "[]"
        );
      });

      let newStatus = []
      orderSummaryData.map((value) => {
        if (value.status === 'invoiced') newStatus.push('invoiced')
        else newStatus.push('in process')
      })
      status_filter = [...new Set(newStatus)]

      created_date_filter = [...new Set(orderSummaryData.map((value) => value.billing_creation_dat))]

    } else if (selectCustPO.length > 0 && !selectCustPO.includes('all')) {
      orderSummaryData = orderSummaryData.filter(value => selectCustPO.includes(value.customer_po_number))

      let newStatus = []
      orderSummaryData.map((value) => {
        if (value.status === 'invoiced') newStatus.push('invoiced')
        else newStatus.push('in process')
      })
      status_filter = [...new Set(newStatus)]

      created_date_filter = [...new Set(orderSummaryData.map((value) => value.billing_creation_dat))]

    } else if (selectCustPO.length === 0) {
      orderSummaryData = orderSummaryData.filter(value => selectCustPO.includes(value.customer_po_number))

      let newStatus = []
      orderSummaryData.map((value) => {
        if (value.status === 'invoiced') newStatus.push('invoiced')
        else newStatus.push('in process')
      })
      status_filter = [...new Set(newStatus)]
      created_date_filter = [...new Set(orderSummaryData.map((value) => value.billing_creation_dat))]
    }

    orderSummaryData =
      selectStatus === "all"
        // selectStatus.includes("all") && selectStatus.length === 1
        ? orderSummaryData
        : orderSummaryData.filter((value) => selectStatus === 'invoiced' ? value.status === 'invoiced' : value.status !== 'invoiced')
    // : orderSummaryData.filter(
    //   value => selectStatus.includes(value.status)
    // )

    // if (selectStatus[0] === 'all' && selectStatus.length > 1) {
    //   selectStatus.splice(selectStatus.indexOf('all'), 1)
    //   orderSummaryData = orderSummaryData.filter(value => selectStatus.includes(value.status))

    //   status_filter = [...new Set(orderSummaryData)]
    //   created_date_filter = [...new Set(orderSummaryData.map((value) => value.billing_creation_dat))]

    // } else if (selectStatus[selectStatus.length - 1] === 'all' && selectStatus.length > 1) {
    //     selectStatus.splice(0, selectStatus.length, 'all')

    //     orderSummaryData = this.filterData(orderSummaryData, function (item) {
    //       return moment(moment(item.billing_creation_dat).format("MM-DD-YYYY")).isBetween(
    //         startDate,
    //         endDate,
    //         null,
    //         "[]"
    //       );
    //     });

    //     status_filter = [...new Set(orderSummaryData.map((value) => {
    //       if (value.status === 'invoiced') status_filter.push('invoiced')
    //       else status_filter.push('in process')
    //     }))]
    //     created_date_filter = [...new Set(orderSummaryData.map((value) => value.billing_creation_dat))]

    // } else if (selectStatus.length > 0 && !selectStatus.includes('all')) {
    //     orderSummaryData = orderSummaryData.filter(value => selectStatus.includes(value.status))

    //     status_filter = [...new Set(orderSummaryData)]
    //     created_date_filter = [...new Set(orderSummaryData.map((value) => value.billing_creation_dat))]

    // } else if (selectStatus.length === 0) {
    //     orderSummaryData = orderSummaryData.filter(value => selectStatus.includes(value.status))

    //     status_filter = [...new Set(orderSummaryData)]
    //     created_date_filter = [...new Set(orderSummaryData.map((value) => value.billing_creation_dat))]
    // }


    orderSummaryData =
      // selectCreatedAt === "all"
      selectCreatedAt.includes("all") && selectCreatedAt.length === 1
        ? orderSummaryData
        : orderSummaryData.filter((value) => selectCreatedAt.includes(moment(value.billing_creation_dat).format("MM-DD-YYYY")));

    if (selectCreatedAt[0] === 'all' && selectCreatedAt.length > 1) {
      selectCreatedAt.splice(selectCreatedAt.indexOf('all'), 1)
      orderSummaryData = orderSummaryData.filter((value) => selectCreatedAt.includes(moment(value.billing_creation_dat).format("MM-DD-YYYY")))

      customer_po_number_filter = [...new Set(orderSummaryData.map((value) => value.customer_po_number))]
      let newStatus = []
      orderSummaryData.map((value) => {
        if (value.status === 'invoiced') newStatus.push('invoiced')
        else newStatus.push('in process')
      })
      status_filter = [...new Set(newStatus)]

    } else if (selectCreatedAt[selectCreatedAt.length - 1] === 'all' && selectCreatedAt.length > 1) {
      selectCreatedAt.splice(0, selectCreatedAt.length, 'all')

      orderSummaryData = this.filterData(orderSummaryData, function (item) {
        return moment(moment(item.billing_creation_dat).format("MM-DD-YYYY")).isBetween(
          startDate,
          endDate,
          null,
          "[]"
        );
      });

      let newStatus = []
      orderSummaryData.map((value) => {
        if (value.status === 'invoiced') newStatus.push('invoiced')
        else newStatus.push('in process')
      })
      status_filter = [...new Set(newStatus)]
      customer_po_number_filter = [...new Set(orderSummaryData.map((value) => value.customer_po_number))]

    } else if (selectCreatedAt.length > 0 && !selectCreatedAt.includes('all')) {
      orderSummaryData = orderSummaryData.filter((value) => selectCreatedAt.includes(moment(value.billing_creation_dat).format("MM-DD-YYYY")))

      customer_po_number_filter = [...new Set(orderSummaryData.map((value) => value.customer_po_number))]
      let newStatus = []
      orderSummaryData.map((value) => {
        if (value.status === 'invoiced') newStatus.push('invoiced')
        else newStatus.push('in process')
      })
      status_filter = [...new Set(newStatus)]

    } else if (selectCreatedAt.length === 0) {
      orderSummaryData = orderSummaryData.filter((value) => selectCreatedAt.includes(moment(value.billing_creation_dat).format("MM-DD-YYYY")))

      customer_po_number_filter = [...new Set(orderSummaryData.map((value) => value.customer_po_number))]
      let newStatus = []
      orderSummaryData.map((value) => {
        if (value.status === 'invoiced') newStatus.push('invoiced')
        else newStatus.push('in process')
      })
      status_filter = [...new Set(newStatus)]
    }
    // console.log("printers",customer_po_number_filter);
    let startOfYear = moment().startOf("year").format("MM-DD-YYYY");
    let endOfYear = moment().endOf("year").format("MM-DD-YYYY");
    let startOfMonth = moment().startOf("month").format("MM-DD-YYYY");
    let endOfMonth = moment().endOf("month").format("MM-DD-YYYY");

    if (radio === "YTD") {
      orderSummaryData = orderSummaryData.filter((value) =>
        moment(value.so_created_on).isBetween(startOfYear, endOfYear, null, "[]")
      );
    } else {
      orderSummaryData = orderSummaryData.filter((value) =>
        moment(value.so_created_on).isBetween(startOfMonth, endOfMonth, null, "[]")
      );
    }

    const customer_po_number = [
      ...new Set(orderSummaryData.map((value) => value.customer_po_number)),
    ];

    // const po_date = [
    //   ...new Set(orderSummaryData.map((value) => value.po_date)),
    // ];


    const inProcessData = orderSummaryData.filter(
      (value) => value.status !== "invoiced"
    );

    // const inProcessAmount = this.getTotal(inProcessData, "delivered_amount");
    // const inProcessQuantity = this.getTotal(inProcessData, "delivered_qty");
    const inProcessAmount = this.getTotalInProcessAmount(inProcessData)
    const inProcessQuantity = this.getTotalInProcessQuantity(inProcessData)

    const invoicedData = orderSummaryData.filter(
      (value) => value.status === "invoiced"
    );
    // const invoicedAmount = this.getTotal(invoicedData, "billed_amount");
    // const invoicedQuantity = this.getTotal(invoicedData, "billed_qty");
    const invoicedAmount = this.getTotalInvoicedAmount(invoicedData)
    const invoicedQuantity = this.getTotalInvoicedQuantity(invoicedData)

    const inProcessPercent = Math.round(
      (parseFloat(inProcessData.length) / parseFloat(orderSummaryData.length)) *
      100
    );
    const invoicedPercent = Math.round(
      (parseFloat(invoicedData.length) / parseFloat(orderSummaryData.length)) *
      100
    );



    const orderSummaryAmount = () => {
      return (invoicedAmount + inProcessAmount)
    }

    const orderSummaryQuantity = () => {
      return (inProcessQuantity + invoicedQuantity)
    }

    const groupBySo = (key) => (array) => {
      const test = [];
      return array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});
    };



    const newPoRateData = () => {
      const groupBysoNumber = groupBySo("sales_document"); // sales_document is equivalent to 'so number'
      const mappedGroupBySoNumber = _.map(
        groupBysoNumber(orderSummaryData),
        (mappedSoNumber) => mappedSoNumber
      );

      const arrayList = []
      mappedGroupBySoNumber.map(data => {
        arrayList.push({
          key: data[0].sales_document,
          po_number: data[0].po_number,
          percent: (data.filter(x => x.status === 'invoiced').length / data.length) * 100,
          po_date: data.po_date,
        })
      })
      return arrayList
    }

    const topSkuData = () => {

      const groupByMaterial = groupBySo("material");

      const mappedGroupByMaterial = _.map(
        groupByMaterial(orderSummaryData),
        (mappedMaterial) => mappedMaterial
      );

      const arrayTotal = []
      mappedGroupByMaterial.map(data => {
        const totalData = {
          totalBilledQty: data.reduce((a, c) => a + parseFloat(c.billed_qty), 0),
          totalOrderQty: data.reduce((a, c) => a + parseFloat(c.order_qty), 0),
          productVariant: data[0].product_variant
        }
        arrayTotal.push(totalData)
      })
      return arrayTotal.sort((a, b) => b.totalBilledQty - a.totalBilledQty)
    }



    // const poRateData = () => {
    //   const invoiced = orderSummaryData.filter(data => data.status === 'invoiced')
    //   let invoicedQuantity = parseFloat(this.getTotal(invoiced, "billed_qty"));
    //   let totalQuantity = parseFloat(this.getTotal(invoiced, "order_qty"));
    //   return invoiced.map(data => {
    //     return {
    //       key: data.po_number,
    //       po_number: data.po_number,
    //       percent: (invoicedQuantity / totalQuantity) * 100,
    //       targetEtd: data.distributor.etd,
    //       po_date: data.po_date,
    //       delayed: moment().diff(moment(data.distributor.etd_month), "days") > 0,
    //     };
    //   }).sort((a, b) => moment(b.po_date).unix() - moment(a.po_date).unix());
    // }
    // const poRateData = orderSummaryData
    //   .map((value) => {
    //     let invoiced = value.products.filter(
    //       (item) => item.status === "invoiced"
    //     );
    //     let invoicedQuantity = parseFloat(this.getTotal(invoiced, "quantity"));
    //     let totalQuantity = parseFloat(
    //       this.getTotal(value.products, "quantity")
    //     );
    //     return {
    //       key: value.customer_po_number,
    //       po_number: value.po_number,
    //       percent: (invoicedQuantity / totalQuantity) * 100,
    //       targetEtd: value.etd,
    //       po_date: value.po_date,
    //       delayed: moment().diff(moment(value.etd), "days") > 0,
    //     };
    //   })
    //   .sort((a, b) => moment(b.po_date).unix() - moment(a.po_date).unix());

    // const topSku = orderSummaryData.flatMap((value) => value.products);
    // const topSkuData = Object.entries(this.groupBy(topSku, "material_code"))
    //   .map(([key, value]) => {
    //     let invoiced = value.filter((item) => item.status === "invoiced");
    //     let invoicedQuantity = this.getTotal(invoiced, "quantity");
    //     let totalQuantity = this.getTotal(value, "quantity");
    //     let item = value.find(
    //       (value) => Number(value.material_code) === Number(key)
    //     );
    //     return {
    //       key: key,
    //       name: item.name,
    //       total_quantity: totalQuantity,
    //       invoiced_quantity: invoicedQuantity,
    //       packing_scheme: item.packing_scheme,
    //       percent: (invoicedQuantity / totalQuantity) * 100,
    //     };
    //   })
    //   .sort((a, b) => b.total_quantity - a.total_quantity);

    const downloadData = orderSummaryData.map((value) => {
      // console.log("VALUE", value)
      return {
        customer_po_number: value.customer_po_number || '',
        so_number: value.sales_document || '',
        status: value.status === "invoiced" ? "Invoiced" : "In Process",
        total_amount: this.getQtyBasedOnStatus(value.status, value) * (value.product_variant && value.product_variant.pricelist.length > 0 && value.product_variant.pricelist[0].selling_price) || 0,
        total_quantity: this.getQtyBasedOnStatus(value.status, value) || '0',
        total_cbm: (value.product_variant && value.product_variant.cbm) * this.getQtyBasedOnStatus(value.status, value) || 0,
        total_grossWeight: (value.product_variant && value.product_variant.gross_weight && value.product_variant.gross_weight.toLocaleString()) || 0,
        po_date: value.po_date || '',
        invoiced_date: value.billing_creation_dat || ''
      };
    });

    const inProcessChartData = {
      datasets: [
        {
          data: isNaN(inProcessPercent)
            ? [0, 100]
            : [inProcessPercent, 100 - inProcessPercent],
          backgroundColor: ["#0785E3", "#CDE7F9"],
          hoverBackgroundColor: ["#0785E3"],
        },
      ],
    };

    const invoicedChartData = {
      datasets: [
        {
          data: isNaN(invoicedPercent)
            ? [0, 100]
            : [invoicedPercent, 100 - invoicedPercent],
          backgroundColor: ["#59BFB9", "#DEF2F1"],
          hoverBackgroundColor: ["#59BFB9"],
        },
      ],
    };

    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          var ctx = chart.chart.ctx;

          //Get options from the center object in options
          var centerConfig = chart.config.options.elements.center;
          var fontStyle = centerConfig.fontStyle || "Montserrat";
          var txt = centerConfig.text;
          var color = centerConfig.color || "#000";
          //Start with a base font of 30px
          ctx.font = "24px " + fontStyle;

          //Set font settings to draw it correctly.
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
          ctx.fillStyle = color;

          ctx.fillText(txt, centerX, centerY);
        }
      },
    });

    const inProcessOptions = {
      cutoutPercentage: 80,
      tooltips: {
        enabled: false,
      },
      elements: {
        arc: {
          borderWidth: 0,
        },
        center: {
          text: isNaN(inProcessPercent) ? "0%" : `${inProcessPercent}%`,
          color: "#0785E3",
        },
      },
    };

    const invoicedOptions = {
      cutoutPercentage: 80,
      tooltips: {
        enabled: false,
      },
      elements: {
        arc: {
          borderWidth: 0,
        },
        center: {
          text: isNaN(invoicedPercent) ? "0%" : `${invoicedPercent}%`,
          color: "#59BFB9",
        },
      },
    };

    if (isLoading) {
      return (
        <Layout.Content>
          <Spin spinning={isLoading}>
            <Skeleton active />
          </Spin>
        </Layout.Content>
      );
    }
    const Filters = (mobile) => (
      <Row
        type="flex"
        justify={mobile ? "center" : "end"}
        gutter={mobile ? 0 : 52}
        className={mobile ? "dropdowns-mobile" : "dropdowns"}
      >
        <Col xs={24} sm={24} md={6} lg={5}>
          <p>SO DATE </p>
          <Select
            value={month}
            onSelect={(value) => this.handleMonth(value)}
            style={{ width: "100%" }}
            showSearch
            getPopupContainer={trigger => trigger.parentNode}
          >
            <Option value="today">Today</Option>
            <Option value="week">This Week</Option>
            <Option value="month">This Month</Option>
            <Option value="year">This Year</Option>
            <Option value="custom">Custom Date</Option>
          </Select>
        </Col>
        <Col xs={24} sm={24} md={6} lg={5}>
          <p>CUSTOMER PO # </p>
          <div onMouseDown={e => {
            e.preventDefault()
            return false
          }}
          >
          <Select
            mode="multiple"
            value={selectCustPO}
            onChange={async (value) => {
              await this.setState({ selectCustPO: [] })
              await this.setState({ selectCustPO: value })
            }}
            style={{ width: "100%" }}
            showSearch
            allowClear
            getPopupContainer={trigger => trigger.parentNode}
            dropdownRender={menu => (
              customer_po_number_filter.length > 0 ? 
              (
              <div>
                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                  <a
                      style={{ flex: 'none', padding: '2px', display: 'block', cursor: 'pointer' }}
                      onClick={async () => {
                        // console.log("clicked")
                        await this.setState({ selectCustPO: ['all'] })
                      }}
                      // onClick={console.log("clicked")}
                  >
                        <Icon type="select" /> Select All
                  </a>
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <a
                          style={{ flex: 'none', padding: '2px', display: 'block', cursor: 'pointer' }}
                          onClick={async () => {
                            await this.setState({ selectCustPO: [] })
                          }}
                      >
                          <Icon type="close" /> Deselect All
                  </a>
                  </div>
                <Divider style={{ margin: '4px 0' }} />
                {menu}
              </div>
              ) : menu
            )}
          >
            {/* <Divider style={{ margin: '4px 0' }} /> */}
            <Option value="all"
            disabled={selectCustPO.length !== 0 ? true : false}
            >All</Option>
            {customer_po_number_filter.sort((a, b) => a && a.localeCompare(b)).map((value, index) => (
              <Option key={index} value={value}>
                {value}
              </Option>
            ))}
          </Select>
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={5}>
          <p>ORDER STATUS </p>
          <Select
            value={selectStatus}
            onChange={async (value) => {
              await this.setState({ selectStatus: [] })
              await this.setState({ selectStatus: value })
            }}
            style={{ width: "100%", textTransform: "capitalize" }}
            showSearch
            getPopupContainer={trigger => trigger.parentNode}
          >
            <Option value="all">All</Option>
            {status_filter.map((value, index) => (
              <Option
                key={index}
                value={value}
                style={{ textTransform: "capitalize" }}
              >
                {value}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={6} lg={5}>
          <p> INVOICED DATE </p>
          <div onMouseDown={e => {
              e.preventDefault()
              return false
          }}
          >
          <Select
            mode="multiple"
            value={selectCreatedAt}
            onChange={async (value) => {
              await this.setState({ selectCreatedAt: [] })
              await this.setState({ selectCreatedAt: value })
            }}
            style={{ width: "100%" }}
            showSearch
            allowClear
            getPopupContainer={trigger => trigger.parentNode}
            dropdownRender={menu => (
              created_date_filter.length > 0 ?
              (<div>
                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                  <a
                      style={{ flex: 'none', padding: '2px', display: 'block', cursor: 'pointer' }}
                      onClick={async() => {
                        await this.setState({ selectCreatedAt: ['all'] })}
                      }
                  >
                        <Icon type="select" /> Select All
                  </a>
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                      <a
                          style={{ flex: 'none', padding: '2px', display: 'block', cursor: 'pointer' }}
                          // onClick={() => customerDeselectAll()}
                          onClick={async() => {
                            await this.setState({ selectCreatedAt: [] })}
                          }
                      >
                          <Icon type="close" /> Deselect All
                  </a>
                  </div>
                <Divider style={{ margin: '4px 0' }} />
                {menu}
              </div>)
              : menu
            )}
          >
            <Option value="all"
            disabled={selectCreatedAt.length !== 0 ? true : false}
            >All</Option>
            {created_date_filter.map((value, index) => (
              <Option key={index} value={moment(value).format("MM-DD-YYYY")}>
                {moment(value).format("MMM YYYY")}
              </Option>
            ))}
          </Select>
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} lg={4} style={{ display: "flex" }}>
          <Button
            type="link"
            onClick={() =>
              this.setState({
                // selectPODate: "all",
                month: "month",
                startDate: moment().startOf("month").format("MM-DD-YYYY"),
                endDate: moment().endOf("month").format("MM-DD-YYYY"),
                selectCustPO: ["all"],
                selectStatus: "all",
                selectCreatedAt: ["all"],
              })
            }
            style={{
              width: "100%",
              alignSelf: "flex-end",
              color: "#7A4521",
              fontWeight: 600,
              fontSize: 16,
              padding: 0,
            }}
          >
            Reset Filter
          </Button>
        </Col>
      </Row>
    );

    const meta = {
      title: "Order Summary Dashboard - URC Global Exports",
      description:
        "URC Global Exports seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: {
          keywords: "Order Summary Dashboard",
        },
      },
    };

    return (
      <DocumentMeta {...meta}>
        <Layout className="order-summary-dashboard-page">
          {permissions.data.some(
            (r) => r.name === "distributor.orders.view"
          ) ? (
              <Layout.Content>
                <Card
                  className="main-header"
                  style={{ position: "sticky", top: 64, zIndex: 999 }}
                >
                  <Row
                    type="flex"
                    justify="center"
                    style={{ margin: "18px 0px" }}
                  >
                    <Col span={22}>
                      <Row type="flex" align="middle" className="header">
                        <Col span={24}>
                          <Title level={3}>OMS Summary Dashboard</Title>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6}>
                          <p>
                            DATE AS OF: <span style={{ whiteSpace: 'nowrap' }}>{moment().format("LL")}</span>
                          </p>
                        </Col>
                        <Col xs={24} sm={24} md={18} lg={18}>
                          <MediaQuery minWidth={1224}>
                            {Filters(false)}
                          </MediaQuery>

                          <MediaQuery maxWidth={1224}>
                            <Row
                              type="flex"
                              align="middle"
                              style={{ margin: "10px 0" }}
                              gutter={8}
                            >
                              <Col sm={24} xs={24} md={10} lg={10}>
                                <Button
                                  className="btn-small btn-primary"
                                  onClick={() =>
                                    this.props.history.push("/order-status")
                                  }
                                  style={{ width: "100%", margin: '5px 0' }}
                                >
                                  <Icon type="layout" />Order History
                              </Button>
                              </Col>
                              <Col sm={24} xs={24} md={10} lg={10}>
                                <Button
                                  className="btn-small btn-primary"
                                  onClick={() =>
                                    this.props.history.push("/container-dashboard")
                                  }
                                  style={{ width: "100%", margin: '5px 0' }}
                                >
                                  <Icon type="layout" />Container Summary Breakdown
                              </Button>
                              </Col>
                              {permissions.data.some(
                                (r) => r.name === "distributor.orders.download"
                              ) ? (
                                  <Col sm={3} xs={3} md={2} lg={2} align="center">
                                    <img
                                      src={reportIcon}
                                      alt="report-download"
                                      onClick={() =>
                                        this.setState({ showDownloadModal: true })
                                      }
                                    />
                                  </Col>
                                ) : null}

                              <Col sm={3} xs={3} md={2} lg={2} align="center">
                                <img
                                  src={filterIcon}
                                  alt="filter-icon"
                                  onClick={this.showDrawer}
                                />
                              </Col>
                            </Row>
                          </MediaQuery>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>

                <Row type="flex" justify="center" style={{ margin: "20px 0px" }}>
                  <Col span={22}>
                    <Row
                      type="flex"
                      justify="space-between"
                      style={{ margin: "16px 0px" }}
                    >
                      <Col xs={24} sm={24} md={9} lg={9}>
                        <Row type="flex">
                          <Col xs={12} sm={12} md={10} lg={6}>
                            <h1 style={{ fontSize: "16px" }}>MTD / YTD Volume</h1>
                          </Col>
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={8}
                            align="right"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <label className="radio">
                              MTD
                            <input
                                type="radio"
                                name="volume"
                                checked={radio === "MTD"}
                                value="MTD"
                                onChange={(e) =>
                                  this.setState({ radio: e.target.value })
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="radio">
                              YTD
                            <input
                                type="radio"
                                name="volume"
                                checked={radio === "YTD"}
                                value="YTD"
                                onChange={(e) =>
                                  this.setState({ radio: e.target.value })
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={15} lg={15}>
                        <Row type="flex" justify="end" gutter={22}>
                          <MediaQuery minWidth={1224}>
                            <Col xs={24} sm={24} md={8} lg={8}>
                              <Button
                                className="btn-small btn-secondary-gold"
                                disabled={permissions.data.some(
                                  (r) => r.name === "distributor.orders.download"
                                ) ? false : true}
                                // disabled={true}
                                onClick={() =>
                                  this.setState({ showDownloadModal: true })
                                }
                                style={{ width: "100%" }}
                              >
                                <Icon type="line-chart" />
                              Extract Report
                            </Button>
                            </Col>
                            <Col xs={24} sm={24} md={7} lg={7}>
                              <Button
                                className="btn-small btn-primary"
                                onClick={() =>
                                  this.props.history.push("/order-status")
                                }
                                style={{ width: "100%" }}
                              >
                                <Icon type="layout" />Order History
                            </Button>
                            </Col>
                            <Col xs={24} sm={24} md={9} lg={9}>
                              <Button
                                className="btn-small btn-primary"
                                onClick={() =>
                                  this.props.history.push("/container-dashboard")
                                }
                                style={{ width: "100%" }}
                              >
                                <Icon type="layout" />Container Summary Breakdown
                            </Button>
                            </Col>
                          </MediaQuery>
                        </Row>
                      </Col>
                    </Row>

                    <Row type="flex" gutter={18}>
                      <Col xs={24} sm={24} md={24} lg={8}>
                        <Row>
                          <Col span={24} style={{ margin: "9px 0px" }}>
                            <Card className="summary-card">
                              <Card.Grid hoverable={false} className="main-card">
                                <Row>
                                  <Col span={24}>
                                    <h3>ORDER SUMMARY</h3>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={12}>
                                    <p>Total PO Count</p>
                                  </Col>
                                  <Col span={12} align="right">
                                    <span>{orderSummaryData.length}</span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={12}>
                                    <p>Total Quantity (In CS)</p>
                                  </Col>
                                  <Col span={12} align="right">
                                    <span>
                                      {orderSummaryQuantity().toLocaleString()}
                                    </span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={12}>
                                    <p>Total Amount (In USD)</p>
                                  </Col>
                                  <Col span={12} align="right">
                                    <span>
                                      ${numberFormat(orderSummaryAmount())}
                                    </span>
                                  </Col>
                                </Row>
                              </Card.Grid>
                              <Card.Grid hoverable={false}>
                                <Row>
                                  <Col span={24}>
                                    <h3>IN PROCESS</h3>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={12}>
                                    <p>Total PO Count</p>
                                  </Col>
                                  <Col span={12} align="right">
                                    <span>{inProcessData.length}</span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={12}>
                                    <p>Total Quantity (In CS)</p>
                                  </Col>
                                  <Col span={12} align="right">
                                    <span>
                                      {inProcessQuantity.toLocaleString()}
                                    </span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={12}>
                                    <p>Total Amount (In USD)</p>
                                  </Col>
                                  <Col span={12} align="right">
                                    <span>${numberFormat(inProcessAmount)}</span>
                                  </Col>
                                </Row>
                              </Card.Grid>
                              <Card.Grid hoverable={false}>
                                <Row>
                                  <Col span={24}>
                                    <h3>INVOICED</h3>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={12}>
                                    <p>Total PO Count</p>
                                  </Col>
                                  <Col span={12} align="right">
                                    <span>{invoicedData.length}</span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={12}>
                                    <p>Total Quantity (In CS)</p>
                                  </Col>
                                  <Col span={12} align="right">
                                    <span>
                                      {invoicedQuantity.toLocaleString()}
                                    </span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={12}>
                                    <p>Total Amount (In USD)</p>
                                  </Col>
                                  <Col span={12} align="right">
                                    <span>${numberFormat(invoicedAmount)}</span>
                                  </Col>
                                </Row>
                              </Card.Grid>
                            </Card>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={9}>
                        <Row>
                          <Col span={24} style={{ margin: "9px 0px" }}>
                            <Card className="percentage-card">
                              <Row>
                                <Col
                                  xs={24}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  style={{
                                    margin: 0,
                                    borderRight: "1px solid #e8e8e8",
                                    padding: "16px 24px",
                                  }}
                                >
                                  <Row type="flex">
                                    <Col span={24} align="center">
                                      <Title level={4}>IN PROCESS %</Title>
                                    </Col>
                                    <Col span={24} align="center">
                                      <Doughnut
                                        data={inProcessChartData}
                                        height={55}
                                        width={100}
                                        options={inProcessOptions}
                                        legend={{ display: false }}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col
                                  xs={24}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  style={{ margin: 0, padding: "16px 24px" }}
                                >
                                  <Row type="flex">
                                    <Col span={24} align="center">
                                      <Title level={4}>INVOICED %</Title>
                                    </Col>
                                    <Col span={24} align="center">
                                      <Doughnut
                                        data={invoicedChartData}
                                        height={55}
                                        width={100}
                                        options={invoicedOptions}
                                        legend={{ display: false }}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24} style={{ margin: "9px 0px" }}>
                            <Card
                              title="PO COMPLETION RATE - VOL INVOICED"
                              className="po-rate-card"
                            >
                              {newPoRateData().length > 0 ? (
                                <Row type="flex">
                                  <Col xs={24} sm={24} md={12} lg={12}>
                                    {newPoRateData()
                                      .map((value, index) => (
                                        <Row
                                          key={index}
                                          type="flex"
                                          justify="center"
                                          style={{ margin: "8px 0px" }}
                                        >
                                          <Col span={20}>
                                            <Row type="flex">
                                              <Col
                                                span={12}
                                                className="bar-description"
                                              >
                                                <span>{value.key}</span>
                                              </Col>
                                              <Col
                                                span={12}
                                                className="bar-percent"
                                                align="right"
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 18,
                                                    color:
                                                      value.delayed &&
                                                        Number(value.percent) !==
                                                        100
                                                        ? "#EA444D"
                                                        : "#404040",
                                                  }}
                                                >
                                                  {Math.round(value.percent)}%
                                              </span>
                                              </Col>
                                            </Row>
                                            <Progress
                                              percent={value.percent}
                                              className={
                                                value.delayed &&
                                                  Number(value.percent) !== 100
                                                  ? "delayed"
                                                  : "not-delayed"
                                              }
                                              showInfo={false}
                                            />
                                          </Col>
                                        </Row>
                                      ))
                                      .slice(0, 3)}
                                  </Col>
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={12}
                                    lg={12}
                                    style={{ marginBottom: 12 }}
                                  >
                                    {newPoRateData()
                                      .map((value, index) => (
                                        <Row
                                          key={index}
                                          type="flex"
                                          justify="center"
                                          style={{ margin: "8px 0px" }}
                                        >
                                          <Col span={20}>
                                            <Row type="flex">
                                              <Col
                                                span={12}
                                                className="bar-description"
                                              >
                                                <span>{value.key}</span>
                                              </Col>
                                              <Col
                                                span={12}
                                                className="bar-percent"
                                                align="right"
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 18,
                                                    color:
                                                      value.delayed &&
                                                        Number(value.percent) !==
                                                        100
                                                        ? "#EA444D"
                                                        : "#404040",
                                                  }}
                                                >
                                                  {Math.round(value.percent)}%
                                                </span>
                                              </Col>
                                            </Row>
                                            <Progress
                                              percent={value.percent}
                                              className={
                                                value.delayed &&
                                                  Number(value.percent) !== 100
                                                  ? "delayed"
                                                  : "not-delayed"
                                              }
                                              showInfo={false}
                                            />
                                          </Col>
                                        </Row>
                                      ))
                                      .slice(3, 6)}
                                  </Col>
                                </Row>
                              ) : (
                                  <Empty />
                                )}
                            </Card>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={7}>
                        <Row>
                          <Col span={24} style={{ margin: "9px 0px" }}>
                            <Card
                              title="TOP PURCHASED SKU'S - VOL INVOICED"
                              className="top-sku-card"
                            >
                              {topSkuData().length > 0 ? (
                                <React.Fragment>
                                  {topSkuData()
                                    .map((value, index) => (
                                      <Row
                                        key={index}
                                        type="flex"
                                        justify="center"
                                        align="middle"
                                        style={{ marginBottom: 4 }}
                                      >
                                        <Col span={21}>
                                          <Row type="flex">
                                            <Col
                                              span={20}
                                              className="sku-description"
                                            >
                                              <span>
                                                {index + 1}. {value.productVariant && value.productVariant.name}{" "}
                                                {value.productVariant && value.productVariant.packing_scheme}
                                              </span>
                                              <p>
                                                {value.totalBilledQty.toLocaleString()}{" "}
                                              of{" "}
                                                {value.totalOrderQty.toLocaleString()}{" "}
                                              cases
                                            </p>
                                            </Col>
                                            <Col
                                              span={4}
                                              className="sku-percent"
                                              align="right"
                                            >
                                              {Math.round((value.totalBilledQty / value.totalOrderQty) * 100)}%
                                          </Col>
                                            <Col span={24}>
                                              <Progress
                                                percent={(value.totalBilledQty / value.totalOrderQty) * 100}
                                                strokeColor="#0785E3"
                                                showInfo={false}
                                              />
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    ))
                                    .slice(0, 8)}
                                </React.Fragment>
                              ) : (
                                  <Empty />
                                )}
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <MediaQuery maxWidth={1224}>
                  <Drawer
                    placement="bottom"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}
                  >
                    {Filters(true)}
                  </Drawer>
                </MediaQuery>

                <Modal
                  title="Download Option"
                  visible={showDownloadModal}
                  width={350}
                  onCancel={() => this.setState({ showDownloadModal: false })}
                  footer={null}
                  className="download-option-modal"
                >
                  <Checkbox
                    indeterminate={this.state.indeterminate}
                    onChange={this.onCheckAllChange}
                    checked={this.state.checkAll}
                  >
                    All
                </Checkbox>
                  <br />
                  <CheckboxGroup
                    options={plainOptions}
                    value={checkedList}
                    onChange={this.onChangeCheckbox}
                  />
                  <Row>
                    <Col span={24}>
                      <Button
                        type="link"
                        onClick={() =>
                          this.setState({
                            checkedList: [],
                            indeterminate: false,
                            checkAll: false,
                          })
                        }
                      >
                        Clear All
                    </Button>
                    </Col>
                  </Row>

                  <Row type="flex" style={{ margin: "10px 0px" }}>
                    <Col span={12}>
                      <Button
                        className="btn-small btn-default"
                        onClick={() =>
                          this.setState({
                            showDownloadModal: false,
                            checkedList: [],
                            indeterminate: false,
                            checkAll: false,
                          })
                        }
                      >
                        Cancel
                    </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        className="btn-small btn-primary"
                        disabled={checkedList.length < 1}
                        onClick={() => this.handleDownload(downloadData)}
                      >
                        Download
                    </Button>
                    </Col>
                  </Row>
                </Modal>

                <Modal
                  width={400}
                  visible={showCustomDateModal}
                  onCancel={() => this.setState({ showCustomDateModal: false })}
                  footer={null}
                  className="modal"
                  closable={false}
                >
                  <Row>
                    <Col span={24}>
                      <p>Period Covered:</p>
                      <RangePicker
                        size="large"
                        value={rangePicker}
                        onCalendarChange={(value) =>
                          this.setState({ rangePicker: value })
                        }
                      />
                    </Col>
                    <Col span={24}>
                      <Row
                        type="flex"
                        justify="end"
                        style={{ marginTop: "20px" }}
                      >
                        <Col span={12}>
                          <Button
                            className="btn-small btn-default"
                            onClick={() =>
                              this.setState({ showCustomDateModal: false })
                            }
                          >
                            Cancel
                        </Button>
                        </Col>
                        <Col span={12}>
                          <Button
                            className="btn-small btn-primary"
                            disabled={rangePicker.length < 1}
                            onClick={() =>
                              this.setState({
                                showCustomDateModal: false,
                                startDate: moment(rangePicker[0]).format(
                                  "MM-DD-YYYY"
                                ),
                                endDate: moment(rangePicker[1]).format(
                                  "MM-DD-YYYY"
                                ),
                              })
                            }
                          >
                            Apply
                        </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Modal>
              </Layout.Content>
            ) : (
              <Result
                status="warning"
                title="You have no permission to view this page."
              />
            )}
        </Layout>
      </DocumentMeta>
    );
  }
}

function mapStateToProps(state) {
  const { permissions, orderSummary } = state;

  return {
    permissions,
    orderSummary,
  };
}

export default connect(mapStateToProps, {
  fetchPermissions,
  fetchOrderSummary,
})(OrderSummary);
