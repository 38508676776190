import axios from 'axios';

import config from '../config';
import { headers } from '../utilities'

export const POST_BULK_UPLOAD = 'POST_BULK_UPLOAD';
export const POST_BULK_UPLOAD_FULFILLED = 'POST_BULK_UPLOAD_FULFILLED';
export const POST_BULK_UPLOAD_REJECTED = 'POST_BULK_UPLOAD_REJECTED';

export const submitBulkUpload = (data) => dispatch => {
  dispatch({
    type: POST_BULK_UPLOAD,
    payload: {}
  });
  return axios.post(`${config.REACT_APP_API_BASE_URL}/po-bulk-upload`, data, { headers: headers() })
    .then(response => {
      dispatch({
        type: POST_BULK_UPLOAD_FULFILLED,
        payload: response.data
      });
      return { error: false, response };
    })
    .catch(err => {
      dispatch({
        type: POST_BULK_UPLOAD_REJECTED,
        payload: err
      });
      return { error: true, err };
    });
}




