import React, { Component } from "react";
import DocumentMeta from "react-document-meta";
import {
  Menu,
  Layout,
  Row,
  Col,
  Form,
  Input,
  Collapse,
  Icon,
  Typography,
  Drawer,
  Button,
  Modal
} from "antd";
import { connect } from 'react-redux';
import * as _ from "lodash";
import YouTube from "react-youtube";
import "./FAQ.scss";
import MediaQuery from "react-responsive";
import { isLocalStorageEmpty } from '../../utilities'
import { fetchFaq, postFaqFeedback } from '../../actions'
import ModalFaqSurvery from './modalFaqSurvey'
const { Title, Paragraph } = Typography;
const { Content } = Layout
const meta = {
  title: "FAQ - URC Global Exports",
  description:
    "URC Global Exports seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
  canonical: window.location.href,
  meta: {
    charset: "utf-8",
    name: {
      keywords: "FAQ"
    }
  }
};

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      active: "0",
      activeFaqCategoryId: 0,
      activeFaqQuestionAndAnswer: [],
      feedbackYesIdArray: [],
      selectedItem: {},
      modalFaqSurveyVisible: false,
      showDrawer: false,
      isLoggedIn: false
    };
    this.modalSurveyVisible = this.modalSurveyVisible.bind(this)
  }

  videoOnReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  componentDidMount() {
    let isLoggedIn = false
    if (!isLocalStorageEmpty()) {
      isLoggedIn = false
    } else {
      isLoggedIn = true
    }
    this.setState({ isLoggedIn })
    this.props.onLoadFaq(isLoggedIn).then((res) => {
      const faqData = res.response.data
      const activeFaqQuestionAndAnswerArray = []
      faqData.map((data) => data.active_faqs.map((data) => {
        activeFaqQuestionAndAnswerArray.push({ ...data })
      }))
      this.setState({ activeFaqQuestionAndAnswer: activeFaqQuestionAndAnswerArray })
    })
  }


  handleSelectCategory(key) {
    if (key === '0') {
      const activeFaqQuestionAndAnswerArray = []
      this.props.faqData.map((data) => data.active_faqs.map((data) => {
        activeFaqQuestionAndAnswerArray.push({ ...data })
      }))
      return this.setState({ activeFaqCategoryId: Number(key), activeFaqQuestionAndAnswer: activeFaqQuestionAndAnswerArray })
    }
    const activeFaqQuestionAndAnswer = this.props.faqData.find((data) => Number(data.id) === Number(key)).active_faqs
    this.setState({ activeFaqCategoryId: Number(key), activeFaqQuestionAndAnswer })
  }

  modalSurveyVisible() {
    this.setState({ modalFaqSurveyVisible: !this.state.modalFaqSurveyVisible })
  }
  handleSubmitNoSurvey(item) {
    this.setState({ modalFaqSurveyVisible: true, selectedItem: item })
  }

  handleSubmitYesSurvey(item) {
    const self = this
    Modal.confirm({
      title: 'Are you sure',
      content: 'You want to submit your feedback',
      okText: 'Ok',
      onOk() {
        self.props.onSubmitFaqFeedback({
          faq_content_id: item.id,
          is_yes: true,
          response: '',
          message: ''
        }).then((res) => {
          if (!res.error) {
            const isFound = self.state.feedbackYesIdArray.find((data) => data.id === item.id) !== undefined
            if (!isFound) {
              self.state.feedbackYesIdArray.push({ id: item.id })
            }
            self.forceUpdate()
          }
        })
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if ((this.props.faqFeedbackSuccess !== nextProps.faqFeedbackSuccess) && nextProps.faqFeedbackSuccess) {
      Modal.success({
        title: "Success",
        content: `You have submitted your feedback.`,
        okText: ' Okay',
      })
    }
  }

  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    //function inside function, this will convert the dot string into actual object
    const convertStringDotNotation = (desc, obj) => {
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    }

    let newArrayObj
    if (this.state.activeFaqCategoryId === 0) {
      const activeFaqQuestionAndAnswerArray = []
      this.props.faqData.map((data) => data.active_faqs.map((data) => {
        activeFaqQuestionAndAnswerArray.push({ ...data })
      }))
      newArrayObj = activeFaqQuestionAndAnswerArray
    } else {
      newArrayObj = this.props.faqData.find((data) => Number(data.id) === Number(this.state.activeFaqCategoryId)).active_faqs
    }

    //if SearchTerm is Empty return original data.
    if (!searchTerm) {
      return this.setState({ activeFaqQuestionAndAnswer: newArrayObj })
    }
    let newArray = []
    newArrayObj.forEach((rowItem, i) => {
      searchKeys.forEach(keys => {
        let rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
        let isFound = (rowItemKeys) && rowItemKeys.toString().toLowerCase().includes(searchTerm.toString().toLowerCase())
        if (isFound) newArray.push(rowItem)
      })
    })
    //Removed Duplicate Entry in Array.
    const newArrayElement = newArray.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    })
    this.setState({ activeFaqQuestionAndAnswer: newArrayElement })
  }

  render() {
    const { keyword, active, showDrawer } = this.state;
    const opts = {
      height: "290",
      width: "540",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1
      }
    };

    return (
      <DocumentMeta {...meta}>
        <Layout className="banner" style={{ display: !this.state.isLoggedIn && 'none' }}>
          <Layout.Content >
            <Row type="flex" justify="center" align="middle">
              <Col span={18}>
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  gutter={48}
                >
                  <Col xs={24} sm={24} md={12} lg={11}>
                    <Title className="title">
                      Your One-Stop Shop for all your Ordering Needs is
                      Available Now!
                    </Title>
                    <Paragraph className="description">
                      Order Management System (OMS) is your online ordering &
                      tracking tool which aims to enhance the customer
                      experience from placement to fulfillment. Watch this OMS
                      Walkthrough Video to know more of the full features!
                    </Paragraph>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={11} className="holds-yt">
                    <YouTube
                      videoId="AQXYp2Caq6U"
                      opts={opts}
                      onReady={this.videoOnReady}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Layout.Content>
        </Layout>
        <Layout className="faq-content">
          <MediaQuery minWidth={768}>
            <Layout.Sider theme="light" width={300} className="sider">
              <h4 className="ant-typography">Filter By Category</h4>
              <Menu
                onClick={({ key }) => this.handleSelectCategory(key)}
                defaultSelectedKeys={[this.state.activeFaqCategoryId.toString()]}
              >
                <Menu.Item key={0}>All</Menu.Item>
                {_.map(this.props.faqData, (data, index) => (
                  <Menu.Item data={data} key={Number(data.id)}>{data.category}</Menu.Item>
                ))}
              </Menu>
            </Layout.Sider>
          </MediaQuery>
          <MediaQuery maxWidth={768}>
            <Drawer
              placement="bottom"
              closable={false}
              onClose={() => this.setState({ showDrawer: false })}
              visible={showDrawer}
              className="faq-drawer"
            >
              <h4 className="ant-typography">Filter By Category</h4>
              <Menu
                onClick={({ key }) => this.setState({ active: key, showDrawer: false })}
                defaultSelectedKeys={[active]}
              >
                {_.map(this.props.faqData, (data, index) => (
                  <Menu.Item key={index}>{data.category}</Menu.Item>
                ))}
              </Menu>
            </Drawer>
          </MediaQuery>
          <Layout.Content>
            <Row type="flex" gutter={16}>
              <Col xs={24} md={18}>
                <h1 className="ant-typography frequently">
                  Frequently Asked Questions (FAQs)
                </h1>
                <Form className="search-form" layout="horizontal">
                  <Form.Item>
                    <Row type="flex" gutter={16}>
                      <Col span={22}>
                        <Input
                          prefix={<Icon type="search" />}
                          placeholder="Search"
                          onChange={e => this.handleSearch(e.target.value.trim(), ['answer', 'question'])}
                          size="large"
                        />
                      </Col>
                      <MediaQuery maxWidth={768}>
                        <Col span={2}>
                          <Icon type="filter" onClick={() => this.setState({ showDrawer: true })} />
                        </Col>
                      </MediaQuery>
                    </Row>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            {/* {filteredFaqs.length > 0 ? ( */}
            {this.state.activeFaqQuestionAndAnswer.length > 0 ? (
              <Collapse
                bordered={false}
                defaultActiveKey={["0"]}
                expandIconPosition="right"
                expandIcon={({ isActive }) => (
                  <Icon
                    type={isActive ? "minus" : "plus"}
                    style={{ fontSize: "25px" }}
                  />
                )}
              >
                {/* {_.map(filteredFaqs, (faq, index) => ( */}
                {_.map(this.state.activeFaqQuestionAndAnswer, (faq, index) => (
                  <Collapse.Panel
                    header={<label>{`${index + 1}. ${faq.question}`}</label>}
                    key={index}
                  >
                    <p>{faq.answer}</p>
                    {
                      (this.state.feedbackYesIdArray.find((data) => data.id === faq.id) === undefined) &&
                      <Content className='helpful-action'>
                        <p>Was the information helpful?</p>
                        <div className='helpful-buttons'>
                          <Button className='like-btn' onClick={() => this.handleSubmitYesSurvey(faq)}><Icon type="like" style={{ fontSize: 15, color: '#7A4521' }} /><span>Yes</span></Button>
                          <Button className='dislike-btn' onClick={() => this.handleSubmitNoSurvey(faq)}><Icon type="dislike" style={{ fontSize: 15, color: 'white' }} />No</Button>
                        </div>
                      </Content>
                    }
                    {
                      (this.state.feedbackYesIdArray.find((data) => data.id === faq.id) !== undefined) &&
                      <Content >
                        <p style={{ color: 'lightgray', margin: 0, fontSize: 12 }}>Thank you for letting us know that this information was indeed helpful!</p>
                        <p style={{ color: 'lightgray', margin: 0, fontSize: 12 }}>We'll continue to keep the information posted for you!</p>
                      </Content>
                    }

                  </Collapse.Panel>
                ))}
              </Collapse>
            ) : (
                <h1>No results found</h1>
              )}
          </Layout.Content>

          {
            this.state.modalFaqSurveyVisible &&
            <ModalFaqSurvery
              visible={this.state.modalFaqSurveyVisible}
              onCancel={this.modalSurveyVisible}
              selectedItem={this.state.selectedItem}
            />
          }

        </Layout>
      </DocumentMeta>
    );
  }
}

function mapStateToProps(state) {
  return {
    faqData: state.faq.data,
    faqFeedbackSuccess: state.faq.faqFeedbackSuccess
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadFaq: (data) => dispatch(fetchFaq(data)),
    onSubmitFaqFeedback: (data) => dispatch(postFaqFeedback(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
