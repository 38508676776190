import React from 'react'
import { Col, Button, Layout } from 'antd'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import Banner1 from '../../assets/images/home-banners/home-banner-1.png'
import Banner2 from '../../assets/images/home-banners/home-banner-2.png'
// import Banner3 from '../../assets/images/banner3.jpg'

const { Content } = Layout

function NextBtnStyle(props) {
  const { onClick } = props
  return (
    <div className='react-slick-custom-btn-right' onClick={onClick}>
      <i className='right' />
    </div>
  )
}

function PrevBtnStyle(props) {
  const { onClick } = props
  return (
    <div className='react-slick-custom-btn-left' onClick={onClick}>
      <i className='left' />
    </div>
  )
}

export default function Banner() {
  const bannerSliderSettings = {
    className: '',
    dots: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 6000,
    cssEase: 'linear'
  }
  const testSliderSettings = {
    dots: true,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    nextArrow: <NextBtnStyle />,
    prevArrow: <PrevBtnStyle />
  }
  return (
    <Layout>
      <Content>
        <div className='banner-container'>
          <div className='banner-title'>
            <Col lg={12} md={19} sm={18} className='banner-slider-text'>
              <h3 className='animated fadeIn' style={{ marginBottom: 0 }}>Conquering Borders</h3>
              <Slider {...testSliderSettings}>
                <div>
                  <div style={{ margin: '10px', maxWidth: '500px' }}>
                    <h4 className='animated fadeIn'>Who We Are</h4>
                    <p className='animated fadeIn'>For many years now, URC’s vision has been to be the best Philippine Food & Beverage Company, with a powerful presence throughout the ASEAN Region exporting its renowned brands, Jack ‘n Jill, Great Taste, C2, and Payless, to more than 38 countries around the world.</p>
                  </div>
                </div>
                <div>
                  <div style={{ margin: '10px' }}>
                    <h4>How We Work</h4>
                    <p>URC’s Global Export business unit provides the company with better access to its network of customers in more than 50 countries enabling a worldwide distribution of URC Philippines & URC Internationally sourced products. </p>
                  </div>
                </div>
                <div>
                  <div style={{ margin: '10px' }}>
                    <h4>Where We Are</h4>
                    <p>Headquartered in Singapore, the Global Exports division serves its customers around the world with sales team classified under four regions namely, Middle East & India, North & Southeast Asia, Africa & Europe, North America, Pacific Islands, & Oceania.</p>
                  </div>
                </div>
              </Slider>
              <div className='banner-button-container animated fadeIn'>
                <Button className='btn-main btn-primary' style={{ width: 280 }}>
                  <Link to='/about-us'> Read In About Us Page > </Link>
                </Button>
              </div>
            </Col>
          </div>
          <Slider {...bannerSliderSettings} arrows={false}>
            <div style={{ position: 'relative' }}>
              <img src={Banner1} alt='banner-1' style={{ height: '700px', width: '100%', objectFit: 'cover' }} />
            </div>
            <div>
              <div>
                <img src={Banner2} alt='banner-2' style={{ height: '700px', width: '100%', objectFit: 'cover' }} />
              </div>
            </div>
            {/* <div>
              <img src={Banner3} style={{ height: '700px', width: '-webkit-fill-available' }} />
            </div> */}
          </Slider>
        </div>
      </Content >
    </Layout >
  )
}
