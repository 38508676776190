import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Layout, Row, Col, List, Menu, Icon } from 'antd'
import ScrollAnimation from 'react-animate-on-scroll';
import MediaQuery from 'react-responsive'
import * as _ from 'lodash'

import './Footer.scss'

function Footer({ paths, globalLinks, isLoggedIn }) {
  const filteredPaths = _.filter(paths, path => path.placements && path.placements.includes('Footer'));

  return (
    <Fragment>
      <MediaQuery minWidth={1224}>
        {/* <ScrollAnimation animateIn="fadeIn"> */}
        <Layout.Footer>
          <Row type="flex" justify="center" gutter={30}>
            <Col span={5}>
              <div className='logo' />
            </Col>
            <Col span={5}>
              <h5>HeadQuarters</h5>
              <p>
                Universal Robina Corporation <br />
                  Global Export Division <br />
                  168 Tagore Lane, <br />
                  Singapore 787574
                </p>
            </Col>
            <Col span={5}>
              <h5>Links</h5>
              <List
                size='small'
                dataSource={filteredPaths}
                renderItem={item => {
                  return (<List.Item>
                    {
                      //Whitelist
                      (
                        (item.slug === 'home') ||
                        (item.slug === 'about-us') ||
                        (item.slug === 'products') ||
                        (item.slug === 'contact-us') ||
                        (item.slug === 'faq')
                      ) && <Link to={item.route}>{
                        (item.label === 'FAQ') ? `FAQ's` :
                          item.label
                      }</Link>
                    }
                  </List.Item>)
                }}
              />
            </Col>
            <Col span={5}>
              <h5>URC Global Links</h5>
              <List
                size='small'
                dataSource={globalLinks}
                renderItem={item => <List.Item>
                  <a href={`//${item.route}`} target="_blank">{item.label}</a>
                </List.Item>}
              />
            </Col>
          </Row>
        </Layout.Footer>
        {/* </ScrollAnimation> */}
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <Layout.Footer className="mobile">
          <Row type="flex" justify="center" gutter={30}>
            <Col span={24}>
              <div className='logo' />
            </Col>
            <Col span={24}>
              <Menu className="address" mode="inline" defaultSelectedKeys={[]} selectedKeys={[]}>
                <Menu.SubMenu key="address" title="Office Address">
                  <Menu.Item key="headquarters">
                    <p>
                      Universal Robina Corporation <br />
                      Global Export Division <br />
                      168 Tagore Lane, <br />
                      Singapore 787574
                    </p>
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
              <Menu className="links" mode="inline" defaultSelectedKeys={[]} selectedKeys={[]}>
                <Menu.SubMenu key="links" title="Links">
                  {_.map(filteredPaths, path => <Menu.Item key={path.slug}><Link data-menu-label={path.label} to={path.route}>{path.label}</Link></Menu.Item>)}
                </Menu.SubMenu>
              </Menu>
              <Menu className="global-links" mode="inline" defaultSelectedKeys={[]} selectedKeys={[]}>
                <Menu.SubMenu key="global-links" title="URC Global Links">
                  {_.map(globalLinks, path => <Menu.Item key={path.route}><a href={`//${path.route}`} target="_blank">{path.label}</a></Menu.Item>)}
                </Menu.SubMenu>
              </Menu>
            </Col>
            <Col span={24}>
              <p style={{ padding: '24px', margin: 0 }}>&#8226; Terms of Use &#8226; Privacy Statement &#8226; Contact &#8226; URC exports Copyright 2018. All rights reserved.</p>
            </Col>
          </Row>
        </Layout.Footer>
      </MediaQuery>
    </Fragment>

  )
}

export default Footer
