// import React, { Component, Fragment } from "react";
// import { connect } from "react-redux";
// import * as _ from "lodash";
// import Slider from "react-slick";
// import {
//   Layout,
//   Menu,
//   Row,
//   Col,
//   Form,
//   Input,
//   Card,
//   Modal,
//   Tooltip,
//   Spin,
//   Icon,
//   Button,
//   Drawer
// } from "antd";
// import InfiniteScroll from "react-infinite-scroller";
// import MediaQuery from "react-responsive";
// import * as queryString from "query-string";
// import DocumentMeta from "react-document-meta";

// import config from "../../config";
// import { fetchProducts, fetchFilterOptions, checkIfLoggedIn, fetchLoggedInProducts } from "../../actions";

// import banner_2 from "../../assets/images/banners/product-banner-1.jpg";
// import filterIcon from "../../assets/icons/filter.svg";
// import addIcon from "../../assets/svg/add.svg";
// import minusIcon from "../../assets/svg/minus.svg";


// import "./Products.scss";
// import { map } from "lodash";

// const antIcon = (
//   <Icon
//     type="loading"
//     style={{ fontSize: "5em", color: "#D20F84", width: "100%" }}
//     spin
//   />
// );

// class ImageLoader extends Component {
//   _isMounted = false;

//   constructor(props) {
//     super(props);
//     this.state = { loaded: false };
//   }

//   componentDidMount() {
//     this._isMounted = true;

//     const img = new Image();
//     img.src = this.props.src;

//     img.onload = () =>
//       this._isMounted && this.setState({ ...this.state, loaded: true });
//   }

//   componentWillUnmount() {
//     this._isMounted = false;
//   }

//   render() {
//     return this.state.loaded
//       ? this.props.render(this.props.src)
//       : this.props.loader;
//   }
// }

// class Products extends Component {
//   carouselSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     arrows: false,
//     initialSlide: 0,
//     lazyLoad: true,
//     preventDefault: false
//   };

//   productGalleryCarouselSettings = {
//     customPaging: index => {
//       const item = this.state.selectedProduct.variant[index];

//       return (
//         <a href="true">
//           {/* <img
//             alt={item.name}
//             height={80}
//             src={item.image ?
//               `${config.REACT_APP_FILES_URL}${item.image}` :
//               'https://via.placeholder.com/60x80?text=No+Image'}
//           /> */}
//           <Tooltip
//             title={
//               item.variants && item.variants.size ? item.variants.size : ""
//             }
//             placement="bottom"
//           >
//             <h3>
//               {item.variants && item.variants.size ? item.variants.size : ""}
//             </h3>
//           </Tooltip>
//         </a>
//       );
//     }
//   };

//   productFlavorsCarouselSettings = {
//     slidesToShow: 4,
//     slidesToScroll: 4
//   };

//   initialState = {
//     selectedProduct: null,
//     selectedProductVariant: null,
//     selectedFilterOptionKeys: [],
//     isFilterDrawerOpen: false,
//     filters: {},
//     products: {},
//     loggedInProducts: {},
//     hasMoreProducts: true,
//     search: "",
//     newData: [],
//     isLoggedIn: false

//   };

//   constructor(props) {
//     super(props);
//     this.state = this.initialState;
//   }

//   componentDidMount() {
//     this.props.checkIfLoggedIn().then((response) => {
//       if (response.error) {
//         this.setState({ isLoggedIn: false })

//     this.setState({
//       newData: this.state.products
//     });
//     this.props.fetchFilterOptions().then(() => {
//       const filters = queryString.parse(this.props.location.search);
//       const { filterOptions } = this.props;

//       const keyIndex = _.flow((options, key, value) => {
//         const index = _.chain(options[key])
//           .sortBy(option => option.id)
//           .findIndex(["name", value])
//           .value();

//         return index >= 0 ? `${key}[${index}]` : null;
//       });
//       const selectedFilterOptionKeys = _.chain(filters)
//         .flatMap((options, key) => {
//           return _.isArray(options)
//             ? _.map(options, optionName =>
//               keyIndex(filterOptions.data, key, optionName)
//             )
//             : keyIndex(filterOptions.data, key, options);
//         })
//         .filter(item => item)
//         .value();

//       _.each(filters, (filterOptions, key) =>
//         filterOptions !== "all" && !_.isArray(filterOptions)
//           ? (filters[key] = [filterOptions])
//           : (filters[key] = filterOptions)
//       );
//       this.props.fetchProducts(filters);

//       this.setState({
//         ...this.state,
//         selectedFilterOptionKeys,
//         filters,
//         products: {},
//         hasMoreProducts: true
//       });
//     });
//   } else {
//     this.setState({ isLoggedIn: true })

//     this.setState({
//       newData: this.state.loggedInProducts
//     });

//     this.props.fetchFilterOptions().then(() => {
//       const filters = queryString.parse(this.props.location.search);
//       const { filterOptions } = this.props;

//       const keyIndex = _.flow((options, key, value) => {
//         const index = _.chain(options[key])
//           .sortBy(option => option.id)
//           .findIndex(["name", value])
//           .value();

//         return index >= 0 ? `${key}[${index}]` : null;
//       });
//       const selectedFilterOptionKeys = _.chain(filters)
//         .flatMap((options, key) => {
//           return _.isArray(options)
//             ? _.map(options, optionName =>
//               keyIndex(filterOptions.data, key, optionName)
//             )
//             : keyIndex(filterOptions.data, key, options);
//         })
//         .filter(item => item)
//         .value();

//       _.each(filters, (filterOptions, key) =>
//         filterOptions !== "all" && !_.isArray(filterOptions)
//           ? (filters[key] = [filterOptions])
//           : (filters[key] = filterOptions)
//       );
//       this.props.fetchLoggedInProducts(filters);

//       this.setState({
//         ...this.state,
//         selectedFilterOptionKeys,
//         filters,
//         loggedInProducts: {},
//         hasMoreProducts: true
//       });
//     });
//   }
// })

//   }

//   componentDidUpdate(prevProps, prevState) {
//     if (this.state.isLoggedIn) {
//       if (prevProps.loggedInProducts !== prevState.loggedInProducts) {
//         return true;
//       }
//     } else {
//     if (prevProps.products !== prevState.products) {
//       return true;
//     }
//     }

//     if (prevState.search !== this.state.search) {
//       if (this.state.search > 1) {
//         this.setState({
//           newData: this.state.newData,
//           search: this.state.search
//           // products: this.state.newData
//         });
//       }
//     }
//   }

//   handleCancel() {
//     this.setState({
//       ...this.state,
//       selectedProduct: null,
//       selectedProductVariant: null
//     });
//   }

//   handleSelectProduct(event, product) {
//     event.preventDefault();
//     event.stopPropagation();
//     this.setState({
//       ...this.state,
//       selectedProduct: product,
//       selectedProductVariant: product.variant[0]
//     });
//   }

//   handleSelectProductVariant(event, variant) {
//     event.preventDefault();
//     event.stopPropagation();
//     this.setState({ ...this.state, selectedProductVariant: variant });
//   }

//   handleToggleIsFilterDrawerOpen(open) {
//     this.setState({ ...this.state, isFilterDrawerOpen: open });
//   }

//   handleToggleFilterOptionItem({ key }) {
//     const selectedFilterOptionKeys = [...this.state.selectedFilterOptionKeys];
//     const keyIndex = selectedFilterOptionKeys.indexOf(key);
//     const { filterOptions } = this.props;

//     // all
//     if (keyIndex >= 0 && key.search("[0]") >= 0) return;

//     if (keyIndex >= 0) {
//       selectedFilterOptionKeys.splice(keyIndex, 1);
//     } else {
//       // *** start ***
//       // DISABLES MULTIPLE KEY IN THE SAME FILTER OPTION
//       const existingOptionFilter = _.find(selectedFilterOptionKeys, item =>
//         _.find(
//           _.keys(filterOptions.data),
//           option => key.includes(option) && item.includes(option)
//         )
//       );
//       if (existingOptionFilter) {
//         selectedFilterOptionKeys.splice(
//           selectedFilterOptionKeys.indexOf(existingOptionFilter),
//           1
//         );
//       }
//       // *** end ***

//       selectedFilterOptionKeys.push(key);
//     }

//     let filters = _.chain(selectedFilterOptionKeys)
//       .map(key => ({
//         key: _.toPath(key)[0],
//         value: _.get(filterOptions.data, key).name || ""
//       }))
//       .groupBy("key")
//       .mapValues(items => _.map(items, "value"))
//       .value();

//     _.keys(filterOptions.data).forEach(
//       key => (filters[key] = filters[key] || "all")
//     );

//     filters = Object.assign({}, this.state.filters, filters);

//     this.setState({
//       ...this.state,
//       selectedFilterOptionKeys,
//       filters,
//       products: {},
//       loggedInProducts: {},
//       search: "",
//       hasMoreProducts: true,
//     });

//     this.props.history.push(
//       `${this.props.location.pathname}?${queryString.stringify(filters)}`
//     );

//     delete filters["product"];

//     this.props.checkIfLoggedIn().then((response) => {
//       if (response.error) {
//     this.props.fetchProducts(filters);
//       } else {
//         this.props.fetchLoggedInProducts(filters);
//       }
//     })

//   }

//   handleLoadMoreProducts(page) {
//     this.props.checkIfLoggedIn().then((response) => {
//       if (response.error) {

//     const { data: collection, fetched } = this.props.products;
//     const { products: _products } = this.state;
//     const limit = 20;
//     const offset = (page - 1) * limit;

//     if (!fetched) return;

//     const keys = _.chain(Object.keys(collection))
//       .map(key => parseInt(key, 10))
//       .sort()
//       .value();
//     const partial = _.chain(_.slice(keys, offset, offset + limit))
//       .map(key => collection[key])
//       .mapKeys("id")
//       .value();
//     const products =
//       offset === 0 ? partial : Object.assign({}, _products, partial);

//     this.setState({
//       ...this.state,
//       products,
//       hasMoreProducts:
//         Object.keys(products).length < Object.keys(collection).length
//     });
//   } else {
//     const { data: collection, fetched } = this.props.loggedInProducts;
//     const { loggedInProducts: _loggedInProducts } = this.state;
//     const limit = 20;
//     const offset = (page - 1) * limit;

//     if (!fetched) return;

//     const keys = _.chain(Object.keys(collection))
//       .map(key => parseInt(key, 10))
//       .sort()
//       .value();
//     const partial = _.chain(_.slice(keys, offset, offset + limit))
//       .map(key => collection[key])
//       .mapKeys("id")
//       .value();
//     const loggedInProducts =
//       offset === 0 ? partial : Object.assign({}, _loggedInProducts, partial);

//     this.setState({
//       ...this.state,
//       loggedInProducts,
//       hasMoreProducts:
//         Object.keys(loggedInProducts).length < Object.keys(collection).length
//     });
//   }
// })

//   }

//   handleProductSearch(e) {
//     e.persist();
//     const { products, search } = this.state;
//     const finder = Object.values(products).filter(row => {
//       const invoke = value => {
//         return (
//           value
//             .toString()
//             .toLowerCase()
//             .replace(/[`~!@#$%^&*()_|+\-=?;:",<>\{\}\[\]\\\/]/gi, "")
//             .indexOf(
//               search.toString().toLowerCase() &&
//               e.target.value.toString().toLowerCase()
//             ) !== -1
//         );
//       };
//       return (
//         invoke(row.name) ||
//         invoke(row.category) ||
//         invoke(row.country_made) ||
//         invoke(row.description) ||
//         invoke(row.brand.name) ||
//         row.variant.some(
//           row =>
//             invoke(row.name) ||
//             invoke(row.packing_scheme) ||
//             invoke(row.material_code) ||
//             invoke(
//               row.gross_weight === null ? "" : row.gross_weight.toString()
//             ) ||
//             invoke(row.net_weight === null ? "" : row.net_weight.toString()) ||
//             (row.variants.flavor === null
//               ? undefined
//               : invoke(row.variants.flavor)) ||
//             (row.variants.size === null ? undefined : invoke(row.variants.size))
//         )
//       );
//     });

//     this.props.checkIfLoggedIn().then((response) => {
//       if (response.error) {

//     const { products, search } = this.state;
//     const finder = Object.values(products).filter(row => {
//       const invoke = value => {
//         return (
//           value
//             .toString()
//             .toLowerCase()
//             .replace(/[`~!@#$%^&*()_|+\-=?;:",<>\{\}\[\]\\\/]/gi, "")
//             .indexOf(
//               search.toString().toLowerCase() &&
//               e.target.value.toString().toLowerCase()
//             ) !== -1
//         );
//       };
//       return (
//         invoke(row.name) ||
//         invoke(row.category) ||
//         invoke(row.country_made) ||
//         invoke(row.description) ||
//         invoke(row.brand.name) ||
//         row.variant.some(
//           row =>
//             invoke(row.name) ||
//             invoke(row.packing_scheme) ||
//             invoke(row.material_code) ||
//             invoke(
//               row.gross_weight === null ? "" : row.gross_weight.toString()
//             ) ||
//             invoke(row.net_weight === null ? "" : row.net_weight.toString()) ||
//             (row.variants.flavor === null
//               ? undefined
//               : invoke(row.variants.flavor)) ||
//             (row.variants.size === null ? undefined : invoke(row.variants.size))
//         )
//       );
//     });

//     this.setState({
//       newData: finder,
//       search: e.target.value.substr(0, 40)
//     });
//   } else {

//     const { loggedInProducts, search } = this.state;
//     const finder = Object.values(loggedInProducts).filter(row => {
//       const invoke = value => {
//         return (
//           value
//             .toString()
//             .toLowerCase()
//             .replace(/[`~!@#$%^&*()_|+\-=?;:",<>\{\}\[\]\\\/]/gi, "")
//             .indexOf(
//               search.toString().toLowerCase() &&
//               e.target.value.toString().toLowerCase()
//             ) !== -1
//         );
//       };
//       return (
//         invoke(row.name) ||
//         invoke(row.category) ||
//         invoke(row.country_made) ||
//         invoke(row.description) ||
//         invoke(row.brand.name) ||
//         row.variant.some(
//           row =>
//             invoke(row.name) ||
//             invoke(row.packing_scheme) ||
//             invoke(row.material_code) ||
//             invoke(
//               row.gross_weight === null ? "" : row.gross_weight.toString()
//             ) ||
//             invoke(row.net_weight === null ? "" : row.net_weight.toString()) ||
//             (row.variants.flavor === null
//               ? undefined
//               : invoke(row.variants.flavor)) ||
//             (row.variants.size === null ? undefined : invoke(row.variants.size))
//         )
//       );
//     });

//     this.setState({
//       newData: finder,
//       search: e.target.value.substr(0, 40)
//     });

//   }
// })
//   this.setState({
//     newData: finder,
//     search: e.target.value.substr(0, 40)
//   });
//   }

//   handleProductSortChange(sort) {
//     const filters = Object.assign({}, this.state.filters, { sort });
//     this.setState({ ...this.state, filters });
//     this.props.checkIfLoggedIn().then((response) => {
//       if (response.error) {

//     this.props.fetchProducts(filters);
//   } else {
//     this.props.fetchLoggedInProducts(filters);
//   }
// })

//   }

//   renderFilterOptionItems(parent, items) {

//     return _.chain(items.sort((a, b) => a.id - b.id))
//       .sortBy(item => item.id)
//       .map((item, index) => {
//         const id = `${parent}[${index}]`;
//         if (item.children && item.children.length) {
//           return (
//             <Menu.SubMenu key={id} title={item.name}>
//               {this.renderFilterOptionItems(`${id}['children']`, item.children).sortBy(item => item.id)}
//             </Menu.SubMenu>
//           );
//         } else {
//           return (
//             <Menu.Item
//               key={id}
//               id={`${item.name
//                 .toLowerCase()
//                 .replace(/ /gi, "")
//                 .replace(/'/gi, "")}_click`}
//             >
//               {item.name === "all" ? "all" : item.name}
//             </Menu.Item>
//           );
//         }
//       })
//       .value();
//   }

//   renderFilterOptions(options) {
//     return _.chain(options)
//       .keys()
//       .map(key => (
//         <Menu.SubMenu className="main-option"
//           key={key}
//           title={`${key}`}
//         >
//           {this.renderFilterOptionItems(key, options[key])}
//         </Menu.SubMenu>
//       ))
//       .value();
//   }

//   renderProductGallerySlider() {
//     // const props = Object.assign({}, this.carouselSettings, this.productGalleryCarouselSettings);

//     const props = Object.assign({}, this.carouselSettings);
//     const { variant } = this.state.selectedProduct || {};
//     const { variants } = this.state.selectedProductVariant || {};

//     const selectedFlavor = variants && variants.flavor ? variants.flavor : null;

//     const variantFlavorSizes = _.filter(variant || [], item => {
//       // const countryCode = this.state.filters.country ? getCode(this.state.filters.country[0]) : null;
//       // let countryCode = this.state.filters.country ? this.state.filters.country[0] : null;
//       // countryCode = countryCode === "a" || countryCode === "all" ? null : countryCode;
//       // const country = item.country || ''

//       return item.variants && item.variants.flavor === selectedFlavor;
//       // Commented this line for a while, because an error occured when a country returning a null data
//       // Will continue to investigate the null data in country in filter(indonesia)
//       // && (countryCode ? country.toLowerCase() === countryCode : true);
//     });

//     if (variantFlavorSizes && variantFlavorSizes.length < props.slidesToShow) {
//       props.slidesToScroll = props.slidesToShow = variantFlavorSizes.length;
//     }

//     return (
//       <Fragment>
//         <MediaQuery minWidth={1224}>
//           {variantFlavorSizes && (
//             <Slider {...props}>
//               {_.map(variantFlavorSizes, (item, index) => {
//                 const { id, variants, image, name } = item;

//                 // remove other products
//                 if (index > 0) {
//                   return false;
//                 }

//                 return (
//                   <div key={id}>
//                     <div
//                       className="image-holder"
//                       arial-label={name}
//                       style={{
//                         backgroundImage: `url(${
//                           !image
//                             ? "https://via.placeholder.com/300x350?text=No+Image"
//                             : `${config.REACT_APP_FILES_URL}${image}`
//                           })`,
//                         height: 350
//                       }}
//                     />
//                     <h2>
//                       {name ||
//                         `${variants && variants.flavor ? variants.flavor : ""}`}
//                     </h2>
//                   </div>
//                 );
//               })}
//             </Slider>
//           )}
//         </MediaQuery>
//         <MediaQuery maxWidth={1224}>
//           {variantFlavorSizes && (
//             <Slider {...props}>
//               {_.map(variantFlavorSizes, (item, index) => {
//                 const { id, variants, image, name } = item;

//                 // remove other products
//                 if (index > 0) {
//                   return false;
//                 }

//                 return (
//                   <div key={id}>
//                     <div
//                       className="image-holder"
//                       arial-label={name}
//                       style={{
//                         backgroundImage: `url(${
//                           !image
//                             ? "https://via.placeholder.com/300x350?text=No+Image"
//                             : `${config.REACT_APP_FILES_URL}${image}`
//                           })`,
//                         height: 130
//                       }}
//                     />
//                     <h2>
//                       {name ||
//                         `${variants && variants.flavor ? variants.flavor : ""}`}
//                     </h2>
//                   </div>
//                 );
//               })}
//             </Slider>
//           )}
//         </MediaQuery>
//       </Fragment>
//     );
//   }

//   renderProductVariantSlider(size = {}) {
//     const props = Object.assign(
//       {},
//       this.carouselSettings,
//       this.productFlavorsCarouselSettings,
//       size
//     );
//     const { variant } = this.state.selectedProduct || {};
//     // const countryCode = this.state.filters.country ? getCode(this.state.filters.country[0]) : null;
//     let countryCode = this.state.filters.country
//       ? this.state.filters.country[0]
//       : null;
//     countryCode =
//       countryCode === "a" || countryCode === "all" ? null : countryCode;

//     const variantByFlavor = _.chain(variant)
//       .uniqBy("variants.flavor")
//       .filter(item =>
//         countryCode && item.country
//           ? item.country.toLowerCase() === countryCode.toLowerCase()
//           : true
//       )
//       .value();

//     if (variantByFlavor && variantByFlavor.length < props.slidesToShow) {
//       props.slidesToScroll = props.slidesToShow = variantByFlavor.length;
//     }

//     return (
//       <Fragment>
//         {variantByFlavor && (
//           <Slider {...props}>
//             {_.map(variantByFlavor, item => {
//               const { id, variants, image } = item;
//               return (
//                 <>
//                   <MediaQuery minWidth={1224}>
//                     <div key={id}>
//                       <a
//                         href="true"
//                         className={
//                           this.state.selectedProductVariant === item
//                             ? "selected"
//                             : ""
//                         }
//                         onClick={event =>
//                           this.handleSelectProductVariant(event, item)
//                         }
//                       >
//                         <div
//                           className="image-holder"
//                           arial-label={
//                             variants && variants.flavor ? variants.flavor : ""
//                           }
//                           style={{
//                             backgroundImage: `url(${
//                               !image
//                                 ? "https://via.placeholder.com/110x140?text=No+Image"
//                                 : `${config.REACT_APP_FILES_URL}${image}`
//                               })`,
//                             height: 140
//                           }}
//                         />
//                         <h3>
//                           {variants && variants.flavor ? variants.flavor : ""}
//                         </h3>
//                       </a>
//                     </div>
//                   </MediaQuery>
//                   <MediaQuery maxWidth={1224}>
//                     <div key={id}>
//                       <a
//                         href="true"
//                         className={
//                           this.state.selectedProductVariant === item
//                             ? "selected"
//                             : ""
//                         }
//                         onClick={event =>
//                           this.handleSelectProductVariant(event, item)
//                         }
//                         style={{ textAlign: "center" }}
//                       >
//                         <div
//                           className="image-holder"
//                           arial-label={
//                             variants && variants.flavor ? variants.flavor : ""
//                           }
//                           style={{
//                             backgroundImage: `url(${
//                               !image
//                                 ? "https://via.placeholder.com/110x140?text=No+Image"
//                                 : `${config.REACT_APP_FILES_URL}${image}`
//                               })`,
//                             height: 120,
//                             backgroundSize: "contain",
//                             backgroundRepeat: "no-repeat",
//                             backgroundPosition: "center"
//                           }}
//                         />
//                         <h3>
//                           {variants && variants.flavor ? variants.flavor : ""}
//                         </h3>
//                       </a>
//                     </div>
//                   </MediaQuery>
//                 </>
//               );
//             })}
//           </Slider>
//         )}
//       </Fragment>
//     );
//   }

//   renderProductVariantSizes() {
//     const { selectedProduct, selectedProductVariant } = this.state;

//     if (selectedProduct === null) {
//       return false;
//     }

//     let sizes = [];

//     selectedProduct.variant.forEach((variant, index) => {
//       if (
//         selectedProductVariant.variants &&
//         selectedProductVariant.variants.flavor &&
//         variant.variants.flavor === selectedProductVariant.variants.flavor
//       ) {
//         sizes.push(variant.variants.size);
//       }
//     });

//     if (sizes.length === 0) {
//       return false;
//     }

//     const uniqueSizes = new Set(sizes);

//     return (
//       <Row gutter={12}>
//         {_.map([...uniqueSizes], (size, index) => {
//           return (
//             <Col key={index} span={4}>
//               {size}
//             </Col>
//           );
//         })}
//       </Row>
//     );
//   }

//   renderProductConfigs() {
//     const { selectedProduct, selectedProductVariant } = this.state;

//     if (selectedProduct === null) {
//       return false;
//     }

//     let configs = [];
//     let packingScheme = [];
//     let cbm = [];

//     const selectedFlavor = selectedProductVariant
//       ? selectedProductVariant.variants.flavor
//       : null;

//     selectedProduct.variant.forEach(variant => {
//       if (selectedFlavor && selectedFlavor === variant.variants.flavor) {
//         packingScheme.push(variant.packing_scheme);
//         cbm.push(variant.cbm);
//         configs.push({
//           cbm: variant.cbm,
//           packing_scheme: variant.packing_scheme,
//           gross_weight: variant.gross_weight,
//           all: variant.cbm + " " + variant.packing_scheme
//         });
//       }
//     });

//     if (configs.length === 0) {
//       return false;
//     }

//     // const uniquePackingScheme = [...new Set(packingScheme)];
//     // const uniqueCbm = [...new Set(cbm)];

//     let showedConfig = [];
//     return (
//       <Row type="flex" justify="center" style={{ marginBottom: 20 }}>
//         <Col xs={24} md={24}>
//           <Row>
//             <Col span={8}>
//               <h2>
//                 Packing&nbsp;
//                 <MediaQuery maxWidth={1224}>
//                   <br />
//                 </MediaQuery>
//                 Scheme
//               </h2>
//             </Col>
//             <Col span={8}>
//               <h2>
//                 CBM/
//                 <MediaQuery maxWidth={1224}>
//                   <br />
//                 </MediaQuery>
//                 Case
//               </h2>
//             </Col>
//             <Col span={8}>
//               <h2>
//                 Gross&nbsp;
//                 <MediaQuery maxWidth={1224}>
//                   <br />
//                 </MediaQuery>
//                 Weight
//               </h2>
//             </Col>
//           </Row>
//           {_.map([...configs], (row, index) => {
//             if (showedConfig.indexOf(row.all) > -1) {
//               return false;
//             }
//             showedConfig.push(row.all);
//             return (
//               <Row key={index} gutter={2} justify="center">
//                 <Col span={8}>{row.packing_scheme}</Col>
//                 <Col span={8}>{row.cbm}</Col>
//                 <Col span={8}>{row.gross_weight}</Col>
//               </Row>
//             );
//           })}
//         </Col>
//       </Row>
//     );
//   }

//   renderProductThumbSlider(product) {
//     const images = [];
//     // _.each(product.variant, variant => images.push(variant.image));
//     let flavors = [];
//     // images.push(product.image);

//     _.each(product.variant, variant => {
//       // do not add if not image is null
//       if (!variant.image) {
//         return;
//       }

//       if (flavors.indexOf(variant.variants.flavor) > -1) {
//         return;
//       }

//       flavors.push(variant.variants.flavor);
//       return images.push(variant.image);
//     });

//     if (_.filter(images, image => image).length === 0) {
//       return (
//         <span
//           onClick={e =>
//             !this.carouselSettings.preventDefault &&
//             this.handleSelectProduct(e, product)
//           }
//         >
//           <div
//             className="image-holder"
//             style={{
//               backgroundImage: `url(https://via.placeholder.com/200x200?text=No+Image`
//             }}
//           />
//         </span>
//       );
//     }

//     return _.chain(images)
//       .slice(0, 5)
//       .map((image, index) => (
//         <span
//           key={index}
//           onClick={e =>
//             !this.carouselSettings.preventDefault &&
//             this.handleSelectProduct(e, product)
//           }
//         >
//           <ImageLoader
//             loader={
//               <div style={{ padding: "80px" }} className="loader">
//                 <Spin size="large" />
//               </div>
//             }
//             render={src => (
//               <div
//                 className="image-holder"
//                 style={{ backgroundImage: `url(${src})` }}
//               />
//             )}
//             src={`${config.REACT_APP_FILES_URL}thumbs_${image}`}
//           />
//         </span>
//       ))
//       .value();
//   }

//   getParameterByName(name) {
//     const url = this.props.location.search;
//     name = name.replace(/[\[\]]/g, "\\$&");
//     var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
//       results = regex.exec(url);
//     if (!results) return null;
//     if (!results[2]) return "";
//     return decodeURIComponent(results[2].replace(/\+/g, " "));
//   }

//   render() {
//     const { filterOptions, products, loggedInProducts } = this.props;
//     const { selectedProduct, selectedProductVariant, isLoggedIn } = this.state;

//     const meta = {
//       title: "Our Products - URC Global Exports",
//       description:
//         "URC Global Exports seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
//       canonical: window.location.href,
//       meta: {
//         charset: "utf-8",
//         name: {
//           keywords: "Our Products"
//         }
//       }
//     };
//     if (isLoggedIn) {
//       return (
//         <DocumentMeta {...meta}>
//           <Layout className='product-container'>
//             <MediaQuery minWidth={1224}>
//               <Layout.Sider theme="light" width={230}>
//                 <div className="sidebar-content">
//                   <div className="filter-by-title">
//                     <h3>Filter by</h3>
//                   </div>
//                   <Menu
//                     mode="inline"
//                     selectedKeys={this.state.selectedFilterOptionKeys}
//                     style={{ borderRight: 0 }}
//                     onClick={this.handleToggleFilterOptionItem.bind(this)}
//                     defaultOpenKeys={[
//                       this.getParameterByName("activeMenu") || "category"
//                     ]}
//                     expandIcon={({ isOpen }) => (
//                       <img
//                         src={isOpen ? minusIcon : addIcon}
//                         alt="icon"
//                         style={{
//                           height: 12,
//                           width: 12
//                         }}
//                       />
//                     )}
//                   >
//                     {this.renderFilterOptions(filterOptions.data)}
//                   </Menu>
//                 </div>
//               </Layout.Sider>
//             </MediaQuery>

//             <Layout.Content className="product-catalog-content">
//                 {/* slider */}
//                 <Slider {...this.carouselSettings} className="leader-board">
//                   <div className="slide">
//                     <div
//                       className="slide-banner"
//                       style={{ backgroundImage: `url(${banner_2})` }}
//                     />
//                     <div
//                       className="slide-content left-bottom"
//                       style={{ width: "500px" }}
//                     >
//                       <p
//                         className="animated fadeIn"
//                         style={{
//                           fontSize: "2.5rem",
//                           fontFamily: "Montserrat",
//                           padding: "20px",
//                           fontWeight: "800",
//                           color: "#FFFFFF",
//                           margin: "10px 0"
//                         }}
//                       >
//                         Our Products
//                       </p>
//                     </div>
//                   </div>
//                 </Slider>
//                 {/* filter */}
//                 <div className="product-query">
//                   <Form
//                     className="product-query-form"
//                     layout="horizontal"
//                     onSubmit={this.handleProductSearch}
//                   >
//                     <Row gutter={16}>
//                       {/* <Col span={16}> */}
//                       <Col span={24} className="search-container">
//                         <Input.Search
//                           prefix={<Icon type="search" />}
//                           placeholder="Search"
//                           value={this.state.search}
//                           onChange={this.handleProductSearch.bind(this)}
//                           size="large"
//                         />
//                         <MediaQuery maxWidth={1224}>
//                           <div
//                             onClick={() =>
//                               this.handleToggleIsFilterDrawerOpen(true)
//                             }
//                             className="filter-holder"
//                           >
//                             <img src={filterIcon} />
//                           </div>
//                         </MediaQuery>
//                       </Col>
//                       {/* <Col span={8}>
//                       <Form.Item label="Sort By">
//                         <Select size="large" defaultValue="brand">
//                           <Select.Option value="brand">Brand</Select.Option>
//                         </Select>
//                       </Form.Item>
//                     </Col> */}
//                     </Row>
//                   </Form>
//                   <h2 className="product-query-result-text">
//                     {/* {this.state.search.length === 0 ? null : ( */}
//                     <>
//                       {this.state.search.length === 0
//                         ? this.props.loggedInProducts.size
//                         : this.state.newData.length}
//                         &nbsp; Products Found
//                       </>
//                     {/* )} */}
//                   </h2>
//                   <div className="product-loading">
//                     <Spin
//                       spinning={!loggedInProducts.fetched || !this.state.newData}
//                       indicator={antIcon}
//                     />
//                   </div>
//                 </div>
//                 {loggedInProducts.fetched && (
//                   <InfiniteScroll
//                     className="product-result"
//                     hasMore={
//                       (loggedInProducts.fetched || this.state.newData) &&
//                       this.state.hasMoreProducts
//                     }
//                     loadMore={this.handleLoadMoreProducts.bind(this)}
//                   >
//                     {_.map(
//                       this.state.search == 0
//                         ? this.state.loggedInProducts
//                         : this.state.newData,
//                       product => (
//                         <Card
//                           hoverable
//                           bordered={false}
//                           key={product.id}
//                           className="product"
//                           style={product.variant.length == 0 ? {display: 'none'} : {}}
//                         >
//                           <Slider
//                             {...this.carouselSettings}
//                             beforeChange={() =>
//                               (this.carouselSettings.preventDefault = false)
//                             }
//                             afterChange={() =>
//                               (this.carouselSettings.preventDefault = false)
//                             }
//                             className="product-thumb"
//                           >
//                             {this.renderProductThumbSlider(product)}
//                           </Slider>
//                           <span
//                             href="true"
//                             onClick={e =>
//                               !this.carouselSettings.preventDefault &&
//                               this.handleSelectProduct(e, product)
//                             }
//                           >
//                             <p
//                               className={
//                                 product.variant.length <= 1
//                                   ? "xs-brand-padding"
//                                   : ""
//                               }
//                             >
//                               {product.brand.name}
//                             </p>
//                             <h2>{product.name}</h2>
//                             {product.country_made !== "global" &&
//                               product.country_made !== "Global" ? (
//                                 <p>{product.country_made}</p>
//                               ) : (
//                                 ""
//                               )}
//                           </span>
//                         </Card>
//                       )
//                     )}
//                   </InfiniteScroll>
//                 )}

//                 <MediaQuery maxWidth={1224}>
//                   <Drawer
//                     title={
//                       <Card
//                         bordered={false}
//                         title="Filter by"
//                         extra={
//                           <Button
//                             type="link"
//                             icon="arrow-right"
//                             onClick={() =>
//                               this.handleToggleIsFilterDrawerOpen(false)
//                             }
//                           />
//                         }
//                       />
//                     }
//                     placement="right"
//                     closable={false}
//                     className="filter-options-drawer"
//                     onClose={() => this.handleToggleIsFilterDrawerOpen(false)}
//                     visible={this.state.isFilterDrawerOpen}
//                   >
//                     <Menu
//                       mode="inline"
//                       selectedKeys={this.state.selectedFilterOptionKeys}
//                       style={{ borderRight: 0 }}
//                       onClick={this.handleToggleFilterOptionItem.bind(this)}
//                       defaultOpenKeys={["category"]}
//                     >
//                       {this.renderFilterOptions(filterOptions.data)}
//                     </Menu>
//                   </Drawer>
//                 </MediaQuery>
//               </Layout.Content>

//               <Modal
//                 className="product-details-modal"
//                 title="Product Details"
//                 visible={selectedProduct != null}
//                 onCancel={this.handleCancel.bind(this)}
//                 footer={null}
//                 width={1000}
//               >
//                 <Layout>
//                   <Layout.Content className="product-details-content">
//                     <Row gutter={48} type="flex">
//                       <Col xs={15} md={10} className="product-gallery">
//                         {this.renderProductGallerySlider()}
//                       </Col>
//                       <Col xs={24} md={14} className="product-details">
//                         <div className="description">
//                           <h2>Description</h2>
//                           <p>
//                             {selectedProduct && selectedProduct.description
//                               ? selectedProduct.description
//                               : ""}
//                           </p>
//                         </div>

//                         <div className="configs">{this.renderProductConfigs()}</div>

//                         <div className="flavors">
//                           <h2>Available Flavors</h2>
//                           {this.renderProductVariantSlider()}
//                         </div>
//                       </Col>
//                     </Row>
//                   </Layout.Content>
//                 </Layout>
//               </Modal>
//             </Layout>
//           </DocumentMeta>
//         );
//       } else {
//         return (
//           <DocumentMeta {...meta}>
//             <Layout className='product-container'>
//               <MediaQuery minWidth={1224}>
//                 <Layout.Sider theme="light" width={230}>
//                   <div className="sidebar-content">
//                     <div className="filter-by-title">
//                       <h3>Filter by</h3>
//                     </div>
//                     <Menu
//                       mode="inline"
//                       selectedKeys={this.state.selectedFilterOptionKeys}
//                       style={{ borderRight: 0 }}
//                       onClick={this.handleToggleFilterOptionItem.bind(this)}
//                       defaultOpenKeys={[
//                         this.getParameterByName("activeMenu") || "category"
//                       ]}
//                       expandIcon={({ isOpen }) => (
//                         <img
//                           src={isOpen ? minusIcon : addIcon}
//                           alt="icon"
//                           style={{
//                             height: 12,
//                             width: 12
//                           }}
//                         />
//                       )}
//                     >
//                       {this.renderFilterOptions(filterOptions.data)}
//                     </Menu>
//                   </div>
//                 </Layout.Sider>
//               </MediaQuery>

//               <Layout.Content className="product-catalog-content">
//               {/* slider */}
//               <Slider {...this.carouselSettings} className="leader-board">
//                 <div className="slide">
//                   <div
//                     className="slide-banner"
//                     style={{ backgroundImage: `url(${banner_2})` }}
//                   />
//                   <div
//                     className="slide-content left-bottom"
//                     style={{ width: "500px" }}
//                   >
//                     <p
//                       className="animated fadeIn"
//                       style={{
//                         fontSize: "2.5rem",
//                         fontFamily: "Montserrat",
//                         padding: "20px",
//                         fontWeight: "800",
//                         color: "#FFFFFF",
//                         margin: "10px 0"
//                       }}
//                     >
//                       Our Products
//                     </p>
//                   </div>
//                 </div>
//               </Slider>
//               {/* filter */}
//               <div className="product-query">
//                 <Form
//                   className="product-query-form"
//                   layout="horizontal"
//                   onSubmit={this.handleProductSearch}
//                 >
//                   <Row gutter={16}>
//                     {/* <Col span={16}> */}
//                     <Col span={24} className="search-container">
//                       <Input.Search
//                         prefix={<Icon type="search" />}
//                         placeholder="Search"
//                         value={this.state.search}
//                         onChange={this.handleProductSearch.bind(this)}
//                         size="large"
//                       />
//                       <MediaQuery maxWidth={1224}>
//                         <div
//                           onClick={() =>
//                             this.handleToggleIsFilterDrawerOpen(true)
//                           }
//                           className="filter-holder"
//                         >
//                           <img src={filterIcon} />
//                         </div>
//                       </MediaQuery>
//                     </Col>
//                     {/* <Col span={8}>
//                     <Form.Item label="Sort By">
//                       <Select size="large" defaultValue="brand" >
//                         <Select.Option value="brand">Brand</Select.Option>
//                       </Select>
//                     </Form.Item>
//                   </Col> */}
//                   </Row>
//                 </Form>
//                 <h2 className="product-query-result-text">
//                   {/* {this.state.search.length === 0 ? null : ( */}
//                   <>
//                     {this.state.search.length === 0
//                       ? this.props.products.size
//                       : this.state.newData.length}
//                       &nbsp; Products Found
//                     </>
//                   {/* )} */}
//                 </h2>
//                 <div className="product-loading">
//                   <Spin
//                     spinning={!products.fetched || !this.state.newData}
//                     indicator={antIcon}
//                   />
//                 </div>
//               </div>
//               {products.fetched && (
//                 <InfiniteScroll
//                   className="product-result"
//                   hasMore={
//                     (products.fetched || this.state.newData) &&
//                     this.state.hasMoreProducts
//                   }
//                   loadMore={this.handleLoadMoreProducts.bind(this)}
//                 >
//                   {_.map(
//                     this.state.search == 0
//                       ? this.state.products
//                       : this.state.newData,
//                     product => (
//                       <Card
//                         hoverable
//                         bordered={false}
//                         key={product.id}
//                         className="product"
//                       >
//                         <Slider
//                           {...this.carouselSettings}
//                           beforeChange={() =>
//                             (this.carouselSettings.preventDefault = false)
//                           }
//                           afterChange={() =>
//                             (this.carouselSettings.preventDefault = false)
//                           }
//                           className="product-thumb"
//                         >
//                           {this.renderProductThumbSlider(product)}
//                         </Slider>
//                         <span
//                           href="true"
//                           onClick={e =>
//                             !this.carouselSettings.preventDefault &&
//                             this.handleSelectProduct(e, product)
//                           }
//                         >
//                           <p
//                             className={
//                               product.variant.length <= 1
//                                 ? "xs-brand-padding"
//                                 : ""
//                             }
//                           >
//                             {product.brand.name}
//                           </p>
//                           <h2>{product.name}</h2>
//                           {product.country_made !== "global" &&
//                             product.country_made !== "Global" ? (
//                               <p>{product.country_made}</p>
//                             ) : (
//                               ""
//                             )}
//                         </span>
//                       </Card>
//                     )
//                   )}
//                 </InfiniteScroll>
//               )}

//               <MediaQuery maxWidth={1224}>
//                 <Drawer
//                   title={
//                     <Card
//                       bordered={false}
//                       title="Filter by"
//                       extra={
//                         <Button
//                           type="link"
//                           icon="arrow-right"
//                           onClick={() =>
//                             this.handleToggleIsFilterDrawerOpen(false)
//                           }
//                         />
//                       }
//                     />
//                   }
//                   placement="right"
//                   closable={false}
//                   className="filter-options-drawer"
//                   onClose={() => this.handleToggleIsFilterDrawerOpen(false)}
//                   visible={this.state.isFilterDrawerOpen}
//                 >
//                   <Menu
//                     mode="inline"
//                     selectedKeys={this.state.selectedFilterOptionKeys}
//                     style={{ borderRight: 0 }}
//                     onClick={this.handleToggleFilterOptionItem.bind(this)}
//                     defaultOpenKeys={["category"]}
//                   >
//                     {this.renderFilterOptions(filterOptions.data)}
//                   </Menu>
//                 </Drawer>
//               </MediaQuery>
//             </Layout.Content>

//             <Modal
//               className="product-details-modal"
//               title="Product Details"
//               visible={selectedProduct != null}
//               onCancel={this.handleCancel.bind(this)}
//               footer={null}
//               width={1000}
//             >
//               <Layout>
//                 <Layout.Content className="product-details-content">
//                   <Row gutter={48} type="flex">
//                     <Col xs={15} md={10} className="product-gallery">
//                       {this.renderProductGallerySlider()}
//                     </Col>
//                     <Col xs={24} md={14} className="product-details">
//                       <div className="description">
//                         <h2>Description</h2>
//                         <p>
//                           {selectedProduct && selectedProduct.description
//                             ? selectedProduct.description
//                             : ""}
//                         </p>
//                       </div>

//                       <div className="configs">{this.renderProductConfigs()}</div>

//                       <div className="flavors">
//                         <h2>Available Flavors</h2>
//                         {this.renderProductVariantSlider()}
//                       </div>
//                     </Col>
//                   </Row>
//                 </Layout.Content>
//               </Layout>
//             </Modal>
//           </Layout>
//         </DocumentMeta>
//       );
//     }
//     }
//   }

//   function mapStateToProps({ productCategories, products, filterOptions, loggedInProducts  }) {
//     return { categories: productCategories.sandbox, products, filterOptions, loggedInProducts };
//   }

//   export default connect(mapStateToProps, { fetchProducts, fetchFilterOptions, checkIfLoggedIn, fetchLoggedInProducts })(
//     Products
//   );

/* SHOWING PRODUCT CATALOG PER DISTRIBUTOR HIDDEN ABOVE*/
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import Slider from "react-slick";
import {
  Layout,
  Menu,
  Row,
  Col,
  Form,
  Input,
  Card,
  Modal,
  Tooltip,
  Spin,
  Icon,
  Button,
  Drawer
} from "antd";
import InfiniteScroll from "react-infinite-scroller";
import MediaQuery from "react-responsive";
import * as queryString from "query-string";
import DocumentMeta from "react-document-meta";

import config from "../../config";
import { fetchProducts, fetchFilterOptions } from "../../actions";

import banner_2 from "../../assets/images/banners/product-banner-1.jpg";
import filterIcon from "../../assets/icons/filter.svg";
import addIcon from "../../assets/svg/add.svg";
import minusIcon from "../../assets/svg/minus.svg";


import "./Products.scss";
import { map } from "lodash";

const antIcon = (
  <Icon
    type="loading"
    style={{ fontSize: "5em", color: "#D20F84", width: "100%" }}
    spin
  />
);

class ImageLoader extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { loaded: false };
  }

  componentDidMount() {
    this._isMounted = true;

    const img = new Image();
    img.src = this.props.src;

    img.onload = () =>
      this._isMounted && this.setState({ ...this.state, loaded: true });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return this.state.loaded
      ? this.props.render(this.props.src)
      : this.props.loader;
  }
}

class Products extends Component {
  carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 0,
    lazyLoad: true,
    preventDefault: false
  };

  productGalleryCarouselSettings = {
    customPaging: index => {
      const item = this.state.selectedProduct.variant[index];

      return (
        <a href="true">
          {/* <img
            alt={item.name}
            height={80}
            src={item.image ?
              `${config.REACT_APP_FILES_URL}${item.image}` :
              'https://via.placeholder.com/60x80?text=No+Image'}
          /> */}
          <Tooltip
            title={
              item.variants && item.variants.size ? item.variants.size : ""
            }
            placement="bottom"
          >
            <h3>
              {item.variants && item.variants.size ? item.variants.size : ""}
            </h3>
          </Tooltip>
        </a>
      );
    }
  };

  productFlavorsCarouselSettings = {
    slidesToShow: 4,
    slidesToScroll: 4
  };

  initialState = {
    selectedProduct: null,
    selectedProductVariant: null,
    selectedFilterOptionKeys: [],
    isFilterDrawerOpen: false,
    filters: {},
    products: {},
    hasMoreProducts: true,
    search: "",
    newData: []
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    this.setState({
      newData: this.state.products
    });
    this.props.fetchFilterOptions().then(() => {
      const filters = queryString.parse(this.props.location.search);
      const { filterOptions } = this.props;

      const keyIndex = _.flow((options, key, value) => {
        const index = _.chain(options[key])
          .sortBy(option => option.id)
          .findIndex(["name", value])
          .value();

        return index >= 0 ? `${key}[${index}]` : null;
      });
      const selectedFilterOptionKeys = _.chain(filters)
        .flatMap((options, key) => {
          return _.isArray(options)
            ? _.map(options, optionName =>
              keyIndex(filterOptions.data, key, optionName)
            )
            : keyIndex(filterOptions.data, key, options);
        })
        .filter(item => item)
        .value();

      _.each(filters, (filterOptions, key) =>
        filterOptions !== "all" && !_.isArray(filterOptions)
          ? (filters[key] = [filterOptions])
          : (filters[key] = filterOptions)
      );
      this.props.fetchProducts(filters);

      this.setState({
        ...this.state,
        selectedFilterOptionKeys,
        filters,
        products: {},
        hasMoreProducts: true
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.products !== prevState.products) {
      return true;
    }

    if (prevState.search !== this.state.search) {
      if (this.state.search > 1) {
        this.setState({
          newData: this.state.newData,
          search: this.state.search
          // products: this.state.newData
        });
      }
    }
  }

  handleCancel() {
    this.setState({
      ...this.state,
      selectedProduct: null,
      selectedProductVariant: null
    });
  }

  handleSelectProduct(event, product) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      ...this.state,
      selectedProduct: product,
      selectedProductVariant: product.variant[0]
    });
  }

  handleSelectProductVariant(event, variant) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ ...this.state, selectedProductVariant: variant });
  }

  handleToggleIsFilterDrawerOpen(open) {
    this.setState({ ...this.state, isFilterDrawerOpen: open });
  }

  handleToggleFilterOptionItem({ key }) {
    const selectedFilterOptionKeys = [...this.state.selectedFilterOptionKeys];
    const keyIndex = selectedFilterOptionKeys.indexOf(key);
    const { filterOptions } = this.props;

    // all
    if (keyIndex >= 0 && key.search("[0]") >= 0) return;

    if (keyIndex >= 0) {
      selectedFilterOptionKeys.splice(keyIndex, 1);
    } else {
      // *** start ***
      // DISABLES MULTIPLE KEY IN THE SAME FILTER OPTION
      const existingOptionFilter = _.find(selectedFilterOptionKeys, item =>
        _.find(
          _.keys(filterOptions.data),
          option => key.includes(option) && item.includes(option)
        )
      );
      if (existingOptionFilter) {
        selectedFilterOptionKeys.splice(
          selectedFilterOptionKeys.indexOf(existingOptionFilter),
          1
        );
      }
      // *** end ***

      selectedFilterOptionKeys.push(key);
    }

    let filters = _.chain(selectedFilterOptionKeys)
      .map(key => ({
        key: _.toPath(key)[0],
        value: _.get(filterOptions.data, key).name || ""
      }))
      .groupBy("key")
      .mapValues(items => _.map(items, "value"))
      .value();

    _.keys(filterOptions.data).forEach(
      key => (filters[key] = filters[key] || "all")
    );

    filters = Object.assign({}, this.state.filters, filters);

    this.setState({
      ...this.state,
      selectedFilterOptionKeys,
      filters,
      products: {},
      search: "",
      hasMoreProducts: true
    });

    this.props.history.push(
      `${this.props.location.pathname}?${queryString.stringify(filters)}`
    );

    delete filters["product"];

    this.props.fetchProducts(filters);
  }

  handleLoadMoreProducts(page) {
    const { data: collection, fetched } = this.props.products;
    const { products: _products } = this.state;
    const limit = 20;
    const offset = (page - 1) * limit;

    if (!fetched) return;

    const keys = _.chain(Object.keys(collection))
      .map(key => parseInt(key, 10))
      .sort()
      .value();
    const partial = _.chain(_.slice(keys, offset, offset + limit))
      .map(key => collection[key])
      .mapKeys("id")
      .value();
    const products =
      offset === 0 ? partial : Object.assign({}, _products, partial);

    this.setState({
      ...this.state,
      products,
      hasMoreProducts:
        Object.keys(products).length < Object.keys(collection).length
    });
  }

  handleProductSearch(e) {
    const { products, search } = this.state;
    const finder = Object.values(products).filter(row => {
      const invoke = value => {
        return (
          value
            .toString()
            .toLowerCase()
            .replace(/[`~!@#$%^&*()_|+\-=?;:",<>\{\}\[\]\\\/]/gi, "")
            .indexOf(
              search.toString().toLowerCase() &&
              e.target.value.toString().toLowerCase()
            ) !== -1
        );
      };
      return (
        invoke(row.name) ||
        invoke(row.category) ||
        invoke(row.country_made) ||
        invoke(row.description) ||
        invoke(row.brand.name) ||
        row.variant.some(
          row =>
            invoke(row.name) ||
            invoke(row.packing_scheme) ||
            invoke(row.material_code) ||
            invoke(
              row.gross_weight === null ? "" : row.gross_weight.toString()
            ) ||
            invoke(row.net_weight === null ? "" : row.net_weight.toString()) ||
            (row.variants.flavor === null
              ? undefined
              : invoke(row.variants.flavor)) ||
            (row.variants.size === null ? undefined : invoke(row.variants.size))
        )
      );
    });

    this.setState({
      newData: finder,
      search: e.target.value.substr(0, 40)
    });
  }

  handleProductSortChange(sort) {
    const filters = Object.assign({}, this.state.filters, { sort });
    this.setState({ ...this.state, filters });
    this.props.fetchProducts(filters);
  }

  renderFilterOptionItems(parent, items) {

    return _.chain(items.sort((a, b) => a.id - b.id))
      .sortBy(item => item.id)
      .map((item, index) => {
        const id = `${parent}[${index}]`;
        if (item.children && item.children.length) {
          return (
            <Menu.SubMenu key={id} title={item.name}>
              {this.renderFilterOptionItems(`${id}['children']`, item.children).sortBy(item => item.id)}
            </Menu.SubMenu>
          );
        } else {
          return (
            <Menu.Item
              key={id}
              id={`${item.name
                .toLowerCase()
                .replace(/ /gi, "")
                .replace(/'/gi, "")}_click`}
            >
              {item.name === "all" ? "all" : item.name}
            </Menu.Item>
          );
        }
      })
      .value();
  }

  renderFilterOptions(options) {
    return _.chain(options)
      .keys()
      .map(key => (
        <Menu.SubMenu className="main-option"
          key={key}
          title={`${key}`}
        >
          {this.renderFilterOptionItems(key, options[key])}
        </Menu.SubMenu>
      ))
      .value();
  }

  renderProductGallerySlider() {
    // const props = Object.assign({}, this.carouselSettings, this.productGalleryCarouselSettings);

    const props = Object.assign({}, this.carouselSettings);
    const { variant } = this.state.selectedProduct || {};
    const { variants } = this.state.selectedProductVariant || {};

    const selectedFlavor = variants && variants.flavor ? variants.flavor : null;

    const variantFlavorSizes = _.filter(variant || [], item => {
      // const countryCode = this.state.filters.country ? getCode(this.state.filters.country[0]) : null;
      // let countryCode = this.state.filters.country ? this.state.filters.country[0] : null;
      // countryCode = countryCode === "a" || countryCode === "all" ? null : countryCode;
      // const country = item.country || ''

      return item.variants && item.variants.flavor === selectedFlavor;
      // Commented this line for a while, because an error occured when a country returning a null data
      // Will continue to investigate the null data in country in filter(indonesia)
      // && (countryCode ? country.toLowerCase() === countryCode : true);
    });

    if (variantFlavorSizes && variantFlavorSizes.length < props.slidesToShow) {
      props.slidesToScroll = props.slidesToShow = variantFlavorSizes.length;
    }

    return (
      <Fragment>
        <MediaQuery minWidth={1224}>
          {variantFlavorSizes && (
            <Slider {...props}>
              {_.map(variantFlavorSizes, (item, index) => {
                const { id, variants, image, name } = item;

                // remove other products
                if (index > 0) {
                  return false;
                }

                return (
                  <div key={id}>
                    <div
                      className="image-holder"
                      arial-label={name}
                      style={{
                        backgroundImage: `url(${
                          !image
                            ? "https://via.placeholder.com/300x350?text=No+Image"
                            : `${config.REACT_APP_FILES_URL}${image}`
                          })`,
                        height: 350
                      }}
                    />
                    <h2>
                      {name ||
                        `${variants && variants.flavor ? variants.flavor : ""}`}
                    </h2>
                  </div>
                );
              })}
            </Slider>
          )}
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          {variantFlavorSizes && (
            <Slider {...props}>
              {_.map(variantFlavorSizes, (item, index) => {
                const { id, variants, image, name } = item;

                // remove other products
                if (index > 0) {
                  return false;
                }

                return (
                  <div key={id}>
                    <div
                      className="image-holder"
                      arial-label={name}
                      style={{
                        backgroundImage: `url(${
                          !image
                            ? "https://via.placeholder.com/300x350?text=No+Image"
                            : `${config.REACT_APP_FILES_URL}${image}`
                          })`,
                        height: 130
                      }}
                    />
                    <h2>
                      {name ||
                        `${variants && variants.flavor ? variants.flavor : ""}`}
                    </h2>
                  </div>
                );
              })}
            </Slider>
          )}
        </MediaQuery>
      </Fragment>
    );
  }

  renderProductVariantSlider(size = {}) {
    const props = Object.assign(
      {},
      this.carouselSettings,
      this.productFlavorsCarouselSettings,
      size
    );
    const { variant } = this.state.selectedProduct || {};
    // const countryCode = this.state.filters.country ? getCode(this.state.filters.country[0]) : null;
    let countryCode = this.state.filters.country
      ? this.state.filters.country[0]
      : null;
    countryCode =
      countryCode === "a" || countryCode === "all" ? null : countryCode;

    const variantByFlavor = _.chain(variant)
      .uniqBy("variants.flavor")
      .filter(item =>
        countryCode && item.country
          ? item.country.toLowerCase() === countryCode.toLowerCase()
          : true
      )
      .value();

    if (variantByFlavor && variantByFlavor.length < props.slidesToShow) {
      props.slidesToScroll = props.slidesToShow = variantByFlavor.length;
    }

    return (
      <Fragment>
        {variantByFlavor && (
          <Slider {...props}>
            {_.map(variantByFlavor, item => {
              const { id, variants, image } = item;
              return (
                <>
                  <MediaQuery minWidth={1224}>
                    <div key={id}>
                      <a
                        href="true"
                        className={
                          this.state.selectedProductVariant === item
                            ? "selected"
                            : ""
                        }
                        onClick={event =>
                          this.handleSelectProductVariant(event, item)
                        }
                      >
                        <div
                          className="image-holder"
                          arial-label={
                            variants && variants.flavor ? variants.flavor : ""
                          }
                          style={{
                            backgroundImage: `url(${
                              !image
                                ? "https://via.placeholder.com/110x140?text=No+Image"
                                : `${config.REACT_APP_FILES_URL}${image}`
                              })`,
                            height: 140
                          }}
                        />
                        <h3>
                          {variants && variants.flavor ? variants.flavor : ""}
                        </h3>
                      </a>
                    </div>
                  </MediaQuery>
                  <MediaQuery maxWidth={1224}>
                    <div key={id}>
                      <a
                        href="true"
                        className={
                          this.state.selectedProductVariant === item
                            ? "selected"
                            : ""
                        }
                        onClick={event =>
                          this.handleSelectProductVariant(event, item)
                        }
                        style={{ textAlign: "center" }}
                      >
                        <div
                          className="image-holder"
                          arial-label={
                            variants && variants.flavor ? variants.flavor : ""
                          }
                          style={{
                            backgroundImage: `url(${
                              !image
                                ? "https://via.placeholder.com/110x140?text=No+Image"
                                : `${config.REACT_APP_FILES_URL}${image}`
                              })`,
                            height: 120,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center"
                          }}
                        />
                        <h3>
                          {variants && variants.flavor ? variants.flavor : ""}
                        </h3>
                      </a>
                    </div>
                  </MediaQuery>
                </>
              );
            })}
          </Slider>
        )}
      </Fragment>
    );
  }

  renderProductVariantSizes() {
    const { selectedProduct, selectedProductVariant } = this.state;

    if (selectedProduct === null) {
      return false;
    }

    let sizes = [];

    selectedProduct.variant.forEach((variant, index) => {
      if (
        selectedProductVariant.variants &&
        selectedProductVariant.variants.flavor &&
        variant.variants.flavor === selectedProductVariant.variants.flavor
      ) {
        sizes.push(variant.variants.size);
      }
    });

    if (sizes.length === 0) {
      return false;
    }

    const uniqueSizes = new Set(sizes);

    return (
      <Row gutter={12}>
        {_.map([...uniqueSizes], (size, index) => {
          return (
            <Col key={index} span={4}>
              {size}
            </Col>
          );
        })}
      </Row>
    );
  }

  renderProductConfigs() {
    const { selectedProduct, selectedProductVariant } = this.state;

    if (selectedProduct === null) {
      return false;
    }

    let configs = [];
    let packingScheme = [];
    let cbm = [];

    const selectedFlavor = selectedProductVariant
      ? selectedProductVariant.variants.flavor
      : null;

    selectedProduct.variant.forEach(variant => {
      if (selectedFlavor && selectedFlavor === variant.variants.flavor) {
        packingScheme.push(variant.packing_scheme);
        cbm.push(variant.cbm);
        configs.push({
          cbm: variant.cbm,
          packing_scheme: variant.packing_scheme,
          gross_weight: variant.gross_weight,
          all: variant.cbm + " " + variant.packing_scheme
        });
      }
    });

    if (configs.length === 0) {
      return false;
    }

    // const uniquePackingScheme = [...new Set(packingScheme)];
    // const uniqueCbm = [...new Set(cbm)];

    let showedConfig = [];
    return (
      <Row type="flex" justify="center" style={{ marginBottom: 20 }}>
        <Col xs={24} md={24}>
          <Row>
            <Col span={8}>
              <h2>
                Packing&nbsp;
                <MediaQuery maxWidth={1224}>
                  <br />
                </MediaQuery>
                Scheme
              </h2>
            </Col>
            <Col span={8}>
              <h2>
                CBM/
                <MediaQuery maxWidth={1224}>
                  <br />
                </MediaQuery>
                Case
              </h2>
            </Col>
            <Col span={8}>
              <h2>
                Gross&nbsp;
                <MediaQuery maxWidth={1224}>
                  <br />
                </MediaQuery>
                Weight
              </h2>
            </Col>
          </Row>
          {_.map([...configs], (row, index) => {
            if (showedConfig.indexOf(row.all) > -1) {
              return false;
            }
            showedConfig.push(row.all);
            return (
              <Row key={index} gutter={2} justify="center">
                <Col span={8}>{row.packing_scheme}</Col>
                <Col span={8}>{row.cbm}</Col>
                <Col span={8}>{row.gross_weight}</Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    );
  }

  renderProductThumbSlider(product) {
    const images = [];
    // _.each(product.variant, variant => images.push(variant.image));
    let flavors = [];
    // images.push(product.image);

    _.each(product.variant, variant => {
      // do not add if not image is null
      if (!variant.image) {
        return;
      }

      if (flavors.indexOf(variant.variants.flavor) > -1) {
        return;
      }

      flavors.push(variant.variants.flavor);
      return images.push(variant.image);
    });

    if (_.filter(images, image => image).length === 0) {
      return (
        <span
          onClick={e =>
            !this.carouselSettings.preventDefault &&
            this.handleSelectProduct(e, product)
          }
        >
          <div
            className="image-holder"
            style={{
              backgroundImage: `url(https://via.placeholder.com/200x200?text=No+Image`
            }}
          />
        </span>
      );
    }

    return _.chain(images)
      .slice(0, 5)
      .map((image, index) => (
        <span
          key={index}
          onClick={e =>
            !this.carouselSettings.preventDefault &&
            this.handleSelectProduct(e, product)
          }
        >
          <ImageLoader
            loader={
              <div style={{ padding: "80px" }} className="loader">
                <Spin size="large" />
              </div>
            }
            render={src => (
              <div
                className="image-holder"
                style={{ backgroundImage: `url(${src})` }}
              />
            )}
            src={`${config.REACT_APP_FILES_URL}thumbs_${image}`}
          />
        </span>
      ))
      .value();
  }

  getParameterByName(name) {
    const url = this.props.location.search;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  render() {
    const { filterOptions, products } = this.props;
    const { selectedProduct, selectedProductVariant } = this.state;

    const meta = {
      title: "Our Products - URC Global Exports",
      description:
        "URC Global Exports seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: {
          keywords: "Our Products"
        }
      }
    };

    return (
      <DocumentMeta {...meta}>
        <Layout className='product-container'>
          <MediaQuery minWidth={1224}>
            <Layout.Sider theme="light" width={230}>
              <div className="sidebar-content">
                <div className="filter-by-title">
                  <h3>Filter by</h3>
                </div>
                <Menu
                  mode="inline"
                  selectedKeys={this.state.selectedFilterOptionKeys}
                  style={{ borderRight: 0 }}
                  onClick={this.handleToggleFilterOptionItem.bind(this)}
                  defaultOpenKeys={[
                    this.getParameterByName("activeMenu") || "category"
                  ]}
                  expandIcon={({ isOpen }) => (
                    <img
                      src={isOpen ? minusIcon : addIcon}
                      alt="icon"
                      style={{
                        height: 12,
                        width: 12
                      }}
                    />
                  )}
                >
                  {this.renderFilterOptions(filterOptions.data)}
                </Menu>
              </div>
            </Layout.Sider>
          </MediaQuery>

          <Layout.Content className="product-catalog-content">
            {/* slider */}
            <Slider {...this.carouselSettings} className="leader-board">
              <div className="slide">
                <div
                  className="slide-banner"
                  style={{ backgroundImage: `url(${banner_2})` }}
                />
                <div
                  className="slide-content left-bottom"
                  style={{ width: "500px" }}
                >
                  <p
                    className="animated fadeIn"
                    style={{
                      fontSize: "2.5rem",
                      fontFamily: "Montserrat",
                      padding: "20px",
                      fontWeight: "800",
                      color: "#FFFFFF",
                      margin: "10px 0"
                    }}
                  >
                    Our Products
                  </p>
                </div>
              </div>
            </Slider>
            {/* filter */}
            <div className="product-query">
              <Form
                className="product-query-form"
                layout="horizontal"
                onSubmit={this.handleProductSearch}
              >
                <Row gutter={16}>
                  {/* <Col span={16}> */}
                  <Col span={24} className="search-container">
                    <Input.Search
                      prefix={<Icon type="search" />}
                      placeholder="Search"
                      value={this.state.search}
                      onChange={this.handleProductSearch.bind(this)}
                      size="large"
                    />
                    <MediaQuery maxWidth={1224}>
                      <div
                        onClick={() =>
                          this.handleToggleIsFilterDrawerOpen(true)
                        }
                        className="filter-holder"
                      >
                        <img src={filterIcon} />
                      </div>
                    </MediaQuery>
                  </Col>
                  {/* <Col span={8}>
                  <Form.Item label="Sort By">
                    <Select size="large" defaultValue="brand" >
                      <Select.Option value="brand">Brand</Select.Option>
                    </Select>
                  </Form.Item>
                </Col> */}
                </Row>
              </Form>
              <h2 className="product-query-result-text">
                {/* {this.state.search.length === 0 ? null : ( */}
                <>
                  {this.state.search.length === 0
                    ? this.props.products.size
                    : this.state.newData.length}
                    &nbsp; Products Found
                  </>
                {/* )} */}
              </h2>
              <div className="product-loading">
                <Spin
                  spinning={!products.fetched || !this.state.newData}
                  indicator={antIcon}
                />
              </div>
            </div>
            {products.fetched && (
              <InfiniteScroll
                className="product-result"
                hasMore={
                  (products.fetched || this.state.newData) &&
                  this.state.hasMoreProducts
                }
                loadMore={this.handleLoadMoreProducts.bind(this)}
              >
                {_.map(
                  this.state.search == 0
                    ? this.state.products
                    : this.state.newData,
                  product => (
                    <Card
                      hoverable
                      bordered={false}
                      key={product.id}
                      className="product"
                    >
                      <Slider
                        {...this.carouselSettings}
                        beforeChange={() =>
                          (this.carouselSettings.preventDefault = false)
                        }
                        afterChange={() =>
                          (this.carouselSettings.preventDefault = false)
                        }
                        className="product-thumb"
                      >
                        {this.renderProductThumbSlider(product)}
                      </Slider>
                      <span
                        href="true"
                        onClick={e =>
                          !this.carouselSettings.preventDefault &&
                          this.handleSelectProduct(e, product)
                        }
                      >
                        <p
                          className={
                            product.variant.length <= 1
                              ? "xs-brand-padding"
                              : ""
                          }
                        >
                          {product.brand.name}
                        </p>
                        <h2>{product.name}</h2>
                        {product.country_made !== "global" &&
                          product.country_made !== "Global" ? (
                            <p>{product.country_made}</p>
                          ) : (
                            ""
                          )}
                      </span>
                    </Card>
                  )
                )}
              </InfiniteScroll>
            )}

            <MediaQuery maxWidth={1224}>
              <Drawer
                title={
                  <Card
                    bordered={false}
                    title="Filter by"
                    extra={
                      <Button
                        type="link"
                        icon="arrow-right"
                        onClick={() =>
                          this.handleToggleIsFilterDrawerOpen(false)
                        }
                      />
                    }
                  />
                }
                placement="right"
                closable={false}
                className="filter-options-drawer"
                onClose={() => this.handleToggleIsFilterDrawerOpen(false)}
                visible={this.state.isFilterDrawerOpen}
              >
                <Menu
                  mode="inline"
                  selectedKeys={this.state.selectedFilterOptionKeys}
                  style={{ borderRight: 0 }}
                  onClick={this.handleToggleFilterOptionItem.bind(this)}
                  defaultOpenKeys={["category"]}
                >
                  {this.renderFilterOptions(filterOptions.data)}
                </Menu>
              </Drawer>
            </MediaQuery>
          </Layout.Content>

          <Modal
            className="product-details-modal"
            title="Product Details"
            visible={selectedProduct != null}
            onCancel={this.handleCancel.bind(this)}
            footer={null}
            width={1000}
          >
            <Layout>
              <Layout.Content className="product-details-content">
                <Row gutter={48} type="flex">
                  <Col xs={15} md={10} className="product-gallery">
                    {this.renderProductGallerySlider()}
                  </Col>
                  <Col xs={24} md={14} className="product-details">
                    <div className="description">
                      <h2>Description</h2>
                      <p>
                        {selectedProduct && selectedProduct.description
                          ? selectedProduct.description
                          : ""}
                      </p>
                    </div>

                    <div className="configs">{this.renderProductConfigs()}</div>

                    <div className="flavors">
                      <h2>Available Flavors</h2>
                      {this.renderProductVariantSlider()}
                    </div>
                  </Col>
                </Row>
              </Layout.Content>
            </Layout>
          </Modal>
        </Layout>
      </DocumentMeta>
    );
  }
}

function mapStateToProps({ productCategories, products, filterOptions }) {
  return { categories: productCategories.sandbox, products, filterOptions };
}

export default connect(mapStateToProps, { fetchProducts, fetchFilterOptions })(
  Products
);
