import React, { useState, Component } from "react";
import { connect } from "react-redux";
import { Menu, Layout } from "antd";
import WorldMap from "../../assets/images/about-us-banner/world-map.png";
import DocumentMeta from "react-document-meta";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw
} from "draft-js";
import draftToHtml from "draftjs-to-html";

// import ScrollAnimation from 'react-animate-on-scroll'
import "./About.scss";

import MediaQuery from "react-responsive";
import { Card, Drawer, Button } from "antd";
import { fetchAbout } from "../../actions";

class AboutUs extends Component {
  constructor() {
    super();
    this.state = {
      keyNumber: 1,
      isFilterDrawerOpen: false
    };
  }

  componentDidMount() {
    this.props.fetchAbout();
  }

  render() {
    const displayData = (keyNumber, objName) => {
      const description = this.props.aboutData.find(
        data => data.id === parseInt(keyNumber)
      );
      if (
        objName === "description" &&
        description !== "{}" &&
        description !== undefined
      ) {
        const content = convertFromRaw(JSON.parse(description.description));
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: draftToHtml(
                convertToRaw(
                  EditorState.createWithContent(content).getCurrentContent()
                )
              )
            }}
          ></span>
        );
      }
      //Catch Error: if API return empty then catch, if API return empty of id then catch
      return (
        this.props.aboutData[0] &&
        this.props.aboutData[0].id !== "" &&
        this.props.aboutData.find(data => data.id === parseInt(keyNumber))[
        objName
        ]
      );
    };

    const animateCSS = (element, animationName, callback) => {
      const node = document.querySelector(element);
      node.classList.add("animated", animationName);
      function handleAnimationEnd() {
        node.classList.remove("animated", animationName);
        node.removeEventListener("animationend", handleAnimationEnd);
        if (typeof callback === "function") callback();
      }
      node.addEventListener("animationend", handleAnimationEnd);
    };

    const meta = {
      title: "About Us - URC Global Exports",
      description:
        "URC Global Exports seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: {
          keywords: "About Us"
        }
      }
    };

    return (
      <DocumentMeta {...meta}>
        <Layout className="about-us-container">
          <MediaQuery maxWidth={1224}>
            <Drawer
              title={
                <Card
                  bordered={false}
                  title="URC Global Exports"
                  extra={
                    <Button
                      type="link"
                      icon="close"
                      onClick={() =>
                        this.setState({ isFilterDrawerOpen: false })
                      }
                    />
                  }
                />
              }
              placement="right"
              closable={false}
              className="filter-options-drawer"
              onClose={() => this.setState({ isFilterDrawerOpen: false })}
              visible={this.state.isFilterDrawerOpen}
            >
              <Menu
                mode="inline"
                // selectedKeys={this.state.selectedFilterOptionKeys}
                style={{ borderRight: 0 }}
                // onClick={this.handleToggleFilterOptionItem.bind(this)}
                defaultOpenKeys={["category"]}
              >
                {/* {this.renderFilterOptions(filterOptions.data)} */}
                {this.props.aboutData.map(menu => {
                  return (
                    <Menu.Item
                      key={menu.id.toString()}
                      onClick={() => {
                        this.setState({
                          keyNumber: menu.id.toString(),
                          isFilterDrawerOpen: false
                        });
                        animateCSS(".about-us-description-content", "fadeIn");
                        animateCSS(".about-us-title", "fadeIn");
                      }}
                    >
                      {" "}
                      <span className="sidebar-label">{menu.sub_title}</span>
                    </Menu.Item>
                  );
                })}
              </Menu>
            </Drawer>
          </MediaQuery>

          <MediaQuery minWidth={1224}>
            <Layout.Sider theme="light" width={230}>
              <div className="sidebar-content">
                <div className="filter-by-title">
                  <h3>URC Global Exports</h3>
                </div>
                <Menu
                  defaultSelectedKeys={["1"]}
                  defaultOpenKeys={["sub1"]}
                  mode="inline"
                  // theme='light'
                  style={{ background: "#FCF9F4" }}
                >
                  {this.props.aboutData.map(menu => {
                    return (
                      <Menu.Item
                        key={menu.id.toString()}
                        onClick={() => {
                          this.setState({ keyNumber: menu.id.toString() });
                          animateCSS(".about-us-description-content", "fadeIn");
                          animateCSS(".about-us-title", "fadeIn");
                        }}
                      >
                        {" "}
                        <span className="sidebar-label">{menu.sub_title}</span>
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </div>
            </Layout.Sider>
          </MediaQuery>

          <Layout.Content className="product-catalog-content1">
            <div className="about-us-content">
              <div className="about-us-banner animated fadeIn">
                <div className="about-us-banner-content">
                  <h2>{displayData(this.state.keyNumber, "title")}</h2>
                  <h1 className="about-us-title">
                    {displayData(this.state.keyNumber, "sub_title")}
                  </h1>
                </div>
              </div>
              <div className="about-us-description-content">
                <div style={{ textAlign: "right" }}>
                  <MediaQuery maxWidth={1224}>
                    <Button
                      type="link"
                      icon="arrow-right"
                      className="mobile-filter-toggle"
                      onClick={() =>
                        this.setState({ isFilterDrawerOpen: true })
                      }
                      size="large"
                    />
                  </MediaQuery>
                </div>
                <span>{displayData(this.state.keyNumber, "description")}</span>
              </div>
            </div>
          </Layout.Content>
        </Layout>
      </DocumentMeta>
    );
  }
}

function mapStateToProps(state) {
  return {
    aboutData: state.about.aboutData
  };
}

export default connect(mapStateToProps, { fetchAbout })(AboutUs);
