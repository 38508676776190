import { saveAs } from 'file-saver';
import moment from 'moment'
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import ExcelJS from 'exceljs'
import Papa from 'papaparse'
import { numberFormat } from "../../utilities";
const wb = new ExcelJS.Workbook()

export const downloadFile = {

    async EXCEL(orderDataArray) {

        let worksheetArray = []

        orderDataArray.map(orderData => {

            const worksheet = wb.addWorksheet(`${orderData.orderNumber}`)

            // Width of  column
            worksheet.getColumn('A').width = 5
            worksheet.getColumn('B').width = 15
            worksheet.getColumn('C').width = 50
            worksheet.getColumn('D').width = 23
            worksheet.getColumn('E').width = 20
            worksheet.getColumn('F').width = 25
            worksheet.getColumn('G').width = 8
            worksheet.getColumn('H').width = 20

            //Header
            worksheet.mergeCells('A1:H1');
            worksheet.getCell('F1').value = 'CUSTOMER INFO SHEET';
            worksheet.getCell('F1').alignment = { horizontal: 'center' };
            worksheet.getCell('F1').font = { bold: true, horizontal: 'center' };

            //Distributor Name
            worksheet.mergeCells('A2:C2');
            worksheet.getCell('C2').value = 'DISTRIBUTOR NAME:';
            worksheet.getCell('C2').font = { bold: true };

            //Distributor Name (Value)
            worksheet.mergeCells('D2:F2');
            worksheet.getCell('F2').value = `${orderData.distributor.distributor.name}`;

            //Distributor Code
            worksheet.mergeCells('A3:C3');
            worksheet.getCell('C3').value = 'DISTRIBUTOR CODE:';
            worksheet.getCell('C3').font = { bold: true };

            //Distributor Code (Value)
            worksheet.mergeCells('D3:F3');
            worksheet.getCell('F3').value = `${orderData.distributor.distributor.customer_code}`;

            //Office Address
            worksheet.mergeCells('A4:C4');
            worksheet.getCell('C4').value = 'OFFICE ADDRESS:';
            worksheet.getCell('C4').font = { bold: true };

            //Office Address (Value)
            worksheet.mergeCells('D4:F4');
            worksheet.getCell('F4').value = `${orderData.distributor.distributor.address[0].name}`;

            //Office #
            worksheet.mergeCells('A5:C5');
            worksheet.getCell('C5').value = 'OFFICE #:';
            worksheet.getCell('C5').font = { bold: true };

            //Office # (Value)
            worksheet.mergeCells('D5:F5');
            worksheet.getCell('F5').value = `${orderData.distributor.distributor.contact.find((item) => item.type === 'office').contact}`;

            //Phone #
            worksheet.mergeCells('A6:C6');
            worksheet.getCell('C6').value = 'PHONE #:';
            worksheet.getCell('C6').font = { bold: true };

            //Phone # (Value)
            worksheet.mergeCells('D6:F6');
            worksheet.getCell('F6').value = `${orderData.distributor.distributor.contact.find((item) => item.type === 'mobile').contact}`;


            //Email Address
            worksheet.mergeCells('A7:C7');
            worksheet.getCell('C7').value = 'EMAIL ADDRESS:';
            worksheet.getCell('C7').font = { bold: true };

            //Email Address (Value)
            worksheet.mergeCells('D7:F7');
            worksheet.getCell('F7').value = `${orderData.distributor.distributor.contact.find((item) => item.type === 'email').contact}`;

            //Header
            worksheet.mergeCells('A8:F8');
            worksheet.getCell('F8').value = 'ORDER FORM DETAILS';
            worksheet.getCell('F8').alignment = { horizontal: 'center' };
            worksheet.getCell('F8').font = { bold: true, horizontal: 'center' };

            //PO Date  
            worksheet.mergeCells('A9:C9');
            worksheet.getCell('C9').value = 'P.O DATE:';
            worksheet.getCell('C9').font = { bold: true };

            //PO Date (Value)
            worksheet.mergeCells('D9:F9');
            worksheet.getCell('F9').value = `${moment(orderData.distributor.created_at).format("MMM D, YYYY")}`;

            //Customer PO Number
            worksheet.mergeCells('A10:C10');
            worksheet.getCell('C10').value = 'CUSTOMER P.O #:';
            worksheet.getCell('C10').font = { bold: true };

            //Customer PO Number (Value)
            worksheet.mergeCells('D10:F10');
            worksheet.getCell('F10').value = `${orderData.salesRefNumber}`;

            //PO Ref Number
            worksheet.mergeCells('A11:C11');
            worksheet.getCell('C11').value = 'P.O REF #:';
            worksheet.getCell('C11').font = { bold: true };

            //PO Ref Number (Value)
            worksheet.mergeCells('D11:F11');
            worksheet.getCell('F11').value = `${orderData.poNumber}`;

            //Order #
            worksheet.mergeCells('A12:C12');
            worksheet.getCell('C12').value = 'ORDER #:';
            worksheet.getCell('C12').font = { bold: true };

            //Order # (Value)
            worksheet.mergeCells('D12:F12');
            worksheet.getCell('F12').value = `${orderData.distributor.transaction_number}`;

            //Port of Destination
            worksheet.mergeCells('A13:C13');
            worksheet.getCell('C13').value = 'PORT OF DESTINATION/DELIVER ADDRESS:';
            worksheet.getCell('C13').font = { bold: true };

            //Port of Destination # (Value)
            worksheet.mergeCells('D13:F13');
            worksheet.getCell('F13').value = `${orderData.distributor.port_destination}`;

            //Container Option
            worksheet.mergeCells('A14:C14');
            worksheet.getCell('C14').value = 'CONTAINER OPTION:';
            worksheet.getCell('C14').font = { bold: true };

            //Container Option (Value)
            worksheet.mergeCells('D14:F14');
            worksheet.getCell('F14').value = `${orderData.distributor.volume}`;

            //ETD
            worksheet.mergeCells('A15:C15');
            worksheet.getCell('C15').value = 'TARGET ETD:';
            worksheet.getCell('C15').font = { bold: true };

            //ETD (Value)
            worksheet.mergeCells('D15:F15');
            worksheet.getCell('F15').value = `${moment(orderData.distributor.estimated_delivery).format("MMMM YYYY")}`;

            // Product List Details
            worksheet.getCell('B17').value = 'SKU CODE';
            worksheet.getCell('B17').font = { bold: true };

            worksheet.getCell('C17').value = 'SKU DESCRIPTION';
            worksheet.getCell('C17').font = { bold: true };

            worksheet.getCell('D17').value = 'PACKING SCHEME';
            worksheet.getCell('D17').font = { bold: true };

            worksheet.getCell('E17').value = 'ORDERED QTY (IN CS)';
            worksheet.getCell('E17').font = { bold: true };

            worksheet.getCell('F17').value = 'ORDERED AMOUNT (IN USD)';
            worksheet.getCell('F17').font = { bold: true };

            worksheet.getCell('G17').value = 'CBM';
            worksheet.getCell('G17').font = { bold: true };

            worksheet.getCell('H17').value = 'GROSS WEIGHT';
            worksheet.getCell('H17').font = { bold: true };


            const lastRow = 18
            orderData.distributor.product.map((productItem, i) => {
                worksheet.getCell((`A${lastRow + i}`).toString()).value = `${i + 1}`;
                worksheet.getCell((`B${lastRow + i}`).toString()).value = `${productItem.variant.material_code}`;
                worksheet.getCell((`C${lastRow + i}`).toString()).value = `${productItem.variant.name}`;
                worksheet.getCell((`D${lastRow + i}`).toString()).value = `${productItem.variant.packing_scheme}`;
                worksheet.getCell((`E${lastRow + i}`).toString()).value = `${(productItem.quantity).toLocaleString()}`;
                worksheet.getCell((`F${lastRow + i}`).toString()).value = `$${numberFormat((productItem.quantity * productItem.selling_price).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }))}`;
                worksheet.getCell((`G${lastRow + i}`).toString()).value = `${(productItem.quantity * productItem.variant.cbm).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}`;
                worksheet.getCell((`H${lastRow + i}`).toString()).value = `${Math.round((productItem.quantity * productItem.variant.gross_weight)).toLocaleString()}`;

                // worksheet.getCell((`J${lastRow + i}`).toString()).value = `${orderData.statusPerProductIds}`;
            })

            const totalQuantity = orderData.distributor.product.reduce((a, b) => +a + +b.quantity, 0);

            const totalCbm = orderData.distributor.product.reduce((a, b) => +a + (b.quantity * b.variant.cbm), 0);
            const totalGrossWeight = Math.round(orderData.distributor.product.reduce((a, b) => +a + (b.quantity * b.variant.gross_weight), 0));

            const totalSellingPrice = orderData.distributor.product.reduce((a, b) => +a + +(b.selling_price * b.quantity), 0);

            let totalRow = lastRow + orderData.distributor.product.length
            worksheet.getCell(`B${totalRow}`).value = 'TOTAL';
            worksheet.getCell(`B${totalRow}`).font = { bold: true };

            worksheet.getCell((`E${totalRow}`).toString()).value = `${totalQuantity.toLocaleString()}`;
            worksheet.getCell(`E${totalRow}`).font = { bold: true };

            worksheet.getCell((`F${totalRow}`).toString()).value = `$${numberFormat(totalSellingPrice.toFixed(2))}`;
            worksheet.getCell(`F${totalRow}`).font = { bold: true };

            worksheet.getCell((`G${totalRow}`).toString()).value = `${totalCbm.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`;
            worksheet.getCell(`G${totalRow}`).font = { bold: true };

            worksheet.getCell((`H${totalRow}`).toString()).value = `${totalGrossWeight.toLocaleString()}`;
            worksheet.getCell(`H${totalRow}`).font = { bold: true };

            //Special Instructions
            totalRow = totalRow + 2
            worksheet.mergeCells(`A${totalRow}:F${totalRow}`);
            worksheet.getCell(`F${totalRow}`).value = 'SPECIAL INSTRUCTIONS:';
            worksheet.getCell(`F${totalRow}`).font = { bold: true };

            //Special Instructions (Value)
            totalRow = totalRow + 1
            worksheet.mergeCells(`A${totalRow}:F${totalRow}`);
            worksheet.getCell(`F${totalRow}`).value = `${orderData.distributor.special_instructions}`;

            worksheetArray.push(worksheet)
        })

        const buf = await wb.xlsx.writeBuffer()
        saveAs(new Blob([buf]), 'Order Status Report.xlsx')

        worksheetArray.map((dataObj) => {
            wb.removeWorksheet(dataObj.id)
        })



    },

    CSV(orderData) {
        let csvArray = []
        orderData.map(data => {
            const orderItem = data.distributor.product
            orderItem.map(productItem => {
                // const status = getStatus(productItem.status) || 'FOR PRODUCTION'
                // const skuCode = productItem.pricelist.special_code ? productItem.pricelist.special_code : productItem.variant.material_code
                // const totalOrderQty = orderItem.reduce((a, b) => +a + +b.orderQty.replace(/,/g, ""), 0);
                // const totalOrderAmount = orderItem.reduce((a, b) => +a + +(parseInt(b.orderAmount.replace(/,/g, ""))), 0);
                // const totalCbm = orderItem.reduce((a, b) => +a + +(b.cbm.replace(/,/g, "")), 0);

                csvArray.push({
                    "P.O DATE": data.poCreatedAt,
                    "CUSTOMER PO NUMBER": data.salesRefNumber,
                    "PO NUMBER": data.poNumber,
                    "ORDER NUMBER": data.orderNumber,
                    // "TOTAL ORDERED QTY (IN CS)": totalOrderQty,
                    // "TOTAL ORDERED AMOUNT (IN USD)": totalOrderAmount,
                    // "TOTAL CBM": totalCbm,
                    "TARGET ETD": data.targetedEtd,

                    "SKU CODE": productItem.variant.material_code,
                    "SKU DESCRIPTION": productItem.variant.name,
                    "PRODUCT NAME": productItem.product.name,
                    "PACKING SCHEME": productItem.variant.packing_scheme,
                    "ORDERED QTY (IN CS)": productItem.quantity.toLocaleString(),
                    "ORDERED AMOUNT (IN USD)": `$${numberFormat((productItem.quantity * productItem.selling_price).toFixed(2))}`,
                    "CBM": `${numberFormat((productItem.quantity * productItem.variant.cbm).toFixed(2))}`,
                    "GROSS WEIGHT": `${Math.round(productItem.quantity * productItem.variant.gross_weight).toLocaleString()}`,
                    "CONTAINER OPTIONS": data.distributor.volume

                    // "INVOICED QTY (IN CS)": productItem.actualStocks,
                    // "INVOICED AMOUNT (IN USD)": productItem.amount,
                    // "DISTRIBUTOR NAME": distributor.name,
                    // "DISTRIBUTOR CODE": distributor.customer_code,
                    // "OFFICE ADDRESS": distributor.address[0].name,
                    // "OFFICE NUMBER": distributor.contact.find((item) => item.type === 'office').contact,
                    // "PHONE NUMBER": distributor.contact.find((item) => item.type === 'mobile').contact,
                    // "EMAIL ADDRESS": distributor.contact.find((item) => item.type === 'email').contact,
                    // "PORT OF DESTINATION": port_destination,
                    // "SPECIAL INSTRUCTIONS": special_instructions
                })
            })
        })

        const newCsv = Papa.unparse(csvArray)

        const blob = new Blob([newCsv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "Order Status Report.csv");
        //         const {
        //             distributor: {
        //                 distributor,
        //                 // product,
        //                 special_instructions,
        //                 port_destination,
        //             },
        //             orderItem,
        //             orderNumber,
        //             poNumber,
        //             statusPerProductIds,
        //             status,
        //             targetedEtd
        //         } = data
        //         const csvArray = []
        //         orderItem.map(productItem => {
        //             const status = getStatus(productItem.status) || 'FOR PRODUCTION'
        //             const skuCode = productItem.pricelist.special_code ? productItem.pricelist.special_code : productItem.productVariant.variant.material_code
        //             const totalOrderQty = orderItem.reduce((a, b) => +a + +b.orderQty.replace(/,/g, ""), 0);
        //             const totalOrderAmount = orderItem.reduce((a, b) => +a + +(parseInt(b.orderAmount.replace(/,/g, ""))), 0);
        //             const totalCbm = orderItem.reduce((a, b) => +a + +(b.cbm.replace(/,/g, "")), 0);

        //             csvArray.push({
        //                 "P.O DATE": data.poCreatedAt,
        //                 "CUSTOMER PO NUMBER": data.salesRefNumber,
        //                 "PO NUMBER": poNumber,
        //                 "ORDER NUMBER": orderNumber,
        //                 // "TOTAL ORDERED QTY (IN CS)": totalOrderQty,
        //                 // "TOTAL ORDERED AMOUNT (IN USD)": totalOrderAmount,
        //                 // "TOTAL CBM": totalCbm,
        //                 "TARGET ETD": targetedEtd,

        //                 "SKU CODE": skuCode,
        //                 "SKU DESCRIPTION": productItem.productVariant.variant.name,
        //                 "PRODUCT NAME": productItem.productVariant.product.name,
        //                 "PACKING SCHEME": productItem.productVariant.variant.packing_scheme,
        //                 "ORDERED QTY (IN CS)": productItem.orderQty,
        //                 "ORDERED AMOUNT (IN USD)": productItem.orderAmount,
        //                 "CBM": productItem.cbm,

        //                 // "INVOICED QTY (IN CS)": productItem.actualStocks,
        //                 // "INVOICED AMOUNT (IN USD)": productItem.amount,
        //                 // "DISTRIBUTOR NAME": distributor.name,
        //                 // "DISTRIBUTOR CODE": distributor.customer_code,
        //                 // "OFFICE ADDRESS": distributor.address[0].name,
        //                 // "OFFICE NUMBER": distributor.contact.find((item) => item.type === 'office').contact,
        //                 // "PHONE NUMBER": distributor.contact.find((item) => item.type === 'mobile').contact,
        //                 // "EMAIL ADDRESS": distributor.contact.find((item) => item.type === 'email').contact,
        //                 // "PORT OF DESTINATION": port_destination,
        //                 // "SPECIAL INSTRUCTIONS": special_instructions
        //             })
        //         })
        //         // const totalOrderQty = orderItem.reduce((a, b) => +a + +b.orderQty.replace(/,/g, ""), 0);
        //         // const totalOrderAmount = orderItem.reduce((a, b) => +a + +(parseInt(b.orderAmount.replace(/,/g, ""))), 0);
        //         // const totalActualStocks = orderItem.reduce((a, b) => +a + +(b.actualStocks.replace(/,/g, "")), 0);
        //         // const totalAmount = orderItem.reduce((a, b) => +a + +(parseInt(b.amount.replace(/,/g, ""))), 0);
        //         // const totalCbm = orderItem.reduce((a, b) => +a + +(b.cbm.replace(/,/g, "")), 0);

        //         // csvArray.push({
        //         //     "PRODUCT NAME": "TOTAL",
        //         //     "SKU CODE": "",
        //         //     "SKU DESCRIPTION": "",
        //         //     "PACKING SCHEME": "",
        //         //     "ORDER STATUS": "",
        //         //     "ORDERED QTY (IN CS)": totalOrderQty.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        //         //     "ORDERED AMOUNT (IN USD)": totalOrderAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        //         //     "INVOICED QTY (IN CS)": totalActualStocks.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        //         //     "INVOICED AMOUNT (IN USD)": totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        //         //     "CBM": totalCbm.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        //         // })

        //         const newCsv = Papa.unparse(csvArray)

        //         const blob = new Blob([newCsv], { type: "text/csv;charset=utf-8" });
        //         // saveAs(blob, "Order-Status.csv");
    },

    async PDF(orderDataArray) {
        const newArray = []
        orderDataArray.map((order, x) => {
            order.distributor.product.map((product, y) => {
                newArray.push({
                    date: order.poCreatedAt || '',
                    customerPoNumber: order.salesRefNumber || '',
                    poNumber: order.poNumber || '',
                    orderNumber: order.orderNumber || '',
                    targetedEtd: order.targetedEtd || '',
                    skuCode: product.variant && product.variant.material_code || '',
                    productName: product.product && product.product.name || '',
                    packingScheme: product.variant && product.variant.packing_scheme || '',
                    orderedQty: product.quantity.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '',
                    cbm: (product.variant && product.variant.cbm * product.quantity).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '',
                    grossWeight: (product.variant && product.variant.gross_weight * product.quantity).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '',
                    containerOptions: order.containerOption || ''
                })
            })
        })

        // Embed the Times Roman font
        const pdfDoc = await PDFDocument.create()
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        // Split page by 80
        let pageArray = splitArrayIntoChunksOfLen(newArray, 45)

        let pageCount = 0
        pageArray.map((orderData, i) => {
            const page = pdfDoc.addPage([910, 500])
            const { height } = page.getSize()
            page.moveTo(240, 120);

            page.drawText('#', { x: 20, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('DATE', { x: 40, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('CUST PO #', { x: 90, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('PO #', { x: 155, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('ORDER #', { x: 205, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('ETD', { x: 270, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('SKU CODE', { x: 340, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('PRODUCT NAME', { x: 410, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('P/SCHEME', { x: 520, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('ORDERED QTY', { x: 610, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('CBM', { x: 710, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('G. WGT', { x: 770, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('CONT. OPT/S', { x: 830, y: height - 4 * 9, size: 9, font: timesRomanFont })

            orderData.map((value, i) => {
                pageCount++
                page.drawText((pageCount).toString(), { x: 20, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.date.toString(), { x: 40, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.customerPoNumber.toString(), { x: 90, y: height - (6 + i) * 9, size: 7, font: timesRomanFont })
                page.drawText(value.poNumber.toString(), { x: 155, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.orderNumber.toString(), { x: 205, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.targetedEtd.toString(), { x: 270, y: height - (6 + i) * 9, size: 7, font: timesRomanFont })
                page.drawText(value.skuCode.toString(), { x: 340, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.productName.toString(), { x: 410, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.packingScheme.toString(), { x: 520, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(`${value.orderedQty.toString()}`, { x: 610, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.cbm.toString(), { x: 710, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.grossWeight.toString(), { x: 770, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value.containerOptions.toString(), { x: 830, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
            })
            // page.drawText('TOTAL', { x: 40, y: height - (6 + orderData.length + 1) * 9, size: 9, font: timesRomanFont })
            // page.drawText(orderData.reduce((a, c) => a + (c.order_qty), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 450, y: height - (6 + orderData.length + 1) * 9, size: 9, font: timesRomanFont })
            // page.drawText('$' + orderData.reduce((a, c) => a + (c.order_amount), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 540, y: height - (6 + orderData.length + 1) * 9, size: 9, font: timesRomanFont })
            // page.drawText(orderData.reduce((a, c) => a + (c.invoiced_qty), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 620, y: height - (6 + orderData.length + 1) * 9, size: 9, font: timesRomanFont })
            // page.drawText('$' + orderData.reduce((a, c) => a + (c.invoiced_amount), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 700, y: height - (6 + orderData.length + 1) * 9, size: 9, font: timesRomanFont })
            // page.drawText(orderData.reduce((a, c) => a + (c.cbm), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 790, y: height - (6 + orderData.length + 1) * 9, size: 9, font: timesRomanFont })
            // page.drawText(orderData.reduce((a, c) => a + (c.gross_weight), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }), { x: 830, y: height - (6 + orderData.length + 1) * 9, size: 9, font: timesRomanFont })
        })





        const pdfBytes = await pdfDoc.save()
        const pdfBlob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        saveAs(pdfBlob, "Order Status Report.pdf");
    }

    // async pdf(data) {
    //     data.map(data => {
    //     })



    //         const {
    //             distributor: {
    //                 distributor,
    //                 port_destination
    //             },
    //             orderItem,
    //             orderNumber,
    //             status,
    //             targetedEtd
    //         } = data

    //         // Create a new PDFDocument
    //         const pdfDoc = await PDFDocument.create()

    //         // Embed the Times Roman font
    //         const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
    //         // Add a blank page to the document
    //         const page = pdfDoc.addPage([850, 520])
    //         // const page2 = pdfDoc.addPage()
    //         page.moveTo(110, 200);
    //         // Get the width and height of the page
    //         const { width, height } = page.getSize()

    //         // Draw a string of text toward the top of the page

    //         page.drawText('CUSTOMER INFO SHEET', { x: 350, y: height - 4 * 12, size: 12, font: timesRomanFont })

    //         page.drawText('DISTRIBUTOR NAME', { x: 50, y: height - 8 * 10, size: 10, font: timesRomanFont })
    //         page.drawText(`${distributor.name}`, { x: 200, y: height - 8 * 10, size: 10, font: timesRomanFont })

    //         page.drawText('P.O DATE', { x: 500, y: height - 8 * 10, size: 10, font: timesRomanFont })
    //         page.drawText(`${data.poCreatedAt}`, { x: 650, y: height - 8 * 10, size: 10, font: timesRomanFont })

    //         page.drawText('DISTRIBUTOR CODE', { x: 50, y: height - 9 * 10, size: 10, font: timesRomanFont })
    //         page.drawText(`${distributor.customer_code}`, { x: 200, y: height - 9 * 10, size: 10, font: timesRomanFont })

    //         page.drawText('P.O REF NO', { x: 500, y: height - 9 * 10, size: 10, font: timesRomanFont })
    //         page.drawText(`${data.salesRefNumber}`, { x: 650, y: height - 9 * 10, size: 10, font: timesRomanFont })

    //         page.drawText('OFFICE ADDRESS', { x: 50, y: height - 10 * 10, size: 10, font: timesRomanFont })
    //         page.drawText(`${distributor.address[0].name}`, { x: 200, y: height - 10 * 10, size: 10, font: timesRomanFont })

    //         page.drawText('ORDER #', { x: 500, y: height - 10 * 10, size: 10, font: timesRomanFont })
    //         page.drawText(`${orderNumber}`, { x: 650, y: height - 10 * 10, size: 10, font: timesRomanFont })

    //         page.drawText('OFFICE #', { x: 50, y: height - 11 * 10, size: 10, font: timesRomanFont })
    //         page.drawText(`${distributor.contact.find((item) => item.type === 'office').contact}`, { x: 200, y: height - 11 * 10, size: 10, font: timesRomanFont })

    //         page.drawText('ORDER STATUS', { x: 500, y: height - 11 * 10, size: 10, font: timesRomanFont })
    //         page.drawText(`${status === 'in process' ? "IN PROCESS" : "INVOICED"}`, { x: 650, y: height - 11 * 10, size: 10, font: timesRomanFont })

    //         page.drawText('PHONE #', { x: 50, y: height - 12 * 10, size: 10, font: timesRomanFont })
    //         page.drawText(`${distributor.contact.find((item) => item.type === 'mobile').contact}`, { x: 200, y: height - 12 * 10, size: 10, font: timesRomanFont })

    //         page.drawText('PORT OF DESTINATION', { x: 500, y: height - 12 * 10, size: 10, font: timesRomanFont })
    //         page.drawText(port_destination, { x: 650, y: height - 12 * 10, size: 10, font: timesRomanFont })

    //         page.drawText('EMAIL ADDRESS', { x: 50, y: height - 13 * 10, size: 10, font: timesRomanFont })
    //         page.drawText(`${distributor.contact.find((item) => item.type === 'email').contact}`, { x: 200, y: height - 13 * 10, size: 10, font: timesRomanFont })

    //         page.drawText('ETD', { x: 500, y: height - 13 * 10, size: 10, font: timesRomanFont })
    //         page.drawText(targetedEtd, { x: 650, y: height - 13 * 10, size: 10, font: timesRomanFont })


    //         page.drawText('ORDER STATUS DETAILS', { x: 350, y: height - 14 * 12, size: 12, font: timesRomanFont })

    //         page.drawText('SKU CODE', { x: 70, y: height - 25 * 8, size: 8, font: timesRomanFont })
    //         page.drawText('SKU DESCRIPTION', { x: 120, y: height - 25 * 8, size: 8, font: timesRomanFont })
    //         page.drawText('PACKING SCHEME', { x: 230, y: height - 25 * 8, size: 8, font: timesRomanFont })
    //         page.drawText('ORDER STATUS', { x: 310, y: height - 25 * 8, size: 8, font: timesRomanFont })
    //         page.drawText('ORDERED QTY', { x: 410, y: height - 25 * 8, size: 8, font: timesRomanFont })
    //         page.drawText('(IN CS)', { x: 410, y: height - 26 * 8, size: 8, font: timesRomanFont })
    //         page.drawText('ORDERED AMOUNT', { x: 480, y: height - 25 * 8, size: 8, font: timesRomanFont })
    //         page.drawText('(IN USD)', { x: 480, y: height - 26 * 8, size: 8, font: timesRomanFont })
    //         page.drawText('INVOICED QTY', { x: 570, y: height - 25 * 8, size: 8, font: timesRomanFont })
    //         page.drawText('(IN CS)', { x: 570, y: height - 26 * 8, size: 8, font: timesRomanFont })
    //         page.drawText('INVOICED AMOUNT', { x: 640, y: height - 25 * 8, size: 8, font: timesRomanFont })
    //         page.drawText('(IN USD)', { x: 640, y: height - 26 * 8, size: 8, font: timesRomanFont })
    //         page.drawText('CBM', { x: 730, y: height - 25 * 8, size: 8, font: timesRomanFont })

    //         const totalOrderQty = orderItem.reduce((a, b) => +a + +b.orderQty.replace(/,/g, ""), 0);
    //         const totalOrderAmount = orderItem.reduce((a, b) => +a + +(b.orderAmount.replace(/,/g, "")), 0);
    //         const totalActualStocks = orderItem.reduce((a, b) => +a + +(b.actualStocks.replace(/,/g, "")), 0);
    //         const totalAmount = orderItem.reduce((a, b) => +a + +(b.amount.replace(/,/g, "")), 0);
    //         const totalCbm = orderItem.reduce((a, b) => +a + +(b.cbm.replace(/,/g, "")), 0);


    //         orderItem.map((productItem, i) => {
    //             const status = getStatus(productItem.status) || 'FOR PRODUCTION'
    //             const skuCode = productItem.pricelist.special_code ? productItem.pricelist.special_code : productItem.productVariant.variant.material_code
    //             page.drawText((i + 1).toString(), { x: (50), y: height - (28 + i) * 8, size: 8, font: timesRomanFont })
    //             page.drawText(`${skuCode}`, { x: 70, y: height - (28 + i) * 8, size: 8, font: timesRomanFont })
    //             page.drawText(`${productItem.productVariant.variant.name.length > 18 ? `${productItem.productVariant.variant.name.substring(0, 18)}...` : productItem.productVariant.variant.name}`, { x: 120, y: height - (28 + i) * 8, size: 8, font: timesRomanFont })
    //             page.drawText(`${productItem.productVariant.variant.packing_scheme}`, { x: 230, y: height - (28 + i) * 8, size: 8, font: timesRomanFont })
    //             page.drawText(`${status}`, { x: 310, y: height - (28 + i) * 8, size: 8, font: timesRomanFont })
    //             page.drawText(`${(productItem.orderQty)}`, { x: 410, y: height - (28 + i) * 8, size: 8, font: timesRomanFont })
    //             page.drawText(`${(productItem.orderAmount)}`, { x: 480, y: height - (28 + i) * 8, size: 8, font: timesRomanFont })
    //             page.drawText(`${(productItem.actualStocks)}`, { x: 570, y: height - (28 + i) * 8, size: 8, font: timesRomanFont })
    //             page.drawText(`${(productItem.amount)}`, { x: 640, y: height - (28 + i) * 8, size: 8, font: timesRomanFont })
    //             page.drawText(`${(productItem.cbm)}`, { x: 730, y: height - (28 + i) * 8, size: 8, font: timesRomanFont })
    //         })

    //         page.drawText(`${'TOTAL'} `, { x: 70, y: height - (28 + orderItem.length + 1) * 8, size: 8, font: timesRomanFont })
    //         page.drawText(`${(totalOrderQty.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} `, { x: 410, y: height - (28 + orderItem.length + 1) * 8, size: 8, font: timesRomanFont })
    //         page.drawText(`${(totalOrderAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} `, { x: 480, y: height - (28 + orderItem.length + 1) * 8, size: 8, font: timesRomanFont })
    //         page.drawText(`${(totalActualStocks.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} `, { x: 570, y: height - (28 + orderItem.length + 1) * 8, size: 8, font: timesRomanFont })
    //         page.drawText(`${(totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} `, { x: 640, y: height - (28 + orderItem.length + 1) * 8, size: 8, font: timesRomanFont })
    //         page.drawText(`${(totalCbm.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} `, { x: 730, y: height - (28 + orderItem.length + 1) * 8, size: 8, font: timesRomanFont })

    //         const pdfBytes = await pdfDoc.save()
    //         const pdfBlob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
    //         saveAs(pdfBlob, "Order-Status.pdf");
    //         // Serialize the PDFDocument to bytes (a Uint8Array)

    //         // For example, `pdfBytes` can be:
    //         //   • Written to a file in Node
    //         //   • Downloaded from the browser
    //         //   • Rendered in an <iframe> 
    // }
}

// const getStatus = (status) => {
//     let statusStr = ''
//     if (status === 'forProduction') statusStr = 'FOR PRODUCTION'
//     if (status === 'produced') statusStr = 'PRODUCED'
//     if (status === 'forLoading') statusStr = 'READY TO LOAD '
//     if (status === 'invoiced') statusStr = 'INVOICED'
//     return statusStr
// }

function splitArrayIntoChunksOfLen(arr, len) {
    let chunks = [], i = 0, n = arr.length;
    while (i < n) {
        chunks.push(arr.slice(i, i += len));
    }
    return chunks;
}