import React, { Component } from "react";
import { Layout, Row, Col, Input, Select, Button, Table, List, Icon } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import './ContainerSummary.scss'
import { fetchContainerBasedOnSoNumber } from '../../actions'
const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
class ContainerSummary extends Component {
    constructor() {
        super()
        this.state = {
            activeRadio: 'all',
            filterBy: 'all',
            tableData: []
        }
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        this.props.fetchContainerBasedOnSoNumber(urlParams.get("so_number"))
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.soNumberData !== nextProps.soNumberData) {
            this.setState({ tableData: nextProps.soNumberData })
        }
    }

    onChangeFilterRadio(status) {
        if (status === "all") {
            this.setState({
                activeRadio: status,
                tableData: this.props.soNumberData
            })
            return;
        }

        let filteredByStatus
        if (status === 'in process') {
            filteredByStatus = this.props.soNumberData.filter((data) => data.status !== 'Invoiced')
        } else {
            filteredByStatus = this.props.soNumberData.filter((data) => data.status === 'Invoiced')
        }
        this.setState({
            activeRadio: status,
            tableData: filteredByStatus
        })
    }

    handleFilterBy(data) {
        this.setState({
            filterBy: data,
            // tableData: this.props.soNumberData.filter((data) => data.type === this.state.activeRadio)
            // activeFaqsBasedOnActiveKeys: this.props.faqCategoryData.find((data) => Number(data.id) === (Number(this.state.activeCategoryId))).faqs
        })
    }

    handleSearch = (searchTerm, searchKeys) => {
        if (this.state.filterBy !== 'all') {
            searchKeys = [this.state.filterBy]
        }
        //function inside function, this will convert the dot string into actual object
        const convertStringDotNotation = (desc, obj) => {
            var arr = desc.split(".");
            while (arr.length && (obj = obj[arr.shift()]));
            return obj;
        }

        const { orderData } = this.props
        let newArrayObj
        if (this.state.activeRadio === 'in process') {
            newArrayObj = this.props.soNumberData.filter((data) => data.status !== 'Invoiced')
        } else {
            newArrayObj = this.props.soNumberData.filter((data) => data.status === 'Invoiced')
        }

        //if SearchTerm is Empty return original data.
        if (!searchTerm) {
            return this.setState({ tableData: newArrayObj })
        }
        let newArray = []
        newArrayObj.forEach((rowItem, i) => {
            searchKeys.forEach(keys => {
                let rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
                let isFound = (rowItemKeys) && rowItemKeys.toString().toLowerCase().includes(searchTerm.toString().toLowerCase())
                if (isFound) newArray.push(rowItem)
            })
        })
        //Removed Duplicate Entry in Array.
        const newArrayElement = newArray.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
        })
        this.setState({ tableData: newArrayElement })
    }

    renderDotLoading(status, center) {

        const dotCount = 4;
        let i;
        let arrayCircle = [];
        let circleDataObject = {
            activeDot: 0,
            label: "",
        };
        if (status === "Invoiced") {
            circleDataObject = {
                activeDot: 4,
                label: "INVOICED",
            };
        } else if (status === "For Loading") {
            circleDataObject = {
                activeDot: 3,
                label: "READY TO LOAD",
            };
        } else if (status === "Produced") {
            circleDataObject = {
                activeDot: 2,
                label: "PRODUCED",
            };
        } else {
            circleDataObject = {
                activeDot: 1,
                label: "FOR PRODUCTION",
            };
        }
        for (i = 0; i < dotCount; i++) {
            arrayCircle.push(
                <div
                    key={i}
                    style={{
                        backgroundColor:
                            circleDataObject.activeDot > i ? "#6BBF59" : "lightgray",
                        height: 12,
                        width: 12,
                        borderRadius: 12,
                        margin: 8,
                    }}
                />
            );
        }
        return (
            <div className="xs-activity">
                <h4 style={{ color: "gray", whiteSpace: "nowrap" }}>
                    {circleDataObject.label}
                </h4>
                <div
                    style={{ display: "flex", justifyContent: center }}
                    className="dot"
                >
                    {arrayCircle}
                </div>
            </div>
        );
    }

    render() {
        // rowSelection object indicates the need for row selection
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                // 
            },
            getCheckboxProps: record => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };
        const urlParams = new URLSearchParams(window.location.search);
        const { tableData } = this.state

        return (
            <Layout className='summary-container'>
                <Layout.Content>
                    <Row className='summary-header'>
                        <Link to="/container-dashboard">
                            <Icon type="arrow-left" />
                        </Link>
                        <Content className='summary-header-content'>
                            <h1>Container Summary Breakdown</h1>

                            <Content className='summary-header-sub-content' >
                                <div>
                                    <h1>CREATED DATE </h1>
                                    <p> {urlParams.get("created_date")}</p>
                                </div>
                                <div>
                                    <h1>SO NUMBER </h1>
                                    <p> {urlParams.get("so_number")}</p>
                                </div>
                                <div>
                                    <h1>CUSTOMER PO NUMBER </h1>
                                    <p> {urlParams.get("customer_po_number")}</p>
                                </div>
                                <div>
                                    <h1>INVOICED DATE </h1>
                                    <p> {urlParams.get("billing_creation_date")}</p>
                                </div>
                                <div>
                                    <h1>STATUS </h1>
                                    <div className='table-status-sm'>
                                        <div className={`${urlParams.get("status") === 'invoiced' ? 'invoiced' : 'inProcess'}-status`}><span>{urlParams.get("status") === 'invoiced' ? 'INVOICED' : 'IN PROCESS'}</span></div>
                                    </div>
                                </div>
                            </Content>
                        </Content>

                    </Row>
                    <Row className='summary-filter'>
                        <Col md={6}>
                            <Search
                                placeholder="Search"
                                onSearch={value => this.handleSearch(value, [
                                    'material',
                                    'product_variant.name',
                                    'product_variant.packing_scheme',
                                    'status',
                                    'order_qty',
                                    'order_amount',
                                    'billed_qty',
                                    'billed_amount',
                                ])}
                                style={{ width: '100%' }}
                            />
                        </Col>
                        <Col md={4} align="center" className='filter-sort-by'>
                            <Select style={{ width: 160 }} defaultValue="All" onChange={(e) => this.handleFilterBy(e)} >
                                <Option value="all">All</Option>
                                <Option value="material">SKU Code</Option>
                                <Option value="product_variant.name">SKU Description</Option>
                                <Option value="product_variant.packing_scheme">Packing Scheme</Option>
                                {/* <Option value="status">Status</Option> */}
                                <Option value="status">Status</Option>
                                <Option value="order_qty">Ordered Qty (IN CS)</Option>
                                <Option value="order_amount">Ordered Amount (IN USD)</Option>
                                <Option value="billed_qty">Invoice Qty</Option>
                                <Option value="billed_amount">Invoice Amount (IN USD)</Option>
                                <Option value="volume">CBM</Option>
                            </Select>
                        </Col>
                        <Col md={10} className='filter-radio'>
                            <label className="radio">ALL
                                <input type="radio" name="volume" checked={this.state.activeRadio === 'all'} value="all" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                                <span className="checkmark"></span>
                            </label>
                            <label className="radio">IN PROCESS
                                <input type="radio" name="volume" checked={this.state.activeRadio === 'in process'} value="in process" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                                <span className="checkmark"></span>
                            </label>
                            <label className="radio">INVOICED
                                <input type="radio" name="volume" checked={this.state.activeRadio === 'invoiced'} value="invoiced" onChange={(e) => this.onChangeFilterRadio(e.target.value)} />
                                <span className="checkmark"></span>
                            </label>
                        </Col>
                    </Row>
                    <Row className='summary-table'>
                        <List
                            header={
                                <Row className='summary-table-header'>
                                    <Col md={2}><label>SKU CODE</label></Col>
                                    <Col md={5}><label>SKU DESCRIPTION</label></Col>
                                    <Col md={4}><label>PACKING SCHEME</label></Col>
                                    <Col md={3} style={{ padding: '0 5px', textAlign: 'center' }}><label>ORDER STATUS</label></Col>
                                    <Col md={2} style={{ padding: '0 5px', textAlign: 'right' }}><label >ORDERED QTY <br /> (IN CS)</label></Col>
                                    <Col md={2} style={{ padding: '0 5px', textAlign: 'right' }}><label >ORDERED AMOUNT <br /> (IN USD)</label></Col>
                                    <Col md={2} style={{ padding: '0 5px', textAlign: 'right' }}><label >INVOICED <br /> QTY</label></Col>
                                    <Col md={2} style={{ padding: '0 5px', textAlign: 'right' }}><label >INVOICED AMOUNT <br />(IN USD)</label></Col>
                                    <Col md={1} style={{ padding: '0 5px', textAlign: 'right' }}><label >CBM</label></Col>
                                    <Col md={1} style={{ padding: '0 5px', textAlign: 'right' }}><label >GROSS WEIGHT</label></Col>
                                </Row>
                            }
                            footer={
                                <Row className='summary-table-footer'>
                                    <Col md={14}><label>TOTAL:</label></Col>
                                    <Col md={2} style={{ padding: '0 5px', textAlign: 'right' }}><label >{tableData.reduce((a, c) => a + (c.order_qty), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label></Col>
                                    <Col md={2} style={{ padding: '0 5px', textAlign: 'right' }}><label >${tableData.reduce((a, c) => a + ((c.product_variant && c.product_variant.pricelist[0]['selling_price'] || 0) * getQtyBasedOnStatus(c.status, c)), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label></Col>
                                    <Col md={2} style={{ padding: '0 5px', textAlign: 'right' }}><label >{tableData.reduce((a, c) => a + (c.billed_qty), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label></Col>
                                    <Col md={2} style={{ padding: '0 5px', textAlign: 'right' }}><label >${tableData.reduce((a, c) => a + ((c.product_variant && c.product_variant.pricelist[0]['selling_price'] || 0) * c.billed_qty), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label></Col>
                                    <Col md={1} style={{ padding: '0 5px', textAlign: 'right' }}><label >{tableData.reduce((a, c) => a + ((c.product_variant && c.product_variant.cbm) * getQtyBasedOnStatus(c.status, c)), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label></Col>
                                    <Col md={1} style={{ padding: '0 5px', textAlign: 'right' }}><label >{tableData.reduce((a, c) => a + (c.product_variant && c.product_variant.gross_weight || 0), 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label></Col>
                                </Row>
                            }
                            bordered
                            loading={this.props.containerBreakdownSoNumberLoading}
                            pagination={{
                                onChange: page => {
                                    //
                                },
                                pageSize: 10,
                            }}
                            dataSource={tableData}
                            renderItem={item => {
                                const isReconfig = item.order && item.order.product && item.order.product.find((data) => data.variant.material_code === item.material)
                                return (
                                    <List.Item>
                                        <Row style={{ width: '100%' }}>
                                            <Col md={2}>{item.material}</Col>
                                            <Col md={5}>{item.product_variant && item.product_variant.name}</Col>
                                            <Col md={4}>{item.product_variant && item.product_variant.packing_scheme}</Col>
                                            <Col md={3} style={{ padding: '0 5px', textAlign: 'center' }}>{this.renderDotLoading(item.status, true)} </Col>
                                            <Col md={2} style={{ padding: '0 5px', textAlign: 'right' }}>{item.order_qty.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                            <Col md={2} style={{ padding: '0 5px', textAlign: 'right' }}>${((item.product_variant && item.product_variant.pricelist[0]['selling_price'] || 0) * getQtyBasedOnStatus(item.status, item)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                            <Col md={2} style={{ padding: '0 5px', textAlign: 'right' }}>{item.billed_qty.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                            <Col md={2} style={{ padding: '0 5px', textAlign: 'right' }}>${((item.product_variant && item.product_variant.pricelist[0]['selling_price'] || 0) * item.billed_qty).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                            <Col md={1} style={{ padding: '0 5px', textAlign: 'right' }}>{((item.product_variant && item.product_variant.cbm) * getQtyBasedOnStatus(item.status, item)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                            <Col md={1} style={{ padding: '0 5px', textAlign: 'right' }}>{(item.product_variant && item.product_variant.gross_weight || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                                        </Row>
                                    </List.Item>
                                )
                            }}
                        />
                        {/* <Table rowSelection={rowSelection} loading={this.props.containerBreakdownLoading} columns={columns} dataSource={tableData} /> */}
                    </Row>
                </Layout.Content>
            </Layout>
        )
    }
}

const getQtyBasedOnStatus = (status, arrayData) => {
    let returnQty;
    if (status === "Produced") {
        returnQty = arrayData["order_qty"]; // sap
    } else if (status === "For Loading") {
        returnQty = arrayData["delivered_qty"]; // sap
    } else if (status === "Invoiced") {
        returnQty = arrayData["billed_qty"]; // sap
    }
    return returnQty;
};


const mapStateToProps = state => {
    return {
        soNumberData: state.containerSummary.containerBreakdownSoNumberData,
        containerBreakdownSoNumberLoading: state.containerSummary.containerBreakdownSoNumberLoading
    };
};

export default connect(mapStateToProps, {
    // fetchPermissions
    fetchContainerBasedOnSoNumber
})(ContainerSummary);